export default {
  BOOKING_BEGIN: 'Start',
  BOOKING_END: 'End',
  OPE_MODULE: 'Operation Module',
  OPE_TYPE: 'Operation Type',
  ROLE_TYPE: 'Role Type',
  OP_LOG: 'Operation Log',
  LOGIN_LOG: 'Login Log',
  SEARCH_PLA: 'Search: Operator',
  LOG_DOWNLOAD: 'Export',
  OPE: 'Operator',
  OPE_CONTENT: 'Operational Content',
  IP: 'IP Adress',
  OPE_TIME: 'Operation Time',
  OPRE: 'Operation',
  DEL: 'Delete',
  UPDATE: 'Update',
  EXP_SUCCESS: 'Export successfully',
  EXP_FAIL: 'Export Failed',
  LOG_DETAIL: 'Log Detail',
  USER_INFO: 'User Info',
  WORK_ID: 'WorkID',
  ROLE: 'Role',
  OPE_INFO: 'Operation Info',
  DEATIL: 'Detail'
}
