//
// import { ReactComponent as ManageIcon } from '../../assets/manage.svg'
import { ReactComponent as Booking } from '../../assets/booking.svg'
import { ReactComponent as Desking } from '../../assets/desking.svg'
import { ReactComponent as EquipmentManage } from '../../assets/equipmentManage.svg'
import { ReactComponent as ManageIcon } from '../../assets/manage.svg'
import { ReactComponent as Statics } from '../../assets/statics.svg'
import { ReactComponent as SystemSetting } from '../../assets/systemSetting.svg'
import { ReactComponent as UserManage } from '../../assets/userManage.svg'
import { ReactComponent as Security } from '../../assets/security.svg'
const DEFAULT_MENUS = {
  POSITION: [
    {
      name: '工位预订',
      path: '/book',
      icon: <Booking />,
      sn: 'DESKBOOKING',
    },
    {
      name: '工位分配',
      path: '/map',
      icon: <Desking />,
      sn: 'DESKASSIGNATION',
    },
    {
      name: '空间管理',
      path: '/spaceManage',
      icon: <ManageIcon />,
      sn: 'SPACEMANAGEMENT',
    },
    {
      name: '部门空间经理',
      path: '/departmentSpaceFather',
      activeIcon: <ManageIcon />,
      sn: 'DEPTSPACEMANAGER',
    },
    {
      name: '接口人空间管理',
      path: '/interfaceManage',
      icon: <ManageIcon />,
      sn: 'INTERFACERSPACEMANAGEMENT',
    },
    {
      name: '接口人工位分配',
      path: '/interfaceMap',
      icon: <Desking />,
      sn: 'INTERFACERDESKASSIGNATION',
    },
    {
      name: '用户管理',
      path: '/userManage',
      icon: <UserManage />,
      sn: 'USERS',
    },
    {
      name: '组织架构',
      path: '/organizationalStructure',
      sn: 'DEPARTMENTS',
      father: '/userManage',
    },
    {
      name: '用户列表',
      path: '/user-page',
      sn: 'USERLIST',
      father: '/userManage',
    },
    // {
    //   name: '员工审批',
    //   path: '/user-approve',
    //   sn: 'USERAPPROVE',
    //   father: '/userManage',
    // },
    {
      name: '角色权限',
      path: '/rolePermissions',
      sn: 'ROLES',
      father: '/userManage',
    },
    {
      name: '系统设置',
      path: '/systemsetting',
      icon: <SystemSetting />,
      sn: 'SETTINGS',
    },
    {
      name: '二维码模板',
      path: '/qr-code',
      sn: 'QRCODE',
      father: '/systemsetting',
    },
    {
      name: '企业信息设置',
      path: '/company-setting',
      sn: 'COMPANYINFORMATIONSETTING',
      father: '/systemsetting',
    },
    {
      name: '许可证',
      path: '/license',
      sn: 'LICENSEINFO',
      father: '/systemsetting',
    },
    {
      name: '关于我们',
      path: '/about-setting',
      sn: 'ABOUTUS',
      father: '/systemsetting',
    },
    {
      name: '第三方集成',
      path: '/third-part',
      sn: 'THIRDCONFIG',
      father: '/systemsetting',
    },
    {
      name: '系统偏好设置',
      path: '/preference-setting',
      sn: 'SYSTEMPREFERENCES',
      parentPath: '/systemsetting',
    },
    {
      name: '工位规则',
      path: '/deskrule',
      sn: 'DESKRULE',
      father: '/systemsetting',
    },
    {
      name: '设备管理',
      path: '/map2',
      icon: <EquipmentManage />,
      sn: '',
    },
    {
      name: '分析统计',
      path: '/statistics',
      icon: <Statics />,
      sn: 'STATISTICS',
    },
    {
      name: '预订数据概览',
      path: '/book-statistics',
      icon: <Statics />,
      sn: 'BOOKSTATISTICS',
    },
    {
      name: '使用情况',
      path: '/book-statistics',
      icon: <Statics />,
      sn: 'BOOKSTATISTICS',
    },
    {
      name: '安全管理',
      path: '/securityManage',
      icon: <Security />,
      sn: 'SECURITYMANAGE',
    },
    {
      name: '日志管理',
      path: '/log',
      father: '/securityManage',
      sn: 'LOGMANAGE',
    },
    {
      name: '自定义密码',
      path: '/custom-password',
      father: '/securityManage',
      sn: 'CUSTOMPASSWORD',
    },
  ],
}

export default DEFAULT_MENUS
