export default {
  DESKRULE_RULE_SETTING: 'Rule ',
  DESKRULE_BOOKING_RULE: 'Booking Rule',
  DESK_RULE_AUTO_RELEASE_RULE: 'Auto Release Rule',
  DESK_RULE_CUSTOM_RELEASE_RULE: 'Custom Release Rule',
  DESKRULE_ADD_RULE: '+ Add Rule',
  DESKRULE_DEFAULT_BOOKING_RULE: 'Defult Booking Rule',
  DESKRULE_EDIT_BOOKING_RULE: 'Edit Booking Rule',
  UNLIMITED: 'Unlimited',
  DESKRULE_BOOKING_WAY: 'Booking way',
  DESKRULE_BUTTON_CANCEL: 'Cancel',
  DESKRULE_BUTTON_CONFIRM: 'Confirm',
  DESKRULE_BOOKING_RULE_PLACEHOLDER: 'Rule name',
  DESKRULE_INPUT_MAX_LENGTH_50: 'At most 50  characters',
  DESKRULE_SAVE_SUCCESS: 'Save successfully',
  DESKRULE_DELETE_RULE: 'Delete Rule',
  DESKRULE_DELETE_SUCCESS: 'Delete successfully',
  DESKRULE_DELETE_RULE_CONFIRM: 'Confirm to delete this booking rule',
  DESKRULE_DELETE_RULE_CONFIRM_UNIT: '?',
  DESKRULE_SWITCH_OPEN: 'On',
  DESKRULE_SWITCH_CLOSE: 'Off',
  DESKRULE_BOOKING_BY_HOUR: 'By the hours',
  DESKRULE_BOOKING_BY_HALF_DAY: 'By a half-day',
  DESKRULE_BOOKING_BY_DAY: 'By a day',
  DESKRULE_LIST_TOTAL: 'total',
  DESKRULE_LIST_UNIT: 'strip',
  DESKRULE_DELETE_TIPS: 'Tips',
  DESKRULE_BOOKING_SCAN: 'Scan QR code（Only support mobile）',
  DESKRULE_BOOKING_MORE_DAY: 'Book more than 1 day',
  DESKRULE_BOOKING_INNER: 'Book for internal staff',
  DESKRULE_BOOKING_OUTTER: 'Book for external person',
  DESKRULE_BOOKING_MANY_DAY: 'Desk can be booked up to',
  DESKRULE_BOOKING_MANY_DAY_UNIT: 'days in advance',
  DESKRULE_BOOKING_RULE_BY_HOUR: 'Book by the hours',
  DESKRULE_BOOKING_RULE_BY_HALF_DAY: 'Book by a half-day',
  DESKRULE_BOOKING_RULE_BY_DAY: 'Book by a day',
  DESKRULE_BOOKING_UNIT_DAY: 'day',
  DESKRULE_BOOKING_UNIT_DAYS: 'days',
  DESKRULE_ADD_SUCCESS: 'Add successfully',
  DESKRULE_ONLY_MOBILE: 'Only support mobile',
  DESKRULE_BOOKED_SEATS: 'Desk can be booked up to ',
  DESKRULE_BOOKED_MANY_SEATS_UNIT: 'seats at one time',
  DESKRULE_BOOKED_SEATS_UNIT: 'seat',
  DESKRULE_BOOKED_SEATS_UNITS: 'seats',
  BIND_ADD_TITLE: 'Add  Binding  Rule',
  BIND_EDIT_TITLE: 'Edit  Binding Rule',
  BIND_RULE_TITLE: 'Binding Rule',
  BIND_DELETE_TEXT: 'Confirm to delete this binding rule【{name}】?',
  BIND_NOTE:
    // eslint-disable-next-line max-len
    'Tip: Binding rules will be used in Fixed desks,Fixed desks have 3 types: Unassigned、Assigned department  and Assigned  staff.',
  BIND_REPLACE_TEXT: 'change to the staff-dept.',
  BIND_STATIC_TEXT: 'remain the original dept.',
  BIND_DEPARTMENT_TEXT: 'Desk-dept. will',
  BIND_DEPARTMENT_CHANGE: 'change to the staff-dept',
  BIND_DEPARTMENT_STATIC: 'remain the original dept',
  RELEASE_RULE_ALREADY_EXIST: 'Auto release rule name existed!',
  RELEASING_RULE_CFM_BTN_TIP:
    // eslint-disable-next-line max-len
    'Necessary items not filled or violative (if releasing schedule is on, a valid frequency is required) or invalid time setting',
  CONFIRM_MODIFY_RULE_MA: 'Confirm to change the rule?',
  CONFIRM_MODIFY_RULE_TIP_JINSHEN:
    // eslint-disable-next-line max-len
    'If the rule is modified, the desks associated with the rule will be affected. Please be cautious!',
  IS_SEND_EMAIL_SMOKING_PEOPLE: 'Notify the related meeting Creator via e-mail?',
  KEY_AUTO_RELEASE_NAME: 'Keywords: autorelease rule name',
  AUTO_RELEASE_RULE: 'Beginning',
  MEETING_BEGIN_AUTO_RELEASE_NO_PERSON: 'Unattended for the first {total} min.',
  AUTO_RELEASE_SUCCESS_EMAIL: 'Notify creator and sponsor via e-mail',
  AUTO_RELEASE_BEFORE_EMAIL: 'Remind creator via e-mail before release',
  MEETING_END_ADVANCE_RELEASE: 'Allow Auto Release',
  MEETING_END_ADVANCE_MINUTE_BAFTER_AUTO_RELEASE: 'Unattended for consecutive {total} min.',
  CAN_NOT_AUTO_RELEASE: 'No releasing date and time',
  PLEASE_INPUT_RULE_NAME_VALID_TIP: 'Rule name cannot be empty',
  PLEASE_INPUT_NUM_LETTER: 'Cannot exceed {num} characters',
  RULE_NAME: 'Rule Name',
  AUTO_RELEASE_BEFORE: 'Before autorelease',
  TO_NOTICE_BOOKER: 'Minute will notify the creator via email',
  MEETING_END_ADVANCE: 'Meeting in progress',
  MINUTE_BAFTER_AUTO_RELEASE: 'Automatic release after minutes',
  START_DATE: 'Start date',
  PLEASE_INPUT_BEGIN_DATE: 'Please enter the start date',
  DUE_DATE: 'Due date',
  NO_SELECT_UNLIMITED_END_DATE: 'not select no due date',
  STATUS_OPERATE_AUTO_RELEASE: '{status} Auto Release Rule',
  TOTAL_RULE: 'Total {total} rules',
  ADD_AUTO_RELEASE_RULE: 'Add release rule',
  AUTO_RELEASE_NO_PERSON_INPUT: 'The desk is released with no-shows for {component} minutes',
  RELEASE_RULE_DESC_SINCE_BEGIN: 'No-show Auto Release After',
  RELEASE_RULE_FOR_BUSY_DESK: 'Release rules of busy desk',
  RELEASE_RULE_FOR_LASTING_NO_SHOW: 'Release if no-show for {total} minute',
  RELEASE_RULE_FOR_LASTING_NO_SHOW_S: 'Release if no-show for {total} minutes',
  // rule
  BOOKING_RULE_NOT_EDITED: 'You have not edited anything',
  BOOKING_RULE_CFM_BTN_TIP: 'Necessary items not filled or right (iIf the rule does not meet the length, etc.)',
  BOOKING_RULE_CFM_DATE_ERROR_TIP: 'When the deadline exists, the start date cannot be greater than the deadline',
  RELEASING_RULE_NOT_EDITED: 'Editing not completed',

  RULE: 'Rule',
  MEETING_ROOM_BOOKING: 'Meeting room booking rule',
  AUTO_RELEASE: 'Allow Auto Release',
  STATUS_LIGHT: 'Status light rule',
  EMAIL_NOTIFICATION: 'Mail notification rule',
  STATUS_CONFIRM_BTN: 'Confirm {status}',
  MEETING_BEGIN: 'Meeting start',
  AUTO_RELEASE_NO_PERSON: 'Releasedally released without a minute',
  AUTO_RELEASE_BEFORE_TO_NOTICE_BOOKER: 'Before auto release {total} minute will notify the creator via email',
  MEETING_DURATION: 'Meeting time',
  ADD_CAN_BOOKING_RULE: 'Add booking rule',
  CAN_BOOKING_MEETING_AFTER_INPUT: 'Book meetings in {component} days at most',
  BOOKING_SUCCESS_NOTICE: 'System notification on booking success',
  NO_SELECT_DEADLINE_NO_UNLIMITE: 'Please select due date',
  BOOKING_SUCCESS_EMAIL: 'E-mail notification on booking success',
  CAN_BOOKING_TIME: 'Bookable meeting date and time',
  SPONSOR: 'Sponsor',
  SCHEDULER: 'Creator',
  PARTICIPANT: 'Attendees',
  APPT_ADMIN: 'Administrator',
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  MEETING_BEFORE_SEND_EMAIL: 'Send e-mail notification before meeting',

  MEETING_BEFORE_NOTICE: 'Pre-meeting notification setup',
  MEETING_BEFORE: 'Notify',
  MINUTE_NOTICE: 'minutes before start',
  SEND_TO_MEETER: 'Send to the meeting',
  EVERY_DAY: 'Everyday',
  EVERY_WEEK: 'Weekly',
  EVERY_MONTH: 'Monthly',
  BEGIN_TIME: 'Start time',
  END_TIME: 'End time',
  PLEASE_INPUT_RULE_NAME: 'Please enter the rule name',
  RULE_NAME_TIP:
    'It is recommended to fill in both English and Chinese. The Chinese and English are separated by a / symbol',
  PLEASE_INPUT_CUTOFF_DATE: 'Please enter the due date',
  CYCLE: 'Frequency',
  SHORTEST: 'min',
  LONGEST: 'max',
  MINUTE: 'mins',
  HOUR: 'hrs',
  STATUS_BOOKING_RULE: '{status} booking rule',
  CUSTOM_BOOKING_RULE: 'Custom Booking rule',
  CUSTOM_BINDING_RULE: 'Custom Binding rule',
  KEY_BOOKING_NAME: 'Keywords: booking rule name',
  KEY_BINDING_NAME: 'Keywords: binding rule name',
  CAN_USE_BOOKING: 'available',
  USING: 'booked',
  OCCUPIED: 'occupied',
  MEETING_ROOM_COLOR: 'Room {status} color',

  ROOM_COLOR_TIPS: 'Tips：',
  ROOM_COLOR_TIPS_COMMON: 'ie. represent',
  ROOM_COLOR_TIPS_CANUSE: 'Meeting room not used or not booked',
  ROOM_COLOR_TIPS_WAITING: 'The meeting has started and meeting room not occupied yet.',
  ROOM_COLOR_TIPS_USING: 'Meeting room occupied',
  ROOM_COLOR_TIPS_OCCUPIED: 'The meeting room is used without booking',
  CONFIRM_DEL_RULE_MA: 'Confirm to cancel the rule?',
  CONFIRM_DEL_RULE_MA_DETAILS:
    // eslint-disable-next-line max-len
    'After the deletion, the desk that executes the rule will restore the default rules, operate with caution!',
  CONFIRM_DEL_RELEASE_RULE_TIP: 'Confirm to delete release rule【{name}】?',
  ALL_DAY_MEETING_RULE_SETTING: 'All day & overnight meeting rule setup',
  MEETING_DONNOT_RELEASE_TIME: "Meeting's time period not allowing autorelease",
  ROOM_COLOR_TIPS_TWO_FONTS: 'Tip',
  CONFIRM_MODIFY_GLOBAL_RULE: 'Confirm to rewrite all the rules?',
  INPUT_BOOKING_CAN_PLEASE: 'Please enter how many days later can book a meeting',
  BOOKING_RULES_TIPS: 'Users can book jobs on the same day from {time}',
  IS_CROSS_DAYS: 'Book over the Day',
  IS_CROSS_DAYS_TIPS: '可跨天预订提示',
  BOOK_DAYS_RANGE_TIPS: '可预订日期范围提示',
  MNG_RULE_PLS_SEL: 'Please select',
  MNG_RULE_DEFINE: 'Custom Rule',
  MNG_RULE_AUTO_RELEASE: 'Auto release',
  MNG_RULE_EXPAND_UNFOLD: 'More',
  MNG_RULE_EXPAND_FOLD: 'Retract'
}
