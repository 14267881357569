export default {
  ORIGANIZE_INFO: '组织架构',
  ORGANIZE_INITIAL_INFO: '信息初始化',
  ORGANIZE_IMPORT_TEXT: '单次导入只支持1个楼宇工位',
  ORGANIZE_DOWNLOAD_INFO_TIP: '请下载信息初始化列表模板，按格式修改后导入',
  ORIGANIZE_SEARCH_INFO: '搜索：部门、员工',
  ORIGANIZE_DEPARTMENT_NAME: '部门名称：',
  ORIGANIZE_DEPARTMENT_NAME_PLACE: '请输入部门名字',
  ORIGANIZE_DEPARTMENT_INTERFACE: '部门接口人：',
  ORIGANIZE_DEPARTMENT_INTERFACE_PLACE: '请选择部门接口人：成员名/工号：',
  ORIGANIZE_DEPARTMENT_COLOR: '部门颜色：',
  ORIGANIZE_ADD_ROLE: '添加成员',
  DELECT_DEPARTMENT_TEXT1: '确定要删除部门【',
  LOAD_MORE: '加载更多',
  LOGIN_EMAIL: '登录邮箱',
  RESET_PASSWORD: '重置密码',
  DISABLED_ACCONUT: '禁用账号',
  ENABLE_ACCONUT: '启用账号',
  HAS_DISABLED: '已禁用',
  DISABLED_TEXT: '您确定要禁用该账号吗?',
  ENABLE_TEXT: '您确定要启用该账号吗?',
  DEPEARTMENT_MEMBERS: '部门成员',
  BATCH_IM: '批量导入',
  TRANSFER_DEPERTMENT: '调整部门',
  MODAL_EMAIL: '邮箱',
  NEW_PASSWORD: '新密码',
  NEW_PASSWORD_PLACERHOLDER: '请输入新密码',
  NEW_PASSWORD_WARNING_TEXT: '新密码不能为空',
  DOWNLOAD_TEXT: '请下载用户列表模板，按格式修改后导入',
  DOWNLOAD_TEMPLATE: '下载模板',
  DOWNLOAD_DATA: '下载数据',
  UPLOAD_DATA_TEXT: '支持文件格式:csv、xls、xlsx,只支持单个上传',
  EDIT_MEMBER: '编辑成员',
  ENTER_NAME_MEMBER: '请输入成员姓名',
  ENTER_EMAIL_ADDRESS: '请输入邮箱地址',
  PLEASE_SELECT_ROLES: '请选择角色',
  TRANSFER: '调整',
  WORK_ID: '工号',
  WORK_ID_SELECT: '工号(选填)',
  WORK_ID_SELECT_OPPO: '请输入工号',
  ROLE_MORE_CHARACTERS_30: '最大输入30个字符',
  ROLE_MORE_CHARACTERS_60: '最大输入60个字符',
  ROLE_MORE_CHARACTERS_80: '最大输入80个字符',
  ROLE_MORE_CHARACTERS_20: '最大输入20个字符',
  ROLE_MORE_CHARACTERS_50: '最大输入50个字符',
  PLACE_SELECT_DEPARTMENTS: '请选择部门',
  PLACE_ENTER_LETTERS: '请输入数字和字母',
  PASSWORD_TEXT: '密码(选填),新用户默认密码为12345678',
  SAVE_CONTINUE: '保存且继续添加',
  EDIT_DEPEARTMENT: '编辑部门',
  INTERFACER: '部门空间经理',
  DEPARTMENT_MANAGE_TEXT: '部门工位的管理者：该角色可分配工位到员工',
  SELECT_INTERFACE: '请选择部门接口人：成员名/工号',
  DEPARTMENT_COLOR: '部门颜色',
  SELECT_DEPARTMENT: '选择部门',
  ORIGANIZE_INFO_TITLE: '组织架构',
  ORG_SEARCH_DEPARTMENT: '请搜索部门',
  ORG_DEPT_SEL_COUNT_SINGULAR: '已选中 {count} 个部门',
  ORG_DEPT_SEL_COUNT_PLURAL: '已选中 {count} 个部门',
  ORIGANIZE_SEARCH_DEPARMENT_INFO: '搜索：部门',
  ADD_CHILD_DEPARTMENT: '添加子部门',
  DEPARTMENT_NAME_TABLE: '部门名',
  DEPARTMENT_COLOR_TABLE: '部门颜色',
  DEPARTMENT_INTERFACE_TABLE: '部门空间经理',
  SUB_DEPARTMENT: '子部门',
  SET_DEPARTMENT_COLOR: '设置部门颜色',
  ADD_DEPEARTMENT: '添加部门',
  ORGANIZATIONAL_TIPS: '温馨提示：',
  ORGANIZATIONAL_TIP_1: '1.该批量导入功能是应用于企业组织架构、用户列表、工位分配的信息初始化',
  ORGANIZATIONAL_TIP_2: '2.若修改已导入的表格信息后再次导入，不会更新/覆盖已存在的员工及工位',
  MANAGEABLE_DESKS: '可管理工位总数',
  MANAGEABLE_DEPARTMENT: '可管理部门总数',
  MANAGEABLE_FLOORS: '可管理楼层总数',
  PLACE_DEPARTMENTS: '工位所属部门',
  ORGAMOZA_DESK_BOOKING: '工位预订',
  ORGAMOZA_DESK_ASSIGNATION: '工位分配',
  ORGAMOZA_DESK_PERMISSION: '工位管理数据权限',
  ORGAMOZA_DESK_SUP: '支持预订有权限的共享工位',
  ORGAMOZA_DESK_FIND: '支持找同事、查询/绑定所有固定工位',
  HIGHER_OFFICE: '上级部门'
}
