import { withMixin } from '../helpers/dva'
import { getPasswordConfig, updatePasswordConfig } from '../pages/custom-password/service'

export default withMixin({
  namespace: 'passwordConfig',
  state: {
    config: {}
  },
  effects: {
    *getPasswordConfig({ payload }, { put, call, select }) {
      const { tenantId } = yield select(_ => _.users.currentUser)
      const res = yield call(getPasswordConfig, tenantId)
      const { errorCode, data } = res
      if (errorCode === 200) {
        yield put({ type: 'updateState', payload: { config: data || {} } })
      }
    },
    *updatePasswordConfig({ payload }, { put, call, select }) {
      const res = yield call(updatePasswordConfig, payload)
      const { errorCode } = res
      if (errorCode === 200) {
        yield put({ type: 'getPasswordConfig' })
      }
    }
  },
  reducers: {}
})
