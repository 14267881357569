import { message } from 'antd'
import { redirectTo } from '../../../helpers/view'
import { withMixin } from '../../../helpers/dva'
import { getUser, getTenantConfig, anyongLogin } from './service'
import { loginOut } from '../../../services/common'
import { dealWithMenus } from '../../../helpers/text'
import { setSignInPage } from '../../../helpers/storage'
// import logoURL from '../../../assets/oppoLogo.png' //oppo 的logo
import logoURL from '../../../assets/dfocusLogo.svg' //dfocus 的logo
import { getCompanyInfo } from '../../../pages/company-setting/service'
import judgingEnv from '../../../helpers/judgingEnv'
import sso from './sso'
import { getAuthToken, setAuthToken, setCanChangepws, removeAuthToken } from '../../../helpers/storage'
import { formatMessage } from 'umi-plugin-react/locale'
import router from 'umi/router'

export default withMixin({
  state: {
    logoURL,
    pcTitle: '',
    // applicationName: '工位空间分配管理'
  },

  effects: {
    *autoLogin({ payload }, { put, call, select }) {
      const { locationQuery } = yield select((_) => _.app)

      const token = getAuthToken()
      const { location } = window
      const { href, origin } = location
      const currentUrl = href.slice(origin.length)
      // and even no Authorization in cookie
      if (!token) {
        if (locationQuery.token && locationQuery.expiredTime) {
          const res = yield call(anyongLogin, {
            token: locationQuery.token,
            expiredTime: locationQuery.expiredTime,
          })
          redirectTo({
            pathname: '/login',
            state: {
              is3rdParty: true,
              res,
            },
          })
          return
        }
        //  没有token的话，判断是不是在钉钉应用内。是的话就调用钉钉登录
        const type = yield judgingEnv()
        const { success } = yield sso(type)
        if (success) {
          return
        }
        if (currentUrl === '/') {
          return redirectTo('/login')
        }
        return redirectTo('/login', currentUrl)
      }
      try {
        const info = yield call(getUser)
        const { success, data, errorCode } = info
        if (errorCode === 200 && data.deskNumberException) {
          message.warning(formatMessage({ id: 'LICENSE_COUNT_DEFAULT_TIPS' }))
        }
        if (!success || errorCode !== 200 || !data) {
          removeAuthToken()
          if (currentUrl === '/') {
            return redirectTo('/login')
          }
          return redirectTo('/login', currentUrl)
        }
        const { data: tenantConfig } = yield call(getTenantConfig, {
          code: `api.response.address.code.${data.tenantId}`,
        })
        const companyInfo = yield call(getCompanyInfo)
        yield put({
          type: 'updateState',
          payload: {
            pcTitle: companyInfo.data.pcTitle,
            logoURL: companyInfo.data.logo,
          },
        })

        setAuthToken(data.token)
        yield put({
          type: 'users/updateState',
          payload: {
            currentUser: dealWithMenus(data),
            locationAlias: tenantConfig,
          },
        })

        //需要修改密码
        if (data.isChangePassword === 0 || data.isChangePassword === 2) {
          if (data.isChangePassword === 2) {
            message.warning(formatMessage({ id: 'PASSWORD_HAS_BEEN_MODIFY_TIPS' }))
          }
          setCanChangepws(data.isChangePassword)
          return redirectTo('/changePassword')
          // if (currentUrl === '/') {
          //   return redirectTo('/')
          // }
          // return redirectTo('/changePassword', currentUrl)
        }
      } catch (err) {
        return err.message && message.error(err.message)
      }
    },
    *loginOut({ payload }, { call, put }) {
      const { success } = yield call(loginOut, payload)
      removeAuthToken()
      redirectTo('/login')
      if (!success) {
        return false
      }
    },
  },
  reducers: {
    updateLogo(state, { payload }) {
      return {
        ...state,
        logoURL: payload,
      }
    },
  },
})
