import * as dd from 'dingtalk-jsapi'

export default function() {
  return new Promise(async function(resolve, reject) {
    try {
      const arr = await Promise.all([checkWelinkEnv(), checkDingDingEnv(), isFromWechat()])
      const typeArr = arr.filter(value => !value.hasError)
      if (typeArr.length) {
        resolve(typeArr[0].type)
      }
      resolve('pc')
    } catch (err) {
      console.log('all  error', err)
      reject(err)
    }
  })
}

function checkWelinkEnv(clientId) {
  return new Promise(async function(resolve, reject) {
    //第一个参数是error
    const timerId = setTimeout(() => {
      resolve({
        type: 'welink',
        hasError: true
      })
    }, 1000 * 2)
    try {
      await window.HWH5.getAuthCode({
        clientId: '20190815134137548462012'
      })
      resolve({
        type: 'welink',
        hasError: false
      })
      console.log('清楚定时器', timerId)
      clearTimeout(timerId)
    } catch (e) {
      clearTimeout(timerId)
      resolve({
        type: 'welink',
        hasError: true
      })
    }
  })
}

function checkDingDingEnv(clientId) {
  return new Promise(function(resolve, reject) {
    try {
      const version = dd.version
      if (version) {
        resolve({
          type: 'dingtalk',
          hasError: false
        })
      } else {
        resolve({
          type: 'dingtalk',
          hasError: true
        })
      }
    } catch (err) {
      resolve({
        type: 'dingtalk',
        hasError: true
      })
    }
  })
}

function isFromWechat() {
  return new Promise(function(resolve, reject) {
    try {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.includes('micromessenger')) {
        resolve({
          type: 'wechat',
          hasError: false
        })
      } else {
        resolve({
          type: 'wechat',
          hasError: true
        })
      }
    } catch (err) {
      resolve({
        type: 'wechat',
        hasError: true
      })
    }
  })
}
