export default {
  PREF_SET_MOBILE_SYS_TITLE: '系统标题设置',
  PREF_SET_EDIT: '编辑',
  PREF_SET_EDIT_MOBILE_SYS_TITLE: '编辑系统标题',
  PREF_SET_SYS_TITLE: '{type}系统标题',
  PREF_SET_SYS_TITLE_PLH: '请输入系统标题',
  PREF_SET_TPL_PREVIEW: '模版预览',
  PREF_SET_CONFIRM: '确定',
  PREF_SET_CANCEL: '取消',
  PREF_SET_ERR_EMPTY: '标题不能为空',
  PREF_SET_ERR_EXCEED: '不能超过{num}个字符',
  PREF_SET_SAVE_SUCCESS: '保存成功',
  PREF_SET_SAVE_FAILURE: '保存失败。网络开小差，请稍后再试'
}
