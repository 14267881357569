import moment from 'moment'
import qs from 'qs'

export const TIME_TYPE = [
  {
    key: 0,
    label: 'LAST_SIX_HOURS'
  },
  {
    key: 1,
    label: 'LAST_HOUR'
  },
  {
    key: 2,
    label: 'TODAY'
  },
  {
    key: 3,
    label: 'WEEK'
  },
  {
    key: 4,
    label: 'MONTH'
  },
  {
    key: 5,
    label: 'CUSTOMIZE'
  }
]

export const getStartTimeFromNow = timeType => {
  return moment().startOf(timeType).valueOf()
}

export const getEndTimeFromNow = timeType => {
  return moment().endOf(timeType).valueOf()
}

export const getSubtractStartTimeFromNow = (num, typeTime) => {
  return moment().subtract(num, typeTime).valueOf()
}

export const routerParams = (path, data) => {
  if (!data || JSON.stringify(data) === '{}') {
    return path
  }
  let str = qs.stringify(data)
  return `${path}?${str}`
}

export const sliceStr = (str, length) => {
  if (!str) {
    return
  }
  const _strLength = strlen(str)

  if (_strLength <= length) {
    return str
  }
  const _str = str.slice(0, length)
  return `${_str}...`
}

export function strlen(str) {
  var len = 0
  for (var i = 0; i < str.length; i++) {
    var c = str.charCodeAt(i)
    //单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      len++
    } else {
      len += 2
    }
  }
  return len
}

export function getStrMaxlengthByWidth(ele) {
  // const baseWidth = window.getComputedStyle(ele).width
  // console.log('baseWidthbaseWidth', baseWidth)
  const baseWidth = '350px'
  const baseFontSize = window.getComputedStyle(ele).fontSize
  const lineWidth = +baseWidth.slice(0, -2)
  // 所计算的strNum为元素内部一行可容纳的字数(不区分中英文)
  return Math.floor(lineWidth / +baseFontSize.slice(0, -2))
}

export function textSize(fontSize, fontFamily, text) {
  var span = document.createElement('span')
  var result = {}
  result.width = span.offsetWidth
  result.height = span.offsetHeight
  span.style.visibility = 'hidden'
  span.style.fontSize = fontSize
  span.style.fontFamily = fontFamily
  span.style.display = 'inline-block'
  document.body.appendChild(span)
  if (typeof span.textContent != 'undefined') {
    span.textContent = text
  } else {
    span.innerText = text
  }
  result.width = parseFloat(window.getComputedStyle(span).width) - result.width
  result.height = parseFloat(window.getComputedStyle(span).height) - result.height
  return result
}
