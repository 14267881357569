import { dfocusTokenExp } from './dfocus'
import { oppoTokenExp } from './oppo'
import { injectedModule } from '../../../config'
window.injectedModule = injectedModule
export function dealwithTokenExp(params) {
  const INJECTED_MAP = {
    dfocus: dfocusTokenExp,
    oppo: oppoTokenExp
  }
  if (!INJECTED_MAP[injectedModule]) {
    throw new Error(`env [${injectedModule}] is not injected correctly`)
  }
  INJECTED_MAP[injectedModule](params)
}
