export default {
  MANAGE_WORK_LOCATION: '办公{location}',
  MANAGE_BUILDINGID: '楼宇ID',
  MANAGE_BUILDINGNAME: '楼宇名称',
  MANAGE_BUILDINGNAME_STATUS: '异常',
  MANAGE_BUILDINGNAME_STATUS_MESSAGE: '该楼宇在第三方被删除',
  MANAGE_LOCATION: '地点',
  MANAGE_SOURCE: '来源',
  MANAGE_SOURCE_FROM_LOCAL: '本地创建',
  MANAGE_SOURCE_FROM_PARTY: '第三方',
  MANAGE_FLOOR: '楼层',
  MANAGE_DESK_TOTAL: '工位总数',
  MANAGE_HOT_DESK_TOTAL: '共享工位数',
  MANAGE_FIXED_DESK_TOTAL: '固定工位数',
  MANAGE_DISABLED_DESK_TOTAL: '未启用工位数',
  MANAGE_DESK_DEPAREMENT_SELECT: '选择工位所属部门',
  MANAGE_STAFF_DEPAREMENT_TAG: '工位标签',
  MANAGE_DESK_TYPE_SELECT: '工位属性',
  MANAGE_DESK_STATUS: '工位状态',
  MANAGE_DESK_SEARCH_PLACEHOLDER: '工位名称/人名/工号',
  MANAGE_DESK_SEARCH_PLACEHOLDER2: '工位名称',
  MANAGE_ADD_DESK: '新建工位',
  MANAGE_OPERATION: '操作',
  MANAGE_DOWN_DATA: '导出本层',
  MANAGE_DESK_DEPAREMENT: '工位所属部门',
  MANAGE_BASIC_INFO: '基本信息',
  MANAGE_KEY_INFO: '属性信息',
  MANAGE_INTERNAL_STAFF: '内部员工',
  MANAGE_STAFF_TYPE: '员工类型',
  MANAGE_EXTERNAL_TRAINEE: '外部人员/实习生',
  MANAGE_EXTERNAL_OUTSOURCING: '外部人员/外包',
  MANAGE_EXTERNAL_OTHER: '外部人员/其他',
  MANAGE_STAFF_NAME: '员工姓名',
  MANAGE_STAFF_DEPAREMENT: '员工所属部门',
  MANAGE_INTERFACER: '空间经理',
  MANAGE_BUTTON_EDIT: '编辑',
  MANAGE_BUTTON_CANCEL: '取消',
  MANAGE_BUTTON_CONFIRM: '确定',
  MANAGE_BUTTON_ENTER: '进入',
  MANAGE_EDIT_BUILDING_AND_FLOOR: '编辑楼宇楼层',
  MANAGE_ADD_BUILDING_AND_FLOOR: '添加楼宇楼层',
  MANAGE_AT_LOCATION: '{location}简称',
  MANAGE_COUNTRY: '国家',
  MANAGE_PROVINCE: '省份',
  MANAGE_CITY: '城市',
  MANAGE_REMARK: '备注',
  MANAGE_BUILDINGNAME_REPEATED: '楼宇名称不可重复',
  MANAGE_BUILDINGNAME_PLACEHOLDER: '请填写楼宇名称',
  MANAGE_LOCATION_PLACEHOLDER: '请填写{location}简称，例如中国上海',
  MANAGE_REMARK_PLACEHOLDER: '请输入备注',
  MANAGE_SAVE_SUCCESS: '保存成功',
  MANAGE_LIST_TOTAL: '共',
  MANAGE_LIST_UNIT: '条记录',
  MANAGE_DOWN_DATAS: '导出所有工位',
  MANAGE_DOWN_DATAS_LIST: '导出所有工位列表',
  MANAGE_EDIT_FLOOR: '编辑楼层',
  MANAGE_BOOKING_RULE: '预订规则',
  MANAGE_BOOKING_RULE_TIP: '该规则应用到该楼层所有的固定工位',
  MANAGE_BOOKING_RULE_EMPTY: '预订规则不可为空',
  MANAGE_INPUT_MAX_LENGTH_50: '最大输入50个字符',
  MANAGE_INPUT_MAX_LENGTH_100: '最大输入100个字符',
  MANAGE_INPUT_MAX_LENGTH: '最大输入{max}个字符',
  MANAGE_BUTTON_DETAIL: '详情',
  MANAGE_BUILDING_DETAIL: '楼宇详情',
  MANAGE_FLOOR_DETAIL: '楼层详情',
  MANAGE_RULE_PREVIEW: '预览',
  MANAGE_RULE_OPEN: '开',
  MANAGE_RULE_CLOSE: '关',
  MANAGE_RULE_BY_HOUR: '按小时',
  MANAGE_RULE_BY_HALF_DAY: '按半天',
  MANAGE_RULE_BY_DAY: '按全天',
  MANAGE_RULE_UNIT_DAY: '天',
  MANAGE_RULE_UNIT_DAYS: '天',
  MANAGE_ADD_DESKS: '添加工位',
  MANAGE_CANTNOT_ASSOGMED: '不可分配',
  MANAGE_DISABLE: '不可用',
  MANAGE_TO_ASSIGN: '去分配',
  MANAGE_CLEAR_STAFF: '清除员工',
  MANAGE_DESK_ASSIGNED: '已分配工位',
  MANAGE_DESK_UNASSIGNED: '待分配工位',
  MANAGE_DESK_LIST: '工位二维码',
  MANAGE_DESK_STATUS_DISPLAY: '工位状态',
  MANAGE_SELECTED_DESCRIPTION: '已选择{total}个标签',
  MANAGE_SELECTED_DESCRIPTIONS: '已选择{total}个标签',
  MANAGE_TAGS_COUNT: '{total}个',
  MANAGE_TOTAL_BUILDING: '共{total}栋楼宇',
  MANAGE_DESK_DEPT: '是否外部门使用',
  MANAGE_QR_CORD: '导出本层二维码',
  MANAGE_LIST: '导出本层工位列表',
  MANAGE_LIST_TITLE: '工位列表',
  MANAGE_DOWN: '下载二维码',
  MANAGE_DOWN_TEXT1: '张贴在桌子上',
  MANAGE_DOWN_TEXT2: '-若为共享工位二维码，员工扫一扫可预订',
  MANAGE_DOWN_TEXT3: '-若为固定工位二维码，员工扫一扫可查看/绑定/解绑',
  MANAGE_SET_STATUS: '设置工位属性',
  MANAGE_TIME_ZONE: '所属时区',
  MANAGE_SET_ATTRIBUTE_PLACEHOLD: '请选择工位属性',
  MANAGE_SET_ATTRIBUTE_HAS_DEPT1: '存在 1 个工位已分配员工或部门，若改变工位属性会清除已分配的信息',
  MANAGE_SET_ATTRIBUTE_HAS_DEPT2: '存在 {deskTotal} 个工位已分配员工或部门，若改变工位属性会清除已分配的信息',
  MANAGE_EDIT_DESK: '编辑工位',
  MANAGE_RULE_INFO: '规则信息',
  MANAGE_RULE_INFORMATION: 'Rule Information',
  MANAGE_CONFIG_INFO: '配置信息',
  MANAGE_CAN_BOOK: '工位是否可预订',
  MANAGE_DISABLED: '禁用',
  MANAGE_CLOSE_BOOKING: '不开放预订',
  MANAGE_OPEN_BOOKING: '开放预订',
  MANAGE_NO_ONE_CAN: '全员不可预订',
  MANAGE_ONLY_MANAGE: '允许超级管理员、管理员在线预订',
  MANAGE_ALLOW_STAFF: '允许员工在线预订',
  MANAGE_AUTHORITY: '权限范围',
  MANAGE_ALL_AVAILABLE: '全员共享',
  MANAGE_CUSTOM: '部分共享',
  MANAGE_AVAILABLER_TIME: '可提前预订日期范围',
  MANAGE_CAN_BOOK_ACROSS: '是否可跨天预订',
  MANAGE_AVAILABLER_MORE_TIME: '能提前预订未来{value}天内的工位',
  MANAGE_MORE_TIME: '能一次连续预订多天的工位',
  MANAGE_CANT_MORE_TIME: '不能连续预订多天的工位',
  MANAGE_ONLY_TODAY: '只能预订当天的工位',
  MANAGE_TODAY_TOMOTTOW: '能预订今天、明天的工位',
  MANAGE_BIND_RULE: '绑定规则',
  MANAGE_BIND_RULE_INFO: '规则信息',
  MANAGE_EDIT_IN_FLOOR: '请在该工位所在的楼层编辑规则',
  MANAGE_PREVIEW: '预览',
  MANAGE_SET_RULE: '共享工位规则设置',
  MANAGE_BOOKING_WAY: '预订方式',
  MANAGE_BOOK_HOUR: '小时制',
  MANAGE_BOOK_HALF_DAY: '半天制',
  MANAGE_BOOK_ALL_DAY: '全天制',
  MANAGE_DAILY: '每天（周一到周日)',
  MANAGE_WORKING: '工作日（周一到周五)',
  MANAGE_AM: '上午',
  MANAGE_PM: '下午',
  MANAGE_BEGIN: '开始',
  MANAGE_END: '结束',
  MANAGE_CAN_BOOK_TIME: '可预订时间',
  MANAGE_SEARCH_NAME_PLH: '请搜索员工姓名',
  MANAGE_ITEM_SEL_COUNT_SINGULAR: '已选择 {total} 项',
  MANAGE_ITEM_SEL_COUNT_PLURAL: '已选择 {total} 项',
  MANAGE_SHARED_DESK_SEL_COUNT_SINGULAR: '共选中 {total} 条',
  MANAGE_SHARED_DESK_SEL_COUNT_PLURAL: '共选中 {total} 条',
  MANAGE_ONLY: '仅',
  MANAGE_ABLE_TO_BOOK: '可预订',
  MANAGE_BOOKING_BY_HOUR_DES: '当预订方式为小时制，时间筛选器可按15分钟的颗粒度选择',
  MANAGE_BOOKING_BY_HALF_DAY_DES: '当预订方式为半天制时，时间筛选器可选择“上午”和“下午”',
  MANAGE_BOOKING_BY_FULL_DAY_DES: '当预订方式为全天制时，时间筛选器只能选择日期',
  MANAGE_SEL_DEPT_STAFF: '选择部门和员工',
  MANAGE_BOOKABLE_USERS: '可预订用户',
  MANAGE_SPECIFIC_DEPT_STAFF: '指定部门及员工',
  CUSTOM_RULE: '自定义规则',
  CAN_BOOK_IN_ADVANCE: '可提前预订',
  BOOK_DESK_OF_CURRENT_DAY: '仅可 {time} 预订 当天 的工位',
  IMPORT_BUILDING_AND_FLOOR: '导入楼宇楼层',
  MANAGE_PLEASE_ADDRESS_NAME: '请输入{location}简称',
  IMPORT_BUILDING_AND_FLOOR_FILE_NAME: '楼宇楼层导入模版.xlsx',
  CONFIRM_DEL_BUILDING_TIP: '确定要删除楼宇【{name}】吗？',
  CONFIRM_DEL_FLOOR_TIP: '确定要删除楼层【{name}】吗？',
  CONFIRM_DEL_DESK_TIP: '确定要删除工位【{name}】吗？',
  MANAGE_BUILDING_HAS_FLOOR_OR_BUILDING: '当前楼宇下已创建了楼层或工位，删除后相关数据无法找到，请谨慎操作',
  MANAGE_DELETE_BUILDING_NEED_INPUT_DOWN: '请在下方输入“{name}”以确认操作',
  MANAGE_DELETE_BUILDING_NEED_INPUT: '请输入“{name}”以确认操作',
  MANAGE_INPUT_ERROR: '输入字符错误, 请重新输入',
  MANAGE_NOT_DELETE_BUILDING_BECAUSE_IT_HAS_MAP: '该楼宇已绑定了地图，不可被删除',
  CONFIRM_DELETE: '确认删除',
  ADD_BUILDING_AND_FLOOR: '+添加楼宇/楼层',
  EDIT_BUILDING_AND_FLOOR: '编辑楼宇/楼层',
  ADD_FLOOR: '添加楼层',
  BLDG_HINT_FLOOR_FORMAT: '提示：根据用户使用习惯选择楼层命名单位，例：F1或L1',
  BLDG_INTERLAYER: '是夹层',
  BLDG_NOT_INTERLAYER: '不是夹层',
  BLDG_FLOOR_NUM_PLH: '楼层序号输入',
  BLDG_FLOOR_NAME_PLH: '楼层名称',
  BLDG_FLOOR_AREA: '楼层面积',
  BLDG_FLOOR_ATTR: '楼层属性',
  BLDG_FLOOR_NUM: '楼层序号',
  BLDG_FLOOR_NAME: '楼层名称',
  // validate
  BLDG_NOT_EDITED: '尚未进行编辑',
  BLDG_NEC_ITEM_MISSING: '必填项尚未填写',
  BLDG_FLOOR_DUPL: '相同楼层属性，楼层序号不能重复',
  BLDG_FLOOR_MISSING: '请添加楼层',
  BLDG_ADDED_FLOORS: '已添楼层',
  BUILDING_INFO: '楼层信息',
  BLDG_PLS_INPUT_STH: '请输入{text}',

  MANAGE_HAS_MAP_CANT_MODIFY_NUM: '该楼层已绑定地图，不能更改楼层序号',
  MANAGE_HAS_MAP_CANT_DELETE: '该楼层已绑定地图，不能删除',
  MANAGE_CANT_DELETE_BUILDING: '不可删除楼宇【{name}】',
  MANAGE_CANT_DELETE_FLOOR: '不可删除楼层【{name}】',
  MANAGE_DELETE_DESK: '删除工位',
  MANAGE_SELECTED_DESK_DELETE: '已选中{count}个工位，确认批量删除吗？',
  MANAGE_DESK_HAS_MAP_TIPS: '存在{count}个关联了地图的工位，一旦删除数据将无法找回，请谨慎操作',

  LICENSE_NUMBER_USED: '{value} 个许可证可用(共{total}个)',
  MEETING_ROOM_NOT_AUTHRIZED: '许可证收回后：工位将无法被分配、绑定或预订。',
  LICENSE_SELECT_FIX_DESK: '共选中：{count}个固定工位',
  LICENSE_SELECT_SHARE_DESK: '共选中：{count}个共享工位',
  LICENSE_NUMBER_SHARE_DESKING_ROOM_USED: '{value} 个共享工位许可证可用（共 {total} 个）',
  LICENSE_NUMBER_FIX_DESKING_ROOM_USED: '{value} 个固定工位许可证可用（共 {total} 个）',
  DISTRIBUTE_SPACE_LICENSE_TIPS: '分配许可证后：固定工位可以被分配或被绑定，共享工位可以被预订。',
  CLOSE_SPACE_LICENSE: '收回许可证',
  CLOSE_SPACE_LICENSE_TIPS: '工位未授权，将不允许分配或预订工位',
  DISTRIBUTE_SPACE_LICENSE: '分配许可证',
  MANAGE_LICENSE: '许可证',
  SELECTED_SPACES_COUNT: '共选中 {total} 个会议室',
  SPACE_BATCH_MANDATE_LICENSE: '批量授权',
  MANAGE_EXPORT_ALL_BUILDING_DESKS: '导出所有楼宇的工位列表',
  BLDG_HINT_MDF_FLOOR: '确定要修改该楼层信息吗？',
  BLDG_HINT_MDF_FLOOR_DES: '当前楼层下已创建了工位，更改楼层序号后，工位以及工位数据会绑定在更改后的楼层序号下',
  BLDG_HINT_DEL_FLOOR: '确定要删除该楼层吗？',
  MANAGE_SEARCH_BUILDING_KEYWORDS: '关键词：楼宇名称、{location}简称',
  MANAGE_HAS_DESK_WHEN_DELETE_TIPS: '当前楼层下已创建了工位，删除后工位及工位数据无法找到，请谨慎操作',
  MANAGE_DELETE_DATA_TIPS: '一旦删除数据无法找回，请谨慎操作。',
  MANAGE_DESK_RELATIVE_MAP_TIPS: '该工位关联了地图，一旦删除数据无法找回，请谨慎操作。',
  LICENSE_COUNT_DEFAULT_TIPS: '许可证数量异常，请调整许可证分配',
  SUBSCRIPTION_INFORMATION: '订阅信息',
  FIXED_DESK_LICENSE: '固定工位许可证',
  HOT_DESK_LICENSE: '共享工位许可证',
  SERVICE_PERIOD_OF_FIXED_DESK: '固定工位服务周期',
  HOT_DSK_SERVICE_PERIOD: '共享工位服务周期',
  REMAINING_AVAILABLE: '剩余可用',
  REMAINING_NOT_AVAILABLE: '剩余不可用',
  REMAINING_DAYS: '剩余天数',
  OVERDUE_DAYS: '过期天数',
  GE_UNIT: '个',
  FIXED_DESK_PURCHASED_NUMBER: '已购买固定工位数量：{val}个',
  FIXED_DESK_AUTHORIZED_NUMBER: '已授权固定工位数量：{val}个',
  HOT_DESK_PURCHASED_NUMBER: '已购买共享工位数量：{val}个',
  HOT_DESK_AUTHORIZED_NUMBER: '已授权共享工位数量：{val}个',
  LICENSE_START_DATE: '开始日期：{val}',
  LICENSE_END_DATE: '结束日期：{val}',
  SUBSCRIPTION_HISTORY: '订阅记录',
  CREATION_TIME: '创建时间',
  LICENSE_NUMBER: '许可证数量',
  SERVICE_PERIOD: '服务周期',
  SERVICE_STATUS: '服务状态',
  EXPIRY_DATE: '到期时间',
  LICENSE_NOT_STARTED: '未开始',
  LICENSE_IN_SERVICE: '服务中',
  LICENSE_FAIL: '已过期',
  PAID_NOTICE: '付费通知',
  PAID_NOTICE_INFO: '产品授权过期前 30 天、前 15 天、前 7 天和最后三天，系统会给以下成员发送邮件通知',
  SEARCH_MEMBER: '搜索成员',
  NO_NOTIFICATION_MAILBOX: '暂无通知的邮箱',
  LICENSE_ENTER_ADDRESS: '请输入邮箱',
  SERVICE_EXPIRATION_NOTICE: '服务到期通知',
  CONTACT_AFTERMARKET: '您可联系售后进行线下续费。',
  NOTIFY_USER: '通知用户',
  UNAUTHORIZED: '未授权',
  AUTHORIZED: '已授权'
}
