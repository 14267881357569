export default {
  APPROVE_UN_HANDLE: '未处理',
  APPROVE_HANDLEED: '已处理',
  APPROVE_RECALL: '已撤销',
  APPROVE_REFUSED: '已拒绝',
  APPROVE_RECEIVE_EMAIL_OF_PEOPLE: '设置邮件接收人',
  APPROVE_SEARCH: '搜索：人名',
  APPROVE_DESCRIPTION: '申请描述',
  APPROVE_PEOPLE: '申请人',
  APPROVE_TIME: '申请时间',
  APPROVE_HANDLE_TIME: '处理时间',
  APPROVE_HANDLE_TYPE: '处理类型',
  APPROVE_HANDLE_PEOPLE: '处理人',
  APPROVE_STATUS: '状态',
  APPROVE_HANDLE: '处理',
  APPROVE_REFUSE: '拒绝',
  APPROVE_CONTENT_QUOTA: '申请工位额度由 {nowDeskQuota} 改为 {amount}',
  APPROVE_FEEDBACK: '申请反馈',
  APPROVE_ITEMS: '共 {total} 项',
  APPROVE_CONTENT: '申请内容',
  APPROVE_NOW_QUOTA: '现有工位额度',
  APPROVE_SOLVE_WAY: '解决方案',
  APPROVE_SOLVE_STATUS_2: '无需处理，关闭申请',
  APPROVE_SOLVE_STATUS_3: '去变更工位额度',
  APPROVE_CHANGE_QUOTA: '变更工位额度',
  APPROVE_CONFIRM_REFUSE: '确认拒绝',
  APPROVE_DETAIL: '申请详情',
  APPROVE_PROCESS_DETAIL: '处理详情',
  APPROVE_EXAMINE: '审批意见',
  APPROVE_RECEIVE_MESSAGE_EMAIL: '接收通知的邮箱',
  APPROVE_SEND_APPROVE: '接收申请',
  APPROVE_ASSIGNED_FAILED: '分配失败',
  APPROVE_ASSIGNED_CAN_NOT_QUOTA: '已分配工位数不能大于工位额度',
  APPROVE_CONFIRM_USER_TO_DESK_NAME: '确定要分配工位【 {deskName} 】给 {userName} 吗?',
  APPROVE_DESK_QUOTA_ASSIGNED_DESK: ' {userName} 工位额度为 {deskQuota} , 已分配工位数 {assignedLength} {desksName}',
  PLEASE_INPUT_LIMIT_WORDS: '请输入 {count} 字以内'
}
