export default {
  /* eslint-disable */
  BOOKING_RULE_ALREADY_EXIST: 'Booking rule name existed!',
  RELEASE_RULE_ALREADY_EXIST: 'Auto release rule name existed!',
  // 会议规则
  MEETING_RULE: 'All-day meeting & Cross-day meeting rule',
  // 第三方集成
  MANAGEMENT_ROOMS_CALENDAR_SYNC: 'Meeting room calendar synchronization',
  CALENDAR_SYNC_ADD_BTN: 'Add a third-party calendar',
  ADD_ROOM_CALENDAR_SYNC: 'Add the meeting room calendar',
  CALENDAR_SYNC_LIST: 'Calendar list',
  MANAGEMENT_ACCOUNT_INTEGRATIONPERM: '3rd-party account integration',
  ACCOUNT_CONFIG_ADD_BTN: 'Add the 3rd-party account',
  CALENDAR_SYNC_ADD_BTN_DISABLED: 'Only one third-party calendar can you configure.',

  CALENDAR_SYNC_NODATA: '3rd-party calendar not added',
  ACCOUNT_CONFIG_NODATA: '3rd-party account not added',
  INTERGRATION_ALREADY_CONNECT: 'Connected',
  INTERGRATION_ALREADY_DISCONNEC: 'Disconnected',
  INTERGRATION_CONNECT: 'Connect',
  INTERGRATION_DISCONNECT: 'Disconnected',
  INTERGRATION_REMOVE: 'Remove',
  INTERGRATION_UPDATE_CONFIG: 'Update the configuration',
  INTERGRATION_CHECK_CONFIG: 'Check the configuration',
  INTERGRATION_CONFIG_TEXT: 'Configuration',
  INTERGRATION_CONFIG_MANAGEMENT: 'Manage',
  SETTING_ADD_FAILED: 'Adding failed',
  SETTING_ADD_SECCESS: 'Added success',
  SETTING_UPDATE_SECCESS: 'Updated success',
  SETTING_UPDATE_FAILED: 'Updating failed',
  SETTING_ADD_CONFIG_FAILED: 'Configuration adding failed',
  SETTING_ADD_CONFIG_SECCESS: 'Configuration added success',
  SETTING_UPDATE_CONFIG_FAILED: 'Configuration updating failed',
  SETTING_UPDATE_CONFIG_SECCESS: 'Configuration updated success',
  SETTING_DELETE_CONFIG_FAILED: 'Configuration deleting failed',
  SETTING_DELETE_CONFIG_SECCESS: 'Configuration deleted success',
  SETTING_DISCONNECT_CONFIG_FAILED: 'Configuration disconnecting failed',
  SETTING_DISCONNECT_CONFIG_SECCESS: 'Configuration disconnected success',
  SETTING_CONNECT_CONFIG_FAILED: 'Configuration connecting failed',
  SETTING_CONNECT_CONFIG_SECCESS: 'Configuration connected success',

  // form
  TIP_HEADER_INPUT: 'Please enter ',
  CREATE_CONFIG: 'Create',
  CONFIRM_DISCONNECT_CONFIG: 'Confirm to disconnect the configuration?',
  CONFIRM_DELETE_CONFIG: 'Confirm to remove the configuration?',
  SELECT_VERSION: 'select the version',
  PLEASE_SELECT_VERSION: 'Please select the version',
  CONFIRM_DELETE_CALENDAR_CONFIG:
    'Removing this configuration will also turn off the 3rd-party synchronization function of all meeting rooms and delete all future meetings already synchronized.',
  RM_CALENDAR_CONFIG_TIP: 'Please type "{code}" to confirm',
  SELECT_THIRDPART: 'select third-part',
  PLEASE_SELECT_THIRDPART: 'Please select a 3rd-party',
  CONFIG_NAME: 'Account',
  PLEASE_INPUT_CONFIG_ACCOUNT: 'Please enter the name of account',
  PLEASE_INPUT_CONFIG_NAME: 'Please enter the name',
  EWS_LOCATION: 'EWS address',
  PLEASE_INPUT_EWS_LOCATION: 'Please enter EWS address of Exchange service',
  EXCHANGE_NAEM: 'Service account',
  PLEASE_INPUT_EXCHANGE_NAEM: 'Please enter the name of service NO',
  EXCHANGE_NAEM_ERROR: 'No special characters are contained in the name',
  EXCHANGE_PASSWORD: 'Service acc. pwd',
  PLEASE_INPUT_EXCHANGE_PASSWORD: 'Please enter the password of service NO',
  INTER_SERVICE_CONFIG: 'Server configuration',
  LDAP_FORM_TYPE: 'Contents name',
  PLEASE_SELECT_LDAP_FORM_TYPE: 'Please select the contents naming',
  LDAP_HOST_URL: 'Host',
  LDAP_HOST_URL_PLACEHOLDER: 'Name of server mainframe running LDAP, for example, ldap.example.com',
  PLEASE_INPUT_LDAP_HOST_URL: 'Please enter host',
  LDAP_PORT: 'Port',
  LDAP_PORT_ERROR: 'Only numbers allowed for Port NO',
  PLEASE_INPUT_LDAP_PORT: 'Please enter the port',
  LDAP_BASE_DN: 'Basic DN',
  LDAP_BASE_DN_PLACEHOLDER: 'Search the user and group from LDAP root node, for example, cn=users,dc=example,dc=com',
  PLEASE_INPUT_LDAP_BASE_DN: 'Please enter the basic domain',
  LDAP_USER_NAME: 'User name',
  LDAP_USER_NAME_PLACEHOLDER: 'User log onto LDAP, for example, user@domain.name or cn = user, dc = domain, dc = name',
  PLEASE_INPUT_LDAP_USER_NAME: 'please enter the username',
  LDAP_USER_PASSWORD: 'Password',
  PLEASE_INPUT_LDAP_USER_PASSWORD: 'Please enter password',
  LDAP_MODEL_SETTING: 'User mode setup',
  LDAP_OBJECT_CLASS: 'User Object Classes',
  LDAP_OBJECT_CLASS_PLACEHOLDER:
    'Please enter the user object class. LDAP user object class that used when loading the user.',
  PLEASE_INPUT_LDAP_OBJECT_CLASS: 'Please enter user object class',
  LDAP_USER_EMAIL: 'User e-mail property',
  LDAP_USER_EMAIL_PLACEHOLDER: 'Please enter the user e-mail. The domain is used for loading the user e-mail.',
  PLEASE_INPUT_LDAP_USER_EMAIL: 'Please enter the user e-mail property. ',

  OFFICE_TENANT_ID: 'Tenant ID',
  PLEASE_INPUT_OFFICE_TENANT_ID: 'Please enter o365 tenant ID.',
  OFFICE_CLIENT_ID: 'Application ID',
  PLEASE_INPUT_OFFICE_CLIENT_ID: 'Please enter application ID',
  OFFICE_CLIENT_SECRET: 'Generated key',
  PLEASE_INPUT_OFFICE_CLIENT_SECRET: 'Please enter the generated key.',
  OFFICE_REDIRECT_URL: 'Callback address',
  CONFIG_VERSION_CHINA: 'Chinese version',
  CONFIG_VERSION_INTERNATION: 'International version',
  CONFIG_VERSION: 'Version',
  OFFICE_SYNC_TYPE: 'Synchronization property',
  OFFICE_SYNC_TYPE_HALF: 'Semi-sync',
  OFFICE_SYNC_TYPE_FULL: 'Full-sync',
  CHECK_CONFIG_EXPLAIN: 'Check the configuration description',
  EXCHANGE_FORM_EXPLAIN:
    'DFocus connects with Exchange via the account, which acts as a user account authorized to DFocus and has the permission in managing the enterprise meeting room account.',
  LDAP_FORM_EXPLAIN:
    'The enterprise needs to acquire the following message in LDAP management for AD connection to DFocus system.',
  OFFICE_FORM_EXPLAIN:
    'The enterprise needs to acquire the following message in O365 management for O365 connection to DFocus system.',
  DING_TALK: 'Ding Talk',
  WECHAT: 'Wechat',
  // Error Code
  EWS_ALREADY_EXIST: 'EWS url already exists',

  // rule
  BOOKING_RULE_NOT_EDITED: 'You have not edited anything',
  BOOKING_RULE_CFM_BTN_TIP: 'Necessary items not filled (if booking schedule is on, a valid frequency is required)',
  BOOKING_RULE_CFM_DATE_ERROR_TIP: 'When the deadline exists, the start date cannot be greater than the deadline',
  RELEASING_RULE_NOT_EDITED: 'Editing not completed',
  RELEASING_RULE_CFM_BTN_TIP:
    'Necessary items not filled (if releasing schedule is on, a valid frequency is required) or invalid time setting',

  // mailbox
  MAILBOX_COUNT: '{total} e-mails',
  MAILBOX_VALID_COUNT: '{total} valid e-mails',
  MAILBOX_VALID: 'Valid',
  MAILBOX_INVALID: 'Invalid',
  MAILBOX_COUNT_TIP:
    'Tip: The following e-mail addresses are used to send system mails which could not be sent before setup.',
  MAILBOX_ADD_EMAIL: 'Add',
  MAILBOX_ADD_SYS_EMAIL: 'Add System E-mail',
  MAILBOX_EDIT_SYS_EMAIL: 'Edit e-mail',
  MAILBOX_BATCH_ADD_EMAIL: 'Add in batch',
  MAILBOX_CFM: 'Confirm',
  MAILBOX_EMAIL: 'E-mail',
  MAILBOX_PASSWORD: 'Password',
  MAILBOX_SERVER: 'Server',
  MAILBOX_PORT: 'Port',
  MAILBOX_COVER_DEFAULT_PORT: 'Cover default port',
  MAILBOX_USE_SSL: 'Use SSL connection (recommended)',
  MAILBOX_USE_STARTTLS: 'Use STARTTLS encryption',
  MAILBOX_NOT_EDITED: 'Editing not completed',
  MAILBOX_NEC_ITEM_MISSING: 'Required fields not completed ',
  MAILBOX_PLS_INPUT: 'Please enter',
  MAILBOX_PORT_EMPTY: 'Port is empty',
  MAILBOX_PORT_INVALID: 'Invalid port',
  MAILBOX_EMAIL_EMPTY: 'E-mail cannot be empty',
  MAILBOX_EMAIL_INVALID: 'Invalid e-mail',
  MAILBOX_PWD_EMPTY: 'Password cannot be empty',
  MAILBOX_SERVER_EMPTY: 'Server cannot be empty',
  MAILBOX_DEL_TIP: 'Do you want to delete this e-mail?',
  MAILBOX_ADD_SUCCEED: 'Add e-mail succeed',
  MAILBOX_EDIT_SUCCEED: 'Edit e-mail succeed',
  MAILBOX_DEL_SUCCEED: 'Delete e-mail succeed',
  MAILBOX_CLOSE: 'Close',
  MAILBOX_DNLD_FAILURE_FILE: 'Download file',
  MAILBOX_IMPORT_COMPLETED: 'Import succeed {successCount}，failed {failedCount}',
  MAILBOX_IMPORT_FAILED: 'Import failed, please try again',
  MAILBOX_DNLD_TEMPLATE: 'Download template',
  MAILBOX_UPLOAD_TIP: 'Please upload files (Filename extensions xlsx, xls uploaded individually)',
  MAILBOX_IMPORT: 'Batch import e-mails',

  // error code
  MAILBOX_ERR_90001: 'Invalid e-mail configuration',
  MAILBOX_ERR_90006: 'Email address, password, or server address error.',
  MAILBOX_ERR_90007: 'E-mail configuration already exists',

  SYSTEM_MAIL: 'Set System E-mail',
  COVER_DEFAULT_PORT: 'Cover the default port',
  USE_SSL_CONNECT: 'Use SSL connection (recommended)',
  USE_STARTTLS_ENCRYPT: 'Use STARTTLS encryption',
  E_MAIL: 'Email',
  PLEASE_INPUT_CORRECT: 'Please enter legal',
  PASSWORD: 'Password',
  SEND_SERVER: 'Outgoing server',
  PORT: 'Port'
}
