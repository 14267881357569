export default {
  DESKRULE_RULE_SETTING: '规则设置',
  DESKRULE_BOOKING_RULE: '预订规则',
  DESK_RULE_AUTO_RELEASE_RULE: '自动释放规则',
  DESK_RULE_CUSTOM_RELEASE_RULE: '自定义释放规则',
  DESKRULE_ADD_RULE: '+ 添加规则',
  DESKRULE_DEFAULT_BOOKING_RULE: '默认预订规则',
  DESKRULE_EDIT_BOOKING_RULE: '编辑预订规则',
  UNLIMITED: '不限',
  DESKRULE_BOOKING_WAY: '预订方式',
  DESKRULE_BUTTON_CANCEL: '取消',
  DESKRULE_BUTTON_CONFIRM: '确定',
  DESKRULE_BOOKING_RULE_PLACEHOLDER: '规则名称',
  DESKRULE_INPUT_MAX_LENGTH_50: '最大输入50个字符',
  DESKRULE_SAVE_SUCCESS: '保存成功',
  DESKRULE_DELETE_RULE: '删除规则',
  DESKRULE_DELETE_SUCCESS: '删除成功',
  DESKRULE_DELETE_RULE_CONFIRM: '确定要删除预订规则',
  DESKRULE_DELETE_RULE_CONFIRM_UNIT: '吗?',
  DESKRULE_SWITCH_OPEN: '开启',
  DESKRULE_SWITCH_CLOSE: '关闭',
  DESKRULE_BOOKING_BY_HOUR: '按小时',
  DESKRULE_BOOKING_BY_HALF_DAY: '按半天',
  DESKRULE_BOOKING_BY_DAY: '按全天',
  DESKRULE_LIST_TOTAL: '共',
  DESKRULE_LIST_UNIT: '条规则',
  DESKRULE_DELETE_TIPS: '提示',
  DESKRULE_BOOKING_SCAN: '扫码预订（仅手机端）',
  DESKRULE_BOOKING_MORE_DAY: '跨天预订',
  DESKRULE_BOOKING_INNER: '代内部员工预订',
  DESKRULE_BOOKING_OUTTER: '代外部员工预订',
  DESKRULE_BOOKING_MANY_DAY: '可提前预订未来',
  DESKRULE_BOOKING_MANY_DAY_UNIT: '天内工位',
  DESKRULE_BOOKING_RULE_BY_HOUR: '按小时预订',
  DESKRULE_BOOKING_RULE_BY_HALF_DAY: '按半天预订',
  DESKRULE_BOOKING_RULE_BY_DAY: '按全天预订',
  DESKRULE_BOOKING_UNIT_DAY: '天',
  DESKRULE_BOOKING_UNIT_DAYS: '天',
  DESKRULE_ADD_SUCCESS: '添加成功',
  DESKRULE_ONLY_MOBILE: '仅手机端',
  DESKRULE_BOOKED_SEATS: '可单次预订最多',
  DESKRULE_BOOKED_MANY_SEATS_UNIT: '个工位',
  DESKRULE_BOOKED_SEATS_UNIT: '个',
  DESKRULE_BOOKED_SEATS_UNITS: '个',
  BIND_ADD_TITLE: '添加绑定规则',
  BIND_EDIT_TITLE: '编辑绑定规则',
  BIND_RULE_TITLE: '绑定规则',
  BIND_DELETE_TEXT: '确定要删除预订规则【{name}】吗?',
  BIND_NOTE: '*注: 绑定规则将运用于固定工位, 固定工位有三种状态: 未分配、已分配员工、已分配部门',
  BIND_REPLACE_TEXT: '替换成员工所属部门',
  BIND_STATIC_TEXT: '保持原工位所属部门不变',
  BIND_DEPARTMENT_TEXT: '工位所属部门会',
  BIND_DEPARTMENT_CHANGE: '替换成员工所属部门',
  BIND_DEPARTMENT_STATIC: '保持原工位所属部门不变',
  RELEASE_RULE_ALREADY_EXIST: '自动释放规则名称已存在！',
  RELEASING_RULE_CFM_BTN_TIP:
    '必填项未填写或不符合规则（如开启“不可自动释放日期及时间”选项，需要选择正确的频率）或相关时间设置不正确',
  CONFIRM_MODIFY_RULE_MA: '确认要修改该条规则吗？',
  CONFIRM_MODIFY_RULE_TIP_JINSHEN: '该条规则修改后，该条规则所关联工位都将受到影响，请谨慎操作！',
  IS_SEND_EMAIL_SMOKING_PEOPLE: '是否发送邮件通知受影响的会议预订人',
  KEY_AUTO_RELEASE_NAME: '关键词: 自动释放规则名称',
  CONFIRM_DEL_RULE_MA: '确认删除该条规则吗？',
  CONFIRM_DEL_RULE_MA_DETAILS: '删除后，执行该条规则的工位将恢复默认规则，请谨慎操作！',
  CONFIRM_DEL_RELEASE_RULE_TIP: '确定要删除释放规则【{name}】吗？',
  AUTO_RELEASE_RULE: '开始释放规则',
  MEETING_BEGIN_AUTO_RELEASE_NO_PERSON: '开始 {total} 分钟后无人则释放',
  AUTO_RELEASE_SUCCESS_EMAIL: '自动释放成功，邮件通知预订人、发起人',
  AUTO_RELEASE_BEFORE_EMAIL: '自动释放前，邮件提醒预订人',
  MEETING_END_ADVANCE_RELEASE: '工位使用中可自动释放',
  MEETING_END_ADVANCE_MINUTE_BAFTER_AUTO_RELEASE: '进行中 {total} 分钟后无人则释放',
  CAN_NOT_AUTO_RELEASE: '不会自动释放日期及时间',
  PLEASE_INPUT_RULE_NAME_VALID_TIP: '规则名称不能为空',
  PLEASE_INPUT_NUM_LETTER: '不能超过{num}个字符',
  RULE_NAME: '规则名称',
  AUTO_RELEASE_BEFORE: '自动释放前',
  TO_NOTICE_BOOKER: '分钟会邮箱通知预订人',
  MEETING_END_ADVANCE: '工位使用中持续',
  MINUTE_BAFTER_AUTO_RELEASE: '分钟无人则自动释放',
  START_DATE: '开始日期',
  PLEASE_INPUT_BEGIN_DATE: '请输入开始日期',
  DUE_DATE: '截止日期',
  PLEASE_INPUT_CUTOFF_DATE: '请输入截止日期',
  NO_SELECT_UNLIMITED_END_DATE: '不选择不限结束日期',
  STATUS_OPERATE_AUTO_RELEASE: '{status}自动释放规则',
  TOTAL_RULE: '共 {total} 条规则',
  ADD_AUTO_RELEASE_RULE: '添加释放规则',
  AUTO_RELEASE_NO_PERSON_INPUT: '工位预订时间开始后 {component} 分钟后无人则自动释放',
  RELEASE_RULE_DESC_SINCE_BEGIN: '工位预订时间开始后多少分钟无人则释放',
  RELEASE_RULE_FOR_BUSY_DESK: '工位使用中释放规则',
  RELEASE_RULE_FOR_LASTING_NO_SHOW: `工位使用中持续{total}分钟无人则释放`,
  RELEASE_RULE_FOR_LASTING_NO_SHOW_S: `工位使用中持续{total}分钟无人则释放`,
  // rule
  BOOKING_RULE_NOT_EDITED: '尚未进行编辑',
  BOOKING_RULE_CFM_BTN_TIP: '必填项未填写或填写不正确（如规则不符合长度等）',
  BOOKING_RULE_CFM_DATE_ERROR_TIP: '“截止日期”存在时，“开始日期”不能大于“截止日期”',
  RELEASING_RULE_NOT_EDITED: '尚未进行编辑',
  RULE: '规则',
  MEETING_ROOM_BOOKING: '会议室预订规则',
  AUTO_RELEASE: '工位是否自动释放',
  STATUS_LIGHT: '状态灯规则',
  EMAIL_NOTIFICATION: '邮件通知规则',
  STATUS_CONFIRM_BTN: '确认{status}',
  MEETING_BEGIN: '会议开始',
  AUTO_RELEASE_NO_PERSON: '分钟后无人则释放',
  AUTO_RELEASE_BEFORE_TO_NOTICE_BOOKER: '自动释放前 {total} 分钟会邮箱通知预订人',
  MEETING_DURATION: '会议时长',
  CAN_BOOKING_MEETING_AFTER_INPUT: '可预订 {component} 天内的会议',
  BOOKING_SUCCESS_NOTICE: '预订成功系统通知',
  BOOKING_SUCCESS_EMAIL: '预订成功邮件通知',
  CAN_BOOKING_TIME: '可预订会议日期及时间',
  ADD_CAN_BOOKING_RULE: '添加预订规则',
  NO_SELECT_DEADLINE_NO_UNLIMITE: '请选择截止日期',
  SPONSOR: '发起人',
  SCHEDULER: '预订人',
  PARTICIPANT: '参与人',
  APPT_ADMIN: '会议管理员',
  CONFIRM: '确认',
  CANCEL: '取消',
  MEETING_BEFORE_SEND_EMAIL: '会议前发送邮件通知',
  MEETING_BEFORE_NOTICE: '会前通知设置',
  MEETING_BEFORE: '会议开始前',
  MINUTE_NOTICE: '分钟通知',
  SEND_TO_MEETER: '发送给会议的',
  EVERY_DAY: '每天',
  EVERY_WEEK: '每周',
  EVERY_MONTH: '每月',
  BEGIN_TIME: '开始时间',
  END_TIME: '结束时间',
  PLEASE_INPUT_RULE_NAME: '请输入规则名称',
  RULE_NAME_TIP: '建议中英文全部填写，中文与英文之间用/符号隔开',
  CYCLE: '频率',
  SHORTEST: '最短',
  LONGEST: '最长',
  MINUTE: '分钟',
  HOUR: '小时',
  STATUS_BOOKING_RULE: '{status}预订规则',
  CUSTOM_BOOKING_RULE: '自定义预订规则',
  CUSTOM_BINDING_RULE: '自定义绑定规则',
  KEY_BOOKING_NAME: '关键词: 预订规则名称',
  KEY_BINDING_NAME: '关键词: 绑定规则名称',
  CAN_USE_BOOKING: '可使用/可预订',
  USING: '使用中/不可预订',
  OCCUPIED: '占用中',
  MEETING_ROOM_COLOR: '会议室{status}提示色',
  ROOM_COLOR_TIPS: '提示：',
  ROOM_COLOR_TIPS_COMMON: '即代表',
  ROOM_COLOR_TIPS_CANUSE: '会议室没人使用或没人预订',
  ROOM_COLOR_TIPS_WAITING: '会议已开始，会议室尚无人员进入',
  ROOM_COLOR_TIPS_USING: '会议室有人使用',
  ROOM_COLOR_TIPS_OCCUPIED: '会议室被非正规使用',
  ALL_DAY_MEETING_RULE_SETTING: '全天&跨天会议规则设置',
  MEETING_DONNOT_RELEASE_TIME: '会议不可自动释放时段',
  ROOM_COLOR_TIPS_TWO_FONTS: '提示',
  CONFIRM_MODIFY_GLOBAL_RULE: '确认修改全局规则吗？',
  INPUT_BOOKING_CAN_PLEASE: '请输入可预订多少天后会议',
  BOOKING_RULES_TIPS: '用户当天{time}可以预订工位',
  IS_CROSS_DAYS: '可跨天预订',
  IS_CROSS_DAYS_TIPS: '可跨天预订提示',
  BOOK_DAYS_RANGE_TIPS: '可预订日期范围提示',
  MNG_RULE_PLS_SEL: '请选择',
  MNG_RULE_DEFINE: '自定义规则',
  MNG_RULE_AUTO_RELEASE: '自动释放',
  MNG_RULE_EXPAND_UNFOLD: '详情',
  MNG_RULE_EXPAND_FOLD: '收起'
}
