import router from 'umi/router'
import { connect } from 'dva'
import { reap } from 'safe-reaper'
import { TENANT_TYPE } from '../helpers/CurrentUserContext'

const FORWARD = {
  '/book': ({ tenantType, children }) => {
    if (tenantType === TENANT_TYPE.ASSIGN) {
      router.push('/404')
      return null
    }
    return children
  },
  '/myBooking': ({ tenantType, children }) => {
    if (tenantType === TENANT_TYPE.ASSIGN) {
      router.push('/404')
      return null
    }
    return children
  }
}

export default connect(state => {
  const { tenantType } = reap(state, 'users.currentUser')
  return { tenantType }
})(props => {
  const { location, tenantType, children } = props
  const { pathname } = location
  const handle = FORWARD[pathname]
  if (handle) {
    return handle({ tenantType, children })
  }
  return children
})
