/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
export default {
  THIRD_ACCOUNT_IN: 'Third-party Account Integration',
  THIRD_USE_BOOK: 'Survices manual',
  // eslint-disable-next-line max-len
  THIRD_BINDED_TIPS:
    'The binding will synchronize the personnel list and organizational structure of the third-party platform and allow members to log into the system using a third-party account.',
  THIRD_HAND_ASYNC: 'Manual sync',
  THIRD_VIEW_CONFIG: 'View configuration',
  THIRD_BIND: 'Bind',
  THIRD_UNBIND: 'Unbind',
  THIRD_CONNECTED: 'Connected',
  THIRD_VIEW_TYPE: 'View {type} configuration',
  THIRD_CONFIRM_UNBIND_TYPE: 'Confirm to unbind【{type}】？',
  THIRD_UNBIND_TYPE: 'Unbind {type}',
  THIRD_BIND_TYPE: 'Bind {type}',
  THIRD_UNBIND_TIPS_TYPE:
    // eslint-disable-next-line max-len
    'Once unbundled the synced organization/members will be deleted, and the organization/members will no longer continue to be synced with Enterprise {type}, you cannot use Enterprise {type} to login to the system.',
  THIRD_ORGANIZATION_SYNC: '{number} members in current enterprise',
  THIRD_NEXT: 'Next',
  THIRD_SYNCING: 'Sync in progress',
  THIRD_DINGTALK: 'Dingtalk',
  THIRD_C_WECHAT: 'Wechat',
  THIRD_LDAP: 'LDAP',
  THIRD_LARK: 'Lark',
  THIRD_LOCAL: 'Local',
  THIRD_OTHERS: 'Others',
  THIRD_SYNC_LOADING: 'Sync in progress, please refresh the page after 30 seconds.',

  // dialog
  THIRD_CONFIGURE_COMPANY_INFO: 'Configuring Information',
  THIRD_INPUT_COMPANY_ID_TIPS: 'Enter Company ID(At the bottom of "My Company - Company Information".',
  THIRD_CREATE_APP_POSITION:
    'Create Self-built application（At the bottom of "APP Management"),enter AgentId and Secret)',
  THIRD_CLICK_STEP1: '1.Click "Create an app",setting the APP Logo、APP Name and About this App (Optional)；',
  THIRD_CLICK_STEP2:
    '2.Once created, the system will and will only synchronize the list of members and organizational structure visible in scope.',
  THIRD_CLICK_STEP3: '3. After creation, you can see AgentId and Secret in the application details page.',
  THIRD_CONFIGURE_MY_APP: 'Set Self-built application',
  THIRD_OPEN_PATH_TIPS: 'Open"Features-Workplace App Homepage"-"Set APP Homepage"',
  THIRD_APP_MAIN_PAGE: 'APP Homepage',
  THIRD_COPY: 'Copy',
  THIRD_APP_DETAIL_PATH_TIPS: 'Open"Developer API-Web Authorization and JS-SDK"-Set trusted domain name',
  THIRD_TRUST_DOMAIN: 'Trustable Domain Names',
  THIRD_WEB_REDIRECT_DOMAIN:
    'Open"Developer API-Log in to via authorization by WeCom"-"Setting",enter the callback domain of the Web page',
  THIRD_AUTH_REDIRECT_DOMAIN: 'Authorize the callback domain',
  THIRD_CONFIGURE_PEOPLE_SYNC: 'Configure the Sync of Contacts',
  THIRD_SYNC_MANAGE_STEP1: '1.Click the tab "Management Tool",and open "Sync of Contacts"',
  THIRD_SYNC_MANAGE_STEP2: '2.Click "Enable API sync',
  THIRD_SYNC_MANAGE_STEP3: '3.Configure the"Set event receiving server"',
  THIRD_CONFIRM_UNBIND: 'Confirm',
  THIRD_DEPARTMENT_ATTRIBUTE: 'Dept. Property',
  THIRD_UNBIND_SUCCESS: 'Unbind successfully',
  THIRD_BIND_SUCCESS: 'Bind successfully',

  // 飞书
  THIRD_CONFIGURE_APPLICATION_INFO: 'Configuring Application info.',
  THIRD_CREATE_LARK_POSITION:
    'Create Custom App(Located in the "My Applications" tab),fill in App ID,App Secret and App Name',
  THIRD_LARK_STEP_1: '1.Log in "FEISHU Developer Console",click "Create APP"',
  THIRD_LARK_STEP_2: '2.Choose the type of "Custom App",fill in the App Name,Custom App,and upload the icon.',
  THIRD_LARK_STEP_3:
    '3.After creation,find the app  and go to the app details page - "Credentials and Basic Info" and you will find the App ID and App Secret.',

  THIRD_LARK_NEXT_1:
    '1.Click on the application to enter the "Application Detail Page",  click on "Features - Web APP" in the sidebar, and fill in the address of the mobile and desktop home pages',
  THIRD_DESKTOP_MAIN_PAGE: 'Desktop Home Page',
  THIRD_MOBILE_MAIN_PAGE: 'Mobile Home Page',
  THIRD_LARK_NEXT_2: '2.Click on the "Security Settings" ，add the Redirect URLs and H5 Trusted Domain',
  THIRD_LARK_H5_DOMAIN: 'H5 Trusted Domain',
  THIRD_LARK_WEB_URL: 'Desktop Redirect URL',
  THIRD_LARK_H5_URL: 'Mobile Redirect URL',
  THIRD_LARK_REQUEST_URL: 'Request URL',
  THIRD_LARK_NEXT_3: '3.Click on the "Event Subscriptions",add the Encrypt Key,Verification Token and Request URL',
  THIRD_LARK_NEXT_4:
    "4.Click on 'Permissions',Select 'Obtain user email information', 'Obtain user's userid', 'Obtain user's ID via email or phone number', 'Access user's basic information', 'Access user department information from the contact' and 'Access department's organize information'",
  THIRD_LARK_NEXT_5:
    '5.Click on "Version Management & Release", click "Create a Version", fill in the relevant information, submit and wait for the enterprise administrator to review, and will be notified in the developer background after the review is approved.',

  // form
  TIP_HEADER_INPUT: 'Please enter ',
  CREATE_CONFIG: 'Create',
  CONFIRM_DISCONNECT_CONFIG: 'Confirm to disconnect the configuration?',
  CONFIRM_DELETE_CONFIG: 'Confirm to remove the configuration?',
  SELECT_VERSION: 'select the version',
  PLEASE_SELECT_VERSION: 'Please select the version',
  CONFIRM_DELETE_CALENDAR_CONFIG:
    // eslint-disable-next-line max-len
    'Removing this configuration will also turn off the 3rd-party synchronization function of all meeting rooms and delete all future meetings already synchronized.',
  RM_CALENDAR_CONFIG_TIP: 'Please type "{code}" to confirm',
  SELECT_THIRDPART: 'select third-part',
  PLEASE_SELECT_THIRDPART: 'Please select a 3rd-party',
  CONFIG_NAME: 'Account',
  PLEASE_INPUT_CONFIG_ACCOUNT: 'Please enter the name of account',
  PLEASE_INPUT_CONFIG_NAME: 'Please enter the name',
  EWS_LOCATION: 'EWS address',
  PLEASE_INPUT_EWS_LOCATION: 'Please enter EWS address of Exchange service',
  EXCHANGE_NAEM: 'Service account',
  PLEASE_INPUT_EXCHANGE_NAEM: 'Please enter the name of service NO',
  EXCHANGE_NAEM_ERROR: 'No special characters are contained in the name',
  EXCHANGE_PASSWORD: 'Service acc. pwd',
  PLEASE_INPUT_EXCHANGE_PASSWORD: 'Please enter the password of service NO',
  INTER_SERVICE_CONFIG: 'Server configuration',
  LDAP_FORM_TYPE: 'Contents name',
  PLEASE_SELECT_LDAP_FORM_TYPE: 'Please select the contents naming',
  LDAP_HOST_URL: 'Host',
  LDAP_HOST_URL_PLACEHOLDER: 'Name of server mainframe running LDAP, for example, ldap.example.com',
  PLEASE_INPUT_LDAP_HOST_URL: 'Please enter host',
  LDAP_PORT: 'Port',
  LDAP_PORT_ERROR: 'Only numbers allowed for Port NO',
  PLEASE_INPUT_LDAP_PORT: 'Please enter the port',
  LDAP_BASE_DN: 'Basic DN',
  LDAP_BASE_DN_PLACEHOLDER: 'Search the user and group from LDAP root node, for example, cn=users,dc=example,dc=com',
  PLEASE_INPUT_LDAP_BASE_DN: 'Please enter the basic domain',
  LDAP_USER_NAME: 'User name',
  LDAP_USER_NAME_PLACEHOLDER: 'User log onto LDAP, for example, user@domain.name or cn = user, dc = domain, dc = name',
  PLEASE_INPUT_LDAP_USER_NAME: 'please enter the username',
  LDAP_USER_PASSWORD: 'Password',
  PLEASE_INPUT_LDAP_USER_PASSWORD: 'Please enter password',
  LDAP_MODEL_SETTING: 'User mode setup',
  LDAP_OBJECT_CLASS_PLACEHOLDER:
    'Please enter the user object class. LDAP user object class that used when loading the user.',
  PLEASE_INPUT_LDAP_OBJECT_CLASS: 'Please enter user object class',
  LDAP_USER_ACCOUNT: 'User Login Property',
  LDAP_USER_ACCOUNT_PLACEHOLDER: 'Please enter the user login property. normally used value: sAMAccountName',
  PLEASE_INPUT_LDAP_USER_ACCOUNT: 'Please enter the user login property. ',
  LDAP_USER_EMAIL: 'User Email Property',
  LDAP_USER_EMAIL_PLACEHOLDER: 'Please enter the user e-mail. The domain is used for loading the user e-mail.',
  PLEASE_INPUT_LDAP_USER_EMAIL: 'Please enter the user e-mail property. ',
  PLEASE_INPUT_LDAP_DEPT_ATTRIBUTE: 'Please enter the dept. property.normally value: organizationalUnit',
  THIRD_LOGIN_FIRST: 'Third party login is preferred'
}
