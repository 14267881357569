export default {
  MAILBOX_COUNT: '共 {total} 个邮箱',
  MAILBOX_VALID_COUNT: '可发送邮箱 {total} 个',
  MAILBOX_VALID: '可发送',
  MAILBOX_INVALID: '不可发送',
  MAILBOX_COUNT_TIP: '提示：以下电子邮箱设置用于发送系统邮件，未设置将无法发送邮件',
  MAILBOX_ADD_EMAIL: '添加邮箱',
  MAILBOX_ADD_SYS_EMAIL: '添加系统邮箱',
  MAILBOX_EDIT_SYS_EMAIL: '编辑邮箱',
  MAILBOX_BATCH_ADD_EMAIL: '批量添加邮箱',
  MAILBOX_CFM: '确认',
  MAILBOX_EMAIL: '电子邮箱',
  MAILBOX_PASSWORD: '密码',
  MAILBOX_SERVER: '发送服务器',
  MAILBOX_PORT: '端口',
  MAILBOX_COVER_DEFAULT_PORT: '覆盖默认端口',
  MAILBOX_USE_SSL: '使用SSL连接（推荐）',
  MAILBOX_USE_STARTTLS: '使用STARTTLS加密',
  MAILBOX_NOT_EDITED: '尚未进行编辑',
  MAILBOX_NEC_ITEM_MISSING: '必填项尚未填写',
  MAILBOX_PLS_INPUT: '请输入',
  MAILBOX_PORT_EMPTY: '端口号不能为空',
  MAILBOX_PORT_INVALID: '端口号不正确',
  MAILBOX_EMAIL_EMPTY: '电子邮箱不能为空',
  MAILBOX_EMAIL_INVALID: '电子邮箱不正确',
  MAILBOX_PWD_EMPTY: '密码不能为空',
  MAILBOX_SERVER_EMPTY: '服务器不能为空',
  MAILBOX_DEL_TIP: '你确认要删除该系统邮箱吗？',
  MAILBOX_ADD_SUCCEED: '添加邮箱成功',
  MAILBOX_EDIT_SUCCEED: '编辑邮箱成功',
  MAILBOX_DEL_SUCCEED: '删除邮箱成功',
  MAILBOX_CLOSE: '关闭',
  MAILBOX_DNLD_FAILURE_FILE: '下载失败列表',
  MAILBOX_IMPORT_COMPLETED: '导入成功{successCount}，导入失败{failedCount}条',
  MAILBOX_IMPORT_FAILED: '导入失败，请重试',
  MAILBOX_DNLD_TEMPLATE: '下载模板',
  MAILBOX_UPLOAD_TIP: '请上传文件 (支持扩展名：xlsx、xls 只能单个上传)',
  MAILBOX_IMPORT: '批量导入邮箱',

  // error code
  MAILBOX_ERR_90001: '邮箱配置不正确',
  MAILBOX_ERR_90006: '邮箱地址或密码或服务器地址错误',
  MAILBOX_ERR_90007: '邮箱配置已存在',

  SYSTEM_MAIL: '设置系统邮件',
  COVER_DEFAULT_PORT: '覆盖默认端口',
  USE_SSL_CONNECT: '使用SSL连接（推荐）',
  USE_STARTTLS_ENCRYPT: '使用STARTTLS加密',
  E_MAIL: '电子邮箱',
  PLEASE_INPUT_CORRECT: '请输入合法的',
  PASSWORD: '密码',
  SEND_SERVER: '发送服务器',
  PORT: '端口'
}
