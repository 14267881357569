export default {
  GET_FAILED: 'Acquiring failed',
  EDIT_FAILED: 'Editing failed',
  ADD: 'Add',
  ADD_FAILED: 'Adding failed',
  DELETE_FAILED: 'Cannot delete',
  EDIT_SUCCESS: 'Edited Success',
  CUSTOMIZE: 'Custom',
  EDIT: 'Edit',
  MODIFY: 'Modify',
  DELETE_SUCCESS: 'Delete success',
  OPEN: 'On',
  CLOSE: 'Off',
  OPEN_ONLY: 'On',
  CLOSE_ONLY: 'Off',
  TO: ' to ',
  EVERY_DAY: 'Everyday',
  EVERY_WEEK: 'Weekly',
  EVERY_MONTH: 'Monthly',
  BEGIN_TIME: 'Start time',
  END_TIME: 'End time',
  MINUTE_SINGULAR: 'minute',
  MINUTE_PLURAL: 'minutes',
  MIN_PLURAL: 'min'
}
