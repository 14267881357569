/* eslint-disable */

export default {
  app: {
    LOG_OUT: 'Sign out',
    TOKEN_EXPIRED: 'Your authorization is incorrect or expired, please sign in again',
    NO_DATA: 'No Data'
  },
  errorCode: {
    'ERROR_CODE_-1': 'Exception not caught',
    ERROR_CODE_400: 'Input Error',
    ERROR_CODE_4101: 'Desking seat not exists'
  },
  errorInfo: {
    PAGE_NOT_FOUND_ERROR: 'The page you are openning is not exist, please verify URL',
    LOAD_ERROR: 'Loading error...',
    BACK_TO_HOME: 'Back home'
  },
  enums: {
    ALL: 'All',
    deskType: {
      GENERAL_MANAGER: '总经理室',
      VICE_GENERAL_MANAGER: '副总经理室',
      SUPERVISOR: '主管工位',
      STAFF: '员工工位',
      OTHER: '其他工位'
    },
    deskStatus: {
      PRIVATE: {
        UNASSIGNED: 'Unassigned',
        RESERVED: 'Preserved',
        ASSIGNED_TO_DEPARTMENT: 'Department assigned',
        ASSIGNED_TO_STAFF: 'Staff assigned',
        PENDING_ALLOCATION: 'Staff unassigned',
        SHARE_DESK: 'Hot Desk',
        NO_USED: 'Disable Desk',
        UNASSIGNED_EMPLOYEE: 'Unassigned',
        ASSIGNED_DEPARTMENT: 'Department assigned'
      }
    },
    assignment: {
      PRIVATE: 'Fixed Desk',
      SHARED: 'Hot Desk',
      FORBIDDEN: 'Disable Desk'
    },
    pieDefaultTitles: {
      TOTAL_DESK: 'All Desks',
      SHARED: 'Shared Count',
      PRIVATE: 'Fixed Desks',
      GENERAL_MANAGER: 'Generage Manager Count',
      SUPERVISOR: 'Supervisor Count',
      STAFF: 'Staff Count',
      OTHER: 'Other Count'
    }
  },
  map: {
    deskMap: {
      DEPARTMENT: 'Department',
      STAFF: 'Staff',
      UNASSIGNED_TO_PROMPT: 'Tips：部门与员工是从属关系，选择解除分配的部门则连同员工一并解除',
      DEPARTMENT_REQUIRED: 'Please select the assigned department',
      NO_MAP_SELECTED: `You haven't choose a map/floor`,
      LOADING_TXT: 'Map is loading',
      SUPPORT_TXT: 'Map visualization is not supported in your browser，Please use Chrome for better experience',
      DESK_NO: 'Desking No',
      SETTING_TYPE: 'Setting Type',
      WORKCARD_NO: 'Workcard No',
      ASSIGN: 'Assignment',
      DEPARTMENT_PALCEHOLDER: 'Please select the department',
      STAFF_PLACEHOLDER: 'Please select the staff',
      RESERVE: 'Reserve',
      UNRESERVE: 'Unreserve',
      CONFIRM_ASSIGN: 'Confirm Assign',
      CANCEL_ASSIGN: 'Cancel Assign',
      CONFIRM_UPDATE: 'Confirm Update',
      MODAL_CONFIRM_ASSIGN: 'Are you sure to assign this current desking seat?',
      MODAL_CANCEL_ASSIGN: 'Are you sure to cancel this assignment?',
      MODAL_UPDATE_ASSIGN: 'Are you sure to update the assigned fixed seat?',
      MODAL_RESERVE: 'Are you sure to reserve the current fixed seat?',
      MODAL_UNRESERVE: 'Are you sure to cancel the reserved fixed seat?',
      OK: 'Ok',
      THINK_AGAIN: 'Think Again'
    },
    PAGE_TITLE: 'Desk Map',
    BUILDING_FINDER_PLACEHOLDER: 'Please choose a building',
    FLOOR_FINDER_PLACEHOLDER: 'Please choose a floor',
    SEARCH_RAMGE_FINDER_PLACEHOLDER: 'Please choose a search text', //需要修改
    SEARCH_BTN: 'Search',
    RESET: 'Reset',
    dialogModel: {
      DIALOG_TITLE: '员工工位',
      DIALOGCONT_TITLE: '分配部门给:',
      DEPARTMENT_PLACEHOLDER: '选择所属部门',
      USERNAME_PLACEHOLDER: '输入员工姓名',
      USERTYPE_PLACEHOLDER: '选择员工类型',
      DEPARTMENT: '部门:',
      STAFF: '员工:',
      TYPE: '类型:',
      REG_DEPARTMENT: '部门不能为空',
      REG_USERNAME_SPACE: '员工姓名不能为空',
      REG_USERNAME_MAX: '*员工姓名过长',
      REG_USERTYPE: '类型不能为空',
      STAFF_TYPE_LIST: [
        { type: 0, staffName: '内部员工' },
        { type: 1, staffName: '外部实习' },
        { type: 2, staffName: '外部外包' },
        { type: 3, staffName: '外部_其他' }
      ]
    }
  },
  copyrights: {
    TEXT: 'Copyright © 2015-2018 xxx ® All Rights Reserved. ICP No. xxx  Email: support@xxx.com'
  },
  sidebar: {
    LIST: 'Desking List',
    MAP: 'Desking Map',
    STATISTICS: 'Analysis Statistics'
  },
  deskingList: {
    EDIT: 'Edit',
    SEARCH: 'Search',
    RESET: 'Reset',
    PRIVATE_SEAT: 'Private Seat',
    BATCH_IM: 'Batch Import',
    LIST_TOTAL: 'Total',
    DESK_UNIT: '',
    EDIT_DESKING: 'Edit Desking',
    DESKING_ID: 'Desking Id',
    LOCATION: 'Location',
    FLOOR: 'Floor',
    SETTING_TYPE: 'Setting Type',
    PROPERTY: 'Property',
    MODAL_TITLE: 'Batch Import Deskings',
    DESKID_REQUIRED: 'Desk NO is required',
    DESKID_TOO_LONG: 'Desk NO is too long, it should be less than 15 characters',
    DESKID_FORMAT_ERROR: 'Desk NO must be capitalized + numbers',
    DESK_TYPE_REQUIRED: 'Desk type is required',
    ASSIGNMENT_REQUIRED: 'Attribute is required',
    DESKID_PLACEHOLDER: 'Please input the desking Id',
    DESKTYPE_PLACEHOLDER: 'Please choose the desking type',
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel'
  },
  statistics: {
    RESET: 'Reset',
    SEARCH: 'Search',
    PAGE_TITLE: 'Statistics',
    DESK_SPACE_DISTRIBUTION_TITLE: 'Space assignment',
    DESK_ATTR_DISTRIBUTION_TITLE: 'Fixed Desk-Space assignment',
    DESK_TYPE_DISTRIBUTION_TITLE: 'Fixed Desk-desk label assignment',
    UNASSIGNED_DESK_TYPE_VACANT_TITLE: 'Vacant fixed desks on each floor',
    PRIVATE_DESK_DISTRIBUTION_TITLE: 'Assigned fixed desks on each department'
  },
  login: {
    PAGE_TITLE: 'Sign in',
    WELCOME_TITLE: 'Welcome',
    REMEMBER_ME_TXT: 'Remember me',
    SIGNIN_BTN_TXT: 'Sign in',
    ACCOUNT_EMPTY_ERROR: 'Email account cannot be empty',
    ACCOUNT_FORMAT_ERROR: 'Please enter the correct email format',
    ACCOUNT_LENGTH_ERROR: 'Email account cannot exceed 50 characters',
    ACCOUNT_PLACEHOLDER: 'Please enter your email account',
    PASSWORD_PLACEHOLDER: 'Please enter the password',
    PASSWORD_EMPTY_ERROR: 'Password cannot be empty',
    PASSWORD_FORMAT_ERROR: 'Please enter 6-16 characters without Spaces',
    ACCOUNT_PWD_ERROR: 'Account or Password is incorrect!',
    USERNAME_TXT: 'Username',
    USERPASSWORD_TXT: 'Password'
  }
}
