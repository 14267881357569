import * as dd from 'dingtalk-jsapi'
import { getThirdInfo, loginDingtalk } from './service'
import { redirectTo } from '../../../helpers/view'
import { querySystemInfo } from '../../../services/common'

export default function (type) {
  return new Promise(async (resolve, reject) => {
    const res = await querySystemInfo()
    if (res.errorCode !== 200) return
    const info = await getThirdInfo(res.data.id)
    const { success, data } = info
    switch (type) {
      case 'dingtalk':
        if (success) {
          const dingtalkInfo = data.find((_) => _.type === 0)
          if (!dingtalkInfo) {
            resolve({ success: false })
            return
          }
          // 2. 通过corpId 去拿到code
          dd.ready(async function () {
            const { code } = await getCodeByCorpId(dingtalkInfo.config.corpId)
            const params = {
              code,
              loginAppId: dingtalkInfo.config.loginAppId,
              corpId: dingtalkInfo.config.corpId,
              // 此处对应PC端钉钉工作台登录方式，但是和H5登录流程一样，用的是授权链接而非二维码扫描，所以state用h5
              state: 'dingtalk_h5',
            }
            loginDingtalk(params).then((res) => {
              redirectTo({
                pathname: '/login',
                state: {
                  is3rdParty: true,
                  res,
                },
              })
              resolve({ success: true })
            })
          })
        }
        break
      default:
        resolve({ success: false })
        break
    }
  })
}

function getCodeByCorpId(corpId) {
  return new Promise((resolve, reject) => {
    dd.runtime.permission.requestAuthCode({
      corpId,
      onSuccess: function (result) {
        resolve(result)
      },
      onFail: function (err) {
        reject(err)
      },
    })
  })
}
