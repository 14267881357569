import queryString from 'query-string'
import moment from 'moment'
import throttle from 'lodash/throttle'
import { getLocale } from 'umi-plugin-react/locale'
import { withMixin } from '../helpers/dva'
import { getLanguage, setLanguage } from '../helpers/storage'
import locale from '../locale'
import { getGroupTree } from '../services/common'

export default withMixin({
  namespace: 'app',
  state: {
    title: undefined,
    lang: getLanguage(),
    locale: locale[getLocale() === 'zh-CN' ? 'zh' : 'en'],
    locationPathname: '',
    locationQuery: {},
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    // 消息类参数
    messageInfo: null,
    isLoading: false,
    unReadList: null,
    readList: null,
    defaultKey: '0',
    sidebarCollapsed: false,
    buildingListData: {},
    book: null,
    fullScreen: false
  },
  subscriptions: {
    setHistory({ dispatch, history }) {
      return history.listen(location => {
        dispatch({
          type: 'updateState',
          payload: {
            locationPathname: location.pathname,
            locationQuery: queryString.parse(location.search)
          }
        })
      })
    },
    screenResize({ dispatch, history }) {
      const screenResizeHandler = () => {
        const { innerWidth, innerHeight } = window
        const data = {
          screenWidth: innerWidth,
          screenHeight: innerHeight
        }
        dispatch({
          type: 'updateState',
          payload: data
        })
        // 为啥用定时器 产品要求 页面不能一打开菜单就关闭，先让用户看到菜单 再隐藏
        setTimeout(() => {
          if (innerWidth <= 1680) {
            dispatch({
              type: 'updateState',
              payload: {
                sidebarCollapsed: true
              }
            })
          }
        }, 900)
      }
      const handler = throttle(screenResizeHandler, 250)
      handler()

      window.addEventListener('resize', handler)
      return () => {
        window.removeEventListener('resize', handler)
      }
    }
  },
  effects: {
    *loginOut({ payload }, { call, put }) {
      yield put({
        type: 'injected/loginOut'
      })
    },
    *getGroupTree({ payload }, { put, call, select }) {
      try {
        const info = yield call(getGroupTree)
        const { success, data } = info
        if (!success) {
          return false
        }
        return data
      } catch (err) {
        // message.error(err.message)
      }
    }
  },
  reducers: {
    switchLanguage(state, { payload }) {
      setLanguage(payload)
      moment.locale(payload === 'zh' ? 'zh-cn' : 'en')
      return {
        ...state,
        lang: payload,
        locale: locale[payload]
      }
    },
    toggleSidebar(state, { payload }) {
      if (payload) {
        return {
          ...state,
          sidebarCollapsed: payload
        }
      }
      return {
        ...state,
        sidebarCollapsed: payload || !state.sidebarCollapsed
      }
    }
  }
})
