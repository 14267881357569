import { BOOKINGAPI } from '../helpers/api'
import { routerParams } from '../helpers/time'
// export function getBuildingList(opts) {
//   return BOOKINGAPI.get(`/desk/building`, { params: opts })
// }
const basicGetFetch = ({ path, delayTime }) => {
  const _delay = delayTime || 360
  return new Promise((reslove, reject) => {
    const res = BOOKINGAPI.get(path)
    setTimeout(() => {
      reslove(res)
    }, _delay)
  })
}

export function loginOut(opts) {
  return BOOKINGAPI.get(`/login/out`)
}
export function getGroupTree(opts) {
  return BOOKINGAPI.get(`/groups/groupTree`, { params: opts })
}

export const querySystemInfo = () => {
  return BOOKINGAPI.get(`/tenant/domain`)
}

//设置页面工位时搜索员工列表
export function getDesksUserList(opts) {
  return BOOKINGAPI.get(`/desk/queryUserList`, { params: opts })
}

export const getDepartTree = params => {
  const _path = routerParams('groups/interfacer/tree', params)
  return basicGetFetch({
    path: _path
  })
}

export function clearAllocate(params) {
  return BOOKINGAPI.patch(`/desk/${params.id || 1}/clear`, params)
}

export function desksAllocate(params) {
  return BOOKINGAPI.patch(`/desk/${params.id || 1}/allocate`, params.dot)
}

export function checkAllocate(params) {
  return BOOKINGAPI.post(`/desk/${params.id || 1}/allocate/check`, params.dot)
}

//弹窗部分获取部门列表
export function getDepartmentList(opts) {
  return BOOKINGAPI.get(`/desk/departments`, { params: opts })
}
