export default {
  EXTERNAL: '外部人员/其他',
  OTHERS: '',
  BOOKED: '预订失败：该工位已被订',
  BOOKCHANGED: '预订失败：工位属性已变更',
  BOOKNOTFOUND: '预订失败：找不到该工位',
  BOOKSUCCESS: '预订成功',
  BOOKTWOSUCCESS: '预订成功，生成 2 条记录',
  BOOKADVANCE: '最多可预订未来##天内工位',
  BOOKNOTMORE: '该工位不可跨天预订',
  REFRESH: '管理员已修改预订规则，请刷新重试',
  PASSEDTIME: '开始时间已过去，请修改预订时间',
  FORME: '为我订',
  FOROTHERS: '代人订',
  TIME: '时间',
  DESKNAME: '工位名称',
  LOCATION: '地点',
  BOOKER: '预订人',
  USER: '使用者',
  MORNING: '上午',
  AFTERNOON: '下午',
  WHOLEDAY: '全天',
  BOOKAGAIN: '再订一次',
  CANCEL: '取消',
  LOCATE: '定位',
  END: '提前结束',
  UNSTARTED: '未开始',
  ONGOING: '进行中',
  ENDEDNORMAL: '正常结束',
  CANCELLED: '已取消',
  EARLYEND: '提前结束',
  MY_RESERVATION_WAITING: '等待中',
  MY_RESERVATION_RELEASED: '提前释放',
  CONFIRMCANCEL: '确定要取消预订吗？',
  CONFIRMEND: '确认要提前结束预订吗？',
  CONFIRM: '确认',
  DURATION: '持续时间',
  BOOKSTATION: '预订工位',
  TYPE: '类型',
  USERNAME: '姓名',
  DESK: '工位名称',
  BOOKINGSTATUS: '工位状态',
  INTERNALSTAFF: '内部员工',
  INNER: '同事',
  OUTER: '外部',
  EXTERNALTRAINEE: '外部人员/实习生',
  EXTERNALOUTSOURING: '外部员工/外包',
  EXTERNALOTHERS: '外部员工/其他',
  CROSSDAYS: '跨天预订',
  DESKLABEL: '工位标签',
  BOOK: '预订',
  BUILDINGNAME: '地点',
  FLOORNAME: '楼层',
  TIMEMESSAGE: '请选择时间',
  SHOWMOREDETAIL: '展开',
  MYBOOKING_DESK_NOT_BIND_MAP: '该工位未绑定地图'
}
