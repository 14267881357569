export default {
  ST_DESK_ON_FLOOR_DISTRIBUTION: '楼层工位分布',
  ST_DESK_ON_TAG_DISTRIBUTION: '工位标签分布',
  ST_BUILDING_OVERVIEW_DATA: '楼宇总览数据',
  ST_UN_ASSIGN_DESKING_SCALE: '待分配员工工位数比例',
  ST_DESK_STATUS_DISTRIBUTE: '固定工位状态分布',
  ST_TOTAL_BUILDINGS_DESK_COUNT: '当前楼宇工位总数（个）',
  ST_ASSIGNED_DESK_STAFF_COUNT: '已分配工位员工数（人）',
  ST_UN_ASSIGN_DESK_COUNT: '待分配员工工位数（个）',
  ST_TOTAL_DESK_COUNT: '总固定工位数',
  ST_ASSIGNED_DEPARTMENT: '已分配部门',
  ST_ASSIGNED_STAFF: '已分配员工',
  ST_UNASSIGN: '未分配',
  ST_UNASSIGNED_DESK_TOOLTIP_INFO1: '待分配员工工位数比例=（未分配工位数+已分配部门工位数）/ 固定工位 * 100%',
  ST_UNASSIGNED_DESK_TOOLTIP_INFO2: '待分配员工工位数=固定工位-已分配员工工位数',
  ST_TAG_DESK_COUNT_CALC_TIPS: '已标记工位数：计算的是标记该标签名称的工位数量',
  ST_TAG_FIXED_DESK_COUNT_CALC_TIPS: '已标记固定工位数：计算的是标记该标签名称的固定工位数量',
  ST_TAG_HOT_DESK_COUNT_CALC_TIPS: '已标记共享工位数：计算的是标记该标签名称的共享工位数量',
  ST_TAG_ASSIGN_STAFF_COUNT_CALC_TIPS: '已标记已分配员工工位数：计算的是标记该标签名称的已分配员工工位数量',
  ST_TAG_ASSIGN_DEPT_COUNT_CALC_TIPS: '已标记已分配部门工位数：计算的是标记该标签名称的已分配部门工位数量',
  ST_TAG_UNASSIGN_COUNT_CALC_TIPS: '已标记未分配工位数：计算的是标记该标签名称的未分配的工位数量',
  ST_TAG_TYPE_COUNT_CALC_THIS_TAG_TYPE_COUNT: '已标记{deskType}数：计算的是标记该标签名称的{deskType}数量',
  ST_EXPORT_IMAGE: '导出图片.png',
  ST_EXPORT_XLS: '导出表格.xls',
  ST_SYSTEM_DATA: '系统数据',
  ST_EXCEL_IMPORT: 'EXCEL导入',
  ST_IMPORT_EXCEL: '导入EXCEL',
  ST_SOURCE_SYSTEM_DATA: '数据源: 系统数据',
  ST_SOURCE_EXCEL_IMPORT: '数据源: EXCEL导入',
  ST_CONFIRM_CHECKOUT_BUILDING_SOURCE: '确定要切换该楼宇的数据源为【{name}】吗？',
  ST_WARNING_VIEW_PERMISSIONS: '切换数据源后，会立即生效，本公司有查看权限的成员也会看到数据源的最新数据',
  ST_UPLOAD_FILE_TIPS: '请上传文件（支持扩展名：XLS, XLSX 只能单个上传）',
  ST_DOWNLOAD_TEMPLATE: '下载模版',
  ST_PLEASE_SELECT_BUILDING_DATA: '请选择数据所属楼宇',
  ST_UPDATE_EXCEL_DATA_SOURCE: '更新EXCEL数据源',
  ST_FLOORS: '楼层({count})',
  ST_DESK_COUNT: '工位总数',
  ST_FIXED_DESK: '固定工位',
  ST_DEPT_COUNT: '部门总数',
  ST_DOWNLOAD_UPLOADED_DATA_MODIFY_THEN_UPLOAD: '下载已上传数据，修改后上传',
  ST_MUST_UPLOAD_EXCEL_OF_FORMAT: '必须上传指定格式的EXCEL文件',
  ST_MUST_SELECT_BUILDING: '必须选择一个楼宇',
  ST_IMPORT: '导入',
  ST_EXPORT: '导出',
  ST_BELONG_DEPT_DISTRIBUTION: '工位所属部门分布',
  ST_KEY_WORD_DEPT_NAME: '关键词：部门名称',
  ST_ALL_FLOORS: '全部楼层',
  ST_TB_DEPARTMENT: '部门',
  ST_TB_DESK_COUNT: '工位数(个)',
  ST_TB_DESK_COUNT_TIP: '工位数：计算的是分配给”本部门+所有子部门“的工位数量之和',
  ST_TB_STAFF_DESK: '工位数：已分配员工(个)',
  ST_TB_STAFF_DESK_TIP: '已分配员工工位数：已分配给员工的工位数量',
  ST_TB_DEPT_DESK: '工位数：已分配部门(个)',
  ST_TB_DEPT_DESK_TIP: '已分配部门工位数：已分配给部门但是未分配到员工的工位数量',
  ST_TB_RATIO: '分配比例条',
  ST_TB_RATIO_TIP: '该部门已分配员工工位数比例：已分配员工/工位数',
  ST_TB_MEMBER_COUNT: '成员数(人)',
  ST_TB_MEMBER_COUNT_TIP: '成员数：计算的是“本部门+所有子部门”内部成员人数之和',
  ST_TB_LOCATE_FLOOR: '所在楼层',
  ST_EXPORT_ALL_EXCELS: '导出全部表格',
  ST_EXPORT_ALL_IMAGES: '导出全部图片',
  ST_DATA_OVERVIEW_IMPORT_TEMPLATE: '数据概览-数据源-导入模版',
  ST_DATA_OVERVIEW_ALL_PICTURES: '数据概览全部图片',
  ST_DATA_OVERVIEW_ALL_TABLES: '数据概览全部表格',
  ST_DATE: '日期',
  ST_DATA_DUE_TIME: '截至到{time}的最新数据',
  ST_UPLOAD_ERROR: '文件已发生更改，请重新上传'
}
