/* eslint-disable prettier/prettier */
export default {
  B_STATISTICS_AVG_RATE: 'Average Booking rate',
  B_STATISTICS_AVG_RATE_TIPS: 'Average Booking rate = Booking time of Hot Desks/Opening time of Hot Desks',
  B_STATISTICS_PEAK_RATE: 'Peak Booking rate',
  B_STATISTICS_PEAK_RATE_TIPS: 'The maximum of the Average Booking rate per hour from 0-23 hours per day',
  B_STATISTICS_SINGLE_AVG_RATE: 'Average Booking time',
  B_STATISTICS_SINGLE_AVG_RATE_TIPS: 'Average Booking time for a single Desk Booking',

  B_STATISTICS_BOOK_COUNT_AND_DESK: 'Number of users and bookable Desks',
  B_STATISTICS_BOOK_COUNT_AND_DESK_TIPS:
    'Average number of users and bookable Desks at specific hour on multiple days during the selected date',
  B_STATISTICS_AVG_BOOK_DESK_COUNT: 'Average number of bookable Desks',
  B_STATISTICS_AVG_BOOK_COUNT: 'Average number of users',

  B_STATISTICS_DAY_RATE: 'Daily Booking rate',
  B_STATISTICS_DAY_RATE_TIPS: 'Daily Average Booking rate and Daily Peak Booking rate during the selected date',
  B_STATISTICS_AVG_LINE: 'Average Line',
  B_STATISTICS_PEAK_LINE: 'Peak Line',

  B_STATISTICS_HOURS_RATE: 'Hourly booking rate',
  B_STATISTICS_HOURS_RATE_TIPS: 'Average Booking rate at specific hour on multiple days during the selected date',

  B_STATISTICS_RATE_HEAT_MAP: 'Booking rate heat distribution',
  B_STATISTICS_RATE_HEAT_MAP_TIPS:
    'Booking rate of each Desk and its popularity on the floor during the selected date.',
  B_STATISTICS_MAP_FIX_DESK: 'Fixed Desk currently',
  B_STATISTICS_MAP_NO_LICENSE: 'Disable currently',
  B_STATISTICS_MAP_DISABLED: 'Unauthorized currently',

  B_STATISTICS_SINGLE_HOURS_RANGE: 'Single Booking length distribution',
  B_STATISTICS_SINGLE_HOURS_RANGE_TIPS:
    'Percentage of the duration of all booking records in each time interval during the selected date.',
  B_STATISTICS_HOUR: 'hour(s)',

  B_STATISTICS_SHARED_DESK_RANGE: 'Hot Desks Booking Charts',
  B_STATISTICS_SHARED_DESK_RANGE_TIPS:
    // eslint-disable-next-line max-len
    'Ranking of individual Desks during the selected date; when selecting positive order, the top five Desks with the highest Booking rate are displayed; when selecting Booking order, the top five Desks with the lowest Booking rate are displayed',
  B_STATISTICS_BOOKING_RATE: 'Booking rate',

  B_STATISTICS_BOOKING_OVERVIEW_EXCEL: 'All forms of Booking Overview',
  B_STATISTICS_BOOKING_OVERVIEW_IMAGE: 'All pictures of Booking Overview',

  B_STATISTICS_BUILDING: 'Building',

  B_STATISTICS_QUERY_TIPS: 'Please wait patiently while we try to find out...'
}
