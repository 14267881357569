export default {
  CUSTOMIZETAGS_MENU_ALL_TAGS: '全部标签',
  CUSTOMIZETAGS_MENU_TAGS: '标签群组',
  CUSTOMIZETAGS_STATION_LABEL_LIBRARY: '工位标签库',
  CUSTOMIZETAGS_INPUT_PLACEHOLDER: '搜索：工位标签名',
  CUSTOMIZETAGS_TOTAL: '共 {total} 条',
  CUSTOMIZETAGS_ADD: '+添加标签',
  CUSTOMIZETAGS_EDIT: '编辑',
  CUSTOMIZETAGS_SELECT_PLACEHOLDER: '搜索并添加标签',
  CUSTOMIZETAGS_SEARCH_NO_DATA: '无搜索结果',
  CUSTOMIZETAGS_MARKED_DESK_NUMBER: '已标记工位数',
  CUSTOMIZETAGS_PROPERTY: '属性',
  CUSTOMIZETAGS_PROPERTY_DEFAULT: '默认',
  CUSTOMIZETAGS_PROPERTY_ADD: '新增',
  CUSTOMIZETAGS_ACTION: '操作',
  CUSTOMIZETAGS_DELETE: '删除',
  CUSTOMIZETAGS_REMOVE: '移除',
  CUSTOMIZE_TAGS_RECOMMOND: '推荐：升降桌、电话间、沙发座、吧台、小圆桌、办公桌、办公室...',
  CUSTOMIZETAGS_TAG_NAME_ZH: '标签名（中）',
  CUSTOMIZETAGS_TAG_NAME_EN: '标签名（英）',
  CUSTOMIZETAGS_ADD_TAG: '添加工位标签',
  CUSTOMIZETAGS_EDIT_TAG: '编辑工位标签',
  CUSTOMIZETAGS_TAG_NAME_PLACEHOLDER: '请输入标签名',
  CUSTOMIZETAGS_TAG_NAME_CANNOT_BE_NULL: '名称不能为空',
  CUSTOMIZETAGS_ZH_TAG_GROUP_NAME: '标签群组名（中）',
  CUSTOMIZETAGS_EN_TAG_GROUP_NAME: '标签群组名（英）',
  CUSTOMIZETAGS_ADD_TAG_GROUP: '添加标签群组',
  CUSTOMIZETAGS_EDIT_TAG_GROUP: '编辑标签群组',
  CUSTOMIZETAGS_TAG_GROUP_NAME_PLACEHOLDER: '请输入标签群组名',
  CUSTOMIZETAGS_MAX_LENGTH: '最大字符长度为{max}',
  CUSTOMIZETAGS_MIN_LENGTH: '最小字符长度为{min}',
  CUSTOMIZETAGS_TAGS_COUNT: '{total} 条',
  CUSTOMIZETAGS_CONFIRM: '确定',
  CUSTOMIZETAGS_CANCEL: '取消',
  CUSTOMIZETAGS_TIP: '提示',
  CUSTOMIZETAGS_DELETE_TAG: '删除工位标签',
  CUSTOMIZETAGS_CONFIRM_DELETE_TAG_GROUP: '确定删除标签群组{text}吗？',
  CUSTOMIZETAGS_CONFIRM_DELETE_TAG: '确定要删除工位标签{text}吗？',
  CUSTOMIZETAGS_AFTER_DELETE_DESCRIPTION: '删除后，该标签在已标记的{total}个工位中被移除',
  CUSTOMIZETAGS_AFTER_DELETE_DESCRIPTION_FOR_SINGLE: '删除后，该标签在已标记的{total}个工位中被移除',
  CUSTOMIZETAGS_CONFIRM_REMOVE_TAG: '确定移除标签{text}吗?',
  CUSTOMIZETAGS_TEXT_WITH_BRACKETS: '【{text}】',
  CUSTOMIZETAGS_EMPTY_TAG: '（空白）'
}
