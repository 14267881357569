export default {
  CUSTOMIZETAGS_MENU_ALL_TAGS: 'All Tags',
  CUSTOMIZETAGS_MENU_TAGS: 'Tag group',
  CUSTOMIZETAGS_STATION_LABEL_LIBRARY: 'Desk Tags',
  CUSTOMIZETAGS_INPUT_PLACEHOLDER: 'Search: Desk Tag Name',
  CUSTOMIZETAGS_TOTAL: 'total {total} strip',
  CUSTOMIZETAGS_ADD: '+Add Tag',
  CUSTOMIZETAGS_EDIT: 'Edit',
  CUSTOMIZETAGS_SELECT_PLACEHOLDER: 'Search and add tags',
  CUSTOMIZETAGS_SEARCH_NO_DATA: 'No results',
  CUSTOMIZETAGS_MARKED_DESK_NUMBER: 'Marked Amounts',
  CUSTOMIZETAGS_PROPERTY: 'Type',
  CUSTOMIZETAGS_PROPERTY_DEFAULT: 'default',
  CUSTOMIZETAGS_PROPERTY_ADD: 'Add',
  CUSTOMIZETAGS_ACTION: 'Operation',
  CUSTOMIZETAGS_DELETE: 'Delete',
  CUSTOMIZETAGS_REMOVE: 'Remove',
  CUSTOMIZE_TAGS_RECOMMOND:
    'Recommended: lifting table, telephone room, sofa seat, bar counter, small round table, desk, office ...',
  CUSTOMIZETAGS_TAG_NAME_ZH: 'TagName (中)',
  CUSTOMIZETAGS_TAG_NAME_EN: 'TagName (En)',
  CUSTOMIZETAGS_ADD_TAG: 'Add Desk Tag',
  CUSTOMIZETAGS_EDIT_TAG: 'Edit Desk Tag',
  CUSTOMIZETAGS_TAG_NAME_PLACEHOLDER: 'Please enter the tag name',
  CUSTOMIZETAGS_TAG_NAME_CANNOT_BE_NULL: 'Name cannot be an empty string',
  CUSTOMIZETAGS_ZH_TAG_GROUP_NAME: 'Taggroup (中)',
  CUSTOMIZETAGS_EN_TAG_GROUP_NAME: 'Taggroup (En)',
  CUSTOMIZETAGS_ADD_TAG_GROUP: 'Add Tag Group',
  CUSTOMIZETAGS_EDIT_TAG_GROUP: 'Edit Tag Group',
  CUSTOMIZETAGS_TAG_GROUP_NAME_PLACEHOLDER: 'Please enter a tag group name',
  CUSTOMIZETAGS_MAX_LENGTH: 'At most {max} characters',
  CUSTOMIZETAGS_MIN_LENGTH: 'At least {min} characters',
  CUSTOMIZETAGS_TAGS_COUNT: '{total}',
  CUSTOMIZETAGS_CONFIRM: 'Confirm',
  CUSTOMIZETAGS_CANCEL: 'Cancel',
  CUSTOMIZETAGS_TIP: 'Tips',
  CUSTOMIZETAGS_DELETE_TAG: 'Delete Desk Tag',
  CUSTOMIZETAGS_CONFIRM_DELETE_TAG_GROUP: 'Confirm to delete the tag group {text}?',
  CUSTOMIZETAGS_CONFIRM_DELETE_TAG: 'Confirm to delete the desk tag {text}?',
  CUSTOMIZETAGS_AFTER_DELETE_DESCRIPTION: 'The tag will be removed from {total} desks that have been marked.',
  CUSTOMIZETAGS_AFTER_DELETE_DESCRIPTION_FOR_SINGLE: 'The tag will be removed from {total} desk that have been marked.',
  CUSTOMIZETAGS_CONFIRM_REMOVE_TAG: 'Confirm to remove the desk tag {text}?',
  CUSTOMIZETAGS_TEXT_WITH_BRACKETS: '[{text}]',
  CUSTOMIZETAGS_EMPTY_TAG: '(Blank)'
}
