export default {
  FORMSELECTFLOOR: '选择楼层',
  FORM_START_STATUS: '开始状态',
  FORM_ORDER_STATUS: '订单状态',
  FORMENDSTATUS: '结束状态',
  FORMBOOKINGTYPE: '预订类型',
  FORMUSERTYPE: '使用人类型',
  FORM_USER_NAME: '使用人',
  FORMDESKNAME: '工位名称',
  FORMRESERVATIONTIME: '预订时间',
  FORMUSERDURATION: '使用时长',
  FORMBOOKFROMOTHER: '代人订',
  FORMBOOKFROMOWN: '自己订',
  FORMCANCEL: '已取消',
  FORM_RELEASED: '提前释放',
  FORMENDEARLY: '提前结束',
  FORMENDNORMALLY: '正常结束',
  FORMEBENGINORMALLY: '正常开始',
  FORM_LATE: '迟到',
  FORM_ABSENT: '失约',
  FORMRESERVATIONDETAIL: '预订记录详情',
  FORMRLIST: '预订记录',
  FORM_WAY: '预订方式',
  FORMBAISEINFO: '基本信息',
  FORMDESK: '工位',
  FORMADDRESS: '地点',
  FORMTAG: '标签',
  FORMBOOKER: '预订人',
  FORMBOOKER_DEPT: '预订人部门',
  FORMUSER: '使用人',
  FORMUSER_DEPT: '使用人部门',
  FORMRESERVATIONINFO: '预订信息',
  FORMRESERVATIONDURATION: '预订时长',
  FORMSTARTSTATUS: '开始状态',
  FORMHOUR: '小时',
  FORMINTERNALUSER: '内部使用人',
  FORMEXTERNALUSER: '外部使用人',
  FORMDESKPLACEHOLD: '搜索：工位名称、预订人、使用人',
  FORMDOWNLIST: '导出',
  FORM_HAS_DELETE: '已删除',
  FORM_ENDED: '已结束',
  FORM_NOT_ENDED: '未结束',
  FORM_CLOSE_OPERATOR: '关闭操作人',
  FORM_CONFIRM_END_ORDER: '确认要结束当前订单吗？',
  FORM_END_ORDER_TIPS: '一旦结束订单无法撤回，请谨慎操作',
  FORM_SIGN_IN_TIME: '签到时间',
  FORM_SIGN_OUT_TIME: '签离时间'
}
