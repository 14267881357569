import { message } from 'antd'
import { withMixin } from '../../../helpers/dva'
import { getUser } from './service'
import { dealWithMenus } from '../../../helpers/text'
import logoURL from '../../../assets/oppoLogo.png'
import moment from 'moment'
import md5 from '../../../helpers/md5'
import Package from '../../../../package.json'

import { clearAll, getAuthToken, setAuthToken } from '../../../helpers/storage'

import { STORAGE_DOMAIN } from '../../../config'

export default withMixin({
  state: {
    logoURL
  },

  effects: {
    *autoLogin({ payload }, { put, call, select }) {
      const token = getAuthToken()
      const ssoRedirectUrl = getUrl()
      if (!token) {
        // OPPO sso 流程
        window.location.href = ssoRedirectUrl
      } else {
        try {
          const info = yield call(getUser)
          const { success, data, errorCode } = info

          if (!success || errorCode !== 200 || !data) {
            yield put({
              type: 'users/updateState',
              payload: {
                currentUser: null
              }
            })
            clearAll()
            // window.location.href = ssoRedirectUrl
            return
          }

          setAuthToken(data.token)
          yield put({
            type: 'users/updateState',
            payload: {
              currentUser: dealWithMenus(data)
            }
          })

          yield put({
            type: 'app/isFirstLogin',
            payload: {
              version: Package.version
            }
          })

          //需要修改密码
          // if (!data.isChangePassword) {
          //   setCanChangepws(data.isChangePassword)
          //   if (currentUrl === '/') {
          //     return redirectTo('/')
          //   }
          //   return redirectTo('/changePassword', currentUrl)
          // }
        } catch (err) {
          return err.message && message.error(err.message)
        }
      }
    },
    loginOut({ payload }, { call, put }) {
      // const { success } = yield call(loginOut, payload)
      clearAll()
      const ssoRedirectUrl = getLogOutUrl()
      window.location.href = ssoRedirectUrl

      // if (!success) {
      //   return false
      // }
    }
  },
  reducers: {}
})

function getUrl(config = {}) {
  // 测试环境
  const {
    appid = 'oppo-it-stams',
    secret = '628b9cdef46e4e90bdc3b7629a9da0ba',
    basicUrl = 'https://t-ssov2.myoas.com/sso/user/authenticate'
  } = config
  // 生产环境
  // const {
  //   appid = 'oppo-it-stams',
  //   secret = 'fea9d6077cc8428ba6199fac0e863e7c',
  //   basicUrl = 'https://ssov2.myoas.com/sso/user/authenticate'
  // } = config
  const domain = STORAGE_DOMAIN === 'localhost' ? 'localhost:8000' : STORAGE_DOMAIN
  const url = window.location.href
  const isHttps = url.startsWith('https')
  const from_url = `${isHttps ? 'https://' : 'http://'}${domain}/sso-callback`
  const time = moment().valueOf()
  const api_path = '/sso/user/authenticate'
  const param_name_value_pairs = `appid=${appid}\ntime=${time}`
  const str = `${api_path}\n${param_name_value_pairs}\n${secret}`
  const sign = md5(str)

  return `${basicUrl}?appid=${appid}&sign=${sign}&time=${time}&from_url=${encodeURI(from_url)}`
}

/**
 * 重定向到oppo注销页面
 */
function getLogOutUrl() {
  // 测试环境地址
  const basicUrl = 'https://t-ssov2.myoas.com/sso/user/logout'
  // // 生产环境地址
  // const basicUrl = 'https://ssov2.myoas.com/sso/user/logout'
  const domain = STORAGE_DOMAIN === 'localhost' ? 'localhost:8000' : STORAGE_DOMAIN
  const url = window.location.href
  const isHttps = url.startsWith('https')
  const from_url = `${isHttps ? 'https://' : 'http://'}${domain}/sso-callback`
  return `${basicUrl}?from_url=${from_url}`
}
