import { message, Modal } from 'antd'
import { debounce } from 'lodash'
import { formatMessage } from 'umi-plugin-react/locale'
import { removeAuthToken } from '../../../helpers/storage'
import { STORAGE_DOMAIN } from '../../../config'
export const oppoTokenExp = debounce(
  params => {
    const { response, showMessageControl } = params

    if (showMessageControl) {
      response.data.message && message.error(response.data.message)
    }
    removeAuthToken()
    if (response.data.errorCode === 9996 || response.data.errorCode === 9997) {
      // sso用户被禁用需在injected/oppo.js特殊处理
      oppoCallback({ response })
      return
    }
    const ssoRedirectUrl = getLogOutUrl()
    window.location.href = ssoRedirectUrl
  },
  800,
  { leading: true, trailing: false }
)

/**
 * 重定向到oppo注销页面
 */
function getLogOutUrl() {
  // 测试环境地址
  const basicUrl = 'https://t-ssov2.myoas.com/sso/user/logout'
  // // 生产环境地址
  // const basicUrl = 'https://ssov2.myoas.com/sso/user/logout'
  const domain = STORAGE_DOMAIN === 'localhost' ? 'localhost:8000' : STORAGE_DOMAIN
  const url = window.location.href
  const isHttps = url.startsWith('https')
  const from_url = `${isHttps ? 'https://' : 'http://'}${domain}/sso-callback`
  return `${basicUrl}?from_url=${from_url}`
}

function oppoCallback({ response }) {
  // 登录失败，处理
  Modal.error({
    content: response.data.message,
    maskClosable: false,
    okText: formatMessage({ id: 'CONFIRM_BUTTON' }),
    onOk: () => {
      const ssoRedirectUrl = getLogOutUrl()
      window.location.href = ssoRedirectUrl
    }
  })
}
