import common from './common/zh-CN'
import desk from './desking/zh-CN'
import deskrule from './deskrule/zh-CN'
import login from './login/zh-CN'
import manage from './manage/zh-CN'
import myBooking from './myBooking/zh-CN'
import organizational from './organizational/zh-CN'
import permissions from './permissions/zh-CN'
import log from './log/zh-CN'
import pwd from './pwd/zh-CH'
import customizetags from './customizetags/zh-CN'
import globalConfig from './global-config/zh-CN'
import bookForm from './bookForm/zh-CN'
import book from './book/zh-CN'
import statistics from './statistics/zh-CN'
import qrCode from './qrCode/zh-CN'
import companySetting from './company-setting/zh-CN'
import aboutUs from './about-us/zh-CN'
import preferenceSetting from './preference-setting/zh-CN'
import map from './map/zh-CN'
import customPassword from './custom-password/zh-CN'
import emailSetting from './email-setting/zh-CN'
import license from './license/zh-CN'
import thirdPart from './third-part/zh-CN'
import userPage from './user-page/zh-CN'
import bookStatistics from './book-statistics/zh-CN'
import userApprove from './user-approve/zh-CN'

export default {
  ...common,
  ...book,
  ...bookForm,
  ...globalConfig,
  ...customizetags,
  ...pwd,
  ...log,
  ...login,
  ...desk,
  ...permissions,
  ...organizational,
  ...myBooking,
  ...manage,
  ...deskrule,
  ...statistics,
  ...qrCode,
  ...companySetting,
  ...aboutUs,
  ...preferenceSetting,
  ...map,
  ...customPassword,
  ...emailSetting,
  ...license,
  ...thirdPart,
  ...userPage,
  ...bookStatistics,
  ...userApprove,
  // global
  MENU_MY_BOOKING: '我的预订',
  APP_LOG_OUT: '退出登录',
  FEED_BACK: '我要反馈',
  CONFIRM_BUTTON: '确定',
  DELETE_BUTTON: '删除',
  CANCEL_BUTTON: '取消',
  CONTINUE_BUTTON: '继续',
  EDIT_BUTTON: '编辑',
  VIEW_BUTTON: '查看',
  FUNCTION: '功能',
  MORE: '更多',
  MENU: '菜单',
  TIPS: '提示',
  RESET_BUTTON: '重置',
  CLEAR_BUTTON: '清除',
  CLEAR_REMOVE_BUTTON: '清除',
  OPERATION: '操作',
  ENTER: '进入',
  SEARCH: '搜索',
  TYPE: '类型',
  DESK_TYPES: '类型',
  AMOUNTS: '数量',
  TOTAL: '数量',
  SELECT_BUTTON: '选择',
  MAX_CHARACTERS: '最大输入50个字符{num}',
  TOTAL_TRIPS: '共 {total} 条记录',
  MEMBER_NAME: '成员',
  ROLE_NAME_TABLE: '角色', //英文为Role
  ROLE_NAME_TITLE: '角色', //英文为Roles
  ROLE_NAME_SEARCH: '成员',
  DEPARTMENT_TITLE: '部门', //英文为departments
  DEPARTMENT: '部门', //英文为department
  DEPARTMENT_SORT: '部门', //英文为department
  NAME: '姓名',
  PASSWORD: '密码',
  ADD_SUCCESS: '添加成功',
  SAVE_SUCCESS: '保存成功',
  NO_DATA: '暂无数据',
  NO_SEARCH_DATA: '暂无搜索结果',
  //DESK
  DESK: '工位',
  STAFF: '员工',
  UNASSIGNED: '未分配',
  RESERVED: '已预留',
  ASSIGNED_STAFF: '已分配员工',
  PENDING_ALLOCATION: '待分配员工',
  SHARE_DESK: '共享工位',
  FIXED_DESK: '固定工位',
  NO_USED: '未启用',
  USED: '启用',
  NO_USED_DESK: '未启用工位',
  NO_DEPARTMENT: '无部门',
  UNASSIGNED_EMPLOYEE: '未分配员工',
  ASSIGNED_DEPARTMENT: '已分配部门',
  ASSIGNED_DEPARTMENTS: '已分配部门', //大写
  DESKING_DISABLED_DESK: '禁用工位',
  DESK_NAME: '工位名称',
  DESK_AUTHORITY: '工位状态',
  DESK_TAG: '工位标签',
  DESK_TYPE: '工位属性',
  DESK_AREA: '人均工位面积',
  AVAILABLE_TO_ALL: '全员共享',
  AVAILABLE_TO_PART: '部分共享',
  CLOSED_BOOKING: '不开放预订',
  SECRECY: '保密',
  DISABLE: '禁用',
  DESK_INFORMATION: '工位详情',
  DESK_ID: '工位编号',
  STAFF_TYPE_ONE: '内部员工',
  STAFF_TYPE_TWO: '外部实习',
  STAFF_TYPE_THR: '外部外包',
  STAFF_TYPE_FOU: '外部其他',
  AVAILABLR_STATUS: '可预订',
  UNAVAILABLE_STATUS: '不可预订',
  BOOKING: '预订',
  TIME: '时间',
  CHECK_ALL: '全部{location}和楼宇及楼层',
  ALL_BUILDING_AND_FLOOR: '全部楼宇楼层',
  DATA_AUTH: '数据权限',
  NO_ASSESS: '无权限',
  TRUE: '是',
  FALSE: '否',
  LAST_STEP: '上一步',
  DESKASSIGNATION: '工位分配',
  INTERFACE_DESKASSIGNATION: '部门工位分配',
  DOWNLOAD: '下载',
  QRCODE: '二维码',
  DELETED: '(删除)',
  TODAY: '今天',
  YESTERDAY: '昨天',
  LAST_ONE_WEEK: '最近1周',
  LAST_ONE_MONTH: '最近1月',
  LAST_THREE_MONTH: '最近3月',
  LAST_ONE_YEAR: '最近1年',
  H5: 'H5端',
  WEB: 'Web端',
  SELECT_ALL: '全选',
  EXCEPT_DATE: '除外日期',
  MONDAY: '星期一',
  TUESDAY: '星期二',
  WEDNESDAY: '星期三',
  THURSDAY: '星期四',
  FRIDAY: '星期五',
  SATURDAY: '星期六',
  SUNDAY: '星期日',
  QUERY: '查询',
  AVAILABLE: '可使用',
  STANBY: '等待中',
  OCCUPIED: '使用中',
  IMPROMPTU: '占用中',
  REFRESH: '刷新',
  SUM: '合计',
  CLICK_TO_LOGIN: '点击使用 EY APP 扫码登录',
}
