export default {
  OPE_MODULE: '操作模块',
  OPE_TYPE: '操作类型',
  ROLE_TYPE: '角色类型',
  BOOKING_BEGIN: '开始',
  BOOKING_END: '结束',
  OP_LOG: '操作日志',
  LOGIN_LOG: '登录日志',
  SEARCH_PLA: '搜索：操作人',
  LOG_DOWNLOAD: '导出',
  OPE: '操作人',
  OPE_CONTENT: '操作内容',
  IP: 'IP地址',
  OPE_TIME: '操作时间',
  OPRE: '操作',
  DEL: '删除',
  UPDATE: '修改',
  EXP_SUCCESS: '导出成功',
  EXP_FAIL: '导出失败',
  LOG_DETAIL: '操作日志详情',
  USER_INFO: '人员信息',
  WORK_ID: '工号',
  ROLE: '角色',
  OPE_INFO: '操作信息',
  DEATIL: '详情'
}
