/* eslint-disable */

export default {
  app: {
    LOG_OUT: '注销',
    TOKEN_EXPIRED: '您的登入信息不正确或者已过期，请重新登录',
    NO_DATA: '没有数据'
  },
  errorCode: {
    'ERROR_CODE_-1': '未捕获异常',
    ERROR_CODE_400: '输入错误',
    ERROR_CODE_4101: '工位不存在'
  },
  errorInfo: {
    PAGE_NOT_FOUND_ERROR: '您正在找的页面不存在，请确认链接是否正确',
    LOAD_ERROR: '加载错误...',
    BACK_TO_HOME: '回到首页'
  },
  enums: {
    ALL: '全部',
    deskType: {
      GENERAL_MANAGER: '总经理室',
      VICE_GENERAL_MANAGER: '副总经理室',
      SUPERVISOR: '主管工位',
      STAFF: '员工工位',
      OTHER: '其他工位'
    },
    deskStatus: {
      PRIVATE: {
        UNASSIGNED: '未分配',
        RESERVED: '已预留',
        ASSIGNED_TO_DEPARTMENT: '已分配部门',
        ASSIGNED_TO_STAFF: '已分配员工',
        PENDING_ALLOCATION: '待分配员工',
        // 待分配员工(PENDING_ALLOCATION) = 未分配员工(UNASSIGNED_EMPLOYEE) + 已分配部门(ASSIGNED_TO_DEPARTMENT)
        SHARE_DESK: '共享工位',
        NO_USED: '未启用',
        NO_DEPARTMENT: '无部门',
        UNASSIGNED_EMPLOYEE: '未分配',
        ASSIGNED_DEPARTMENT: '已分配部门'
      },
      SHARED: {
        USING: '使用中',
        BOOKABLE: '可预订',
        BOOKED: '已预定'
      }
    },
    assignment: {
      PRIVATE: '固定工位',
      SHARED: '共享工位',
      FORBIDDEN: '未启用'
    },
    pieDefaultTitles: {
      TOTAL_DESK: '工位总数',
      SHARED: '共享工位数',
      PRIVATE: '固定工位数',
      GENERAL_MANAGER: '总经理工位数',
      VICE_GENERAL_MANAGER: '副总经理工位数',
      SUPERVISOR: '主管工位数',
      STAFF: '员工工位数',
      OTHER: '其他工位数'
    }
  },
  map: {
    deskMap: {
      DEPARTMENT: '部门',
      STAFF: '员工',
      UNASSIGNED_TO_PROMPT: '温馨提示：部门与员工是从属关系，选择解除分配的部门则连同员工一并解除',
      DEPARTMENT_REQUIRED: '请选择分配的部门',
      NO_MAP_SELECTED: '您还没有选择地图/楼层',
      LOADING_TXT: '地图正在加载中',
      SUPPORT_TXT: '您使用的浏览器暂不支持地图，请升级或改用Chrome获取更好的服务',
      DESK_NO: '工位编号',
      SETTING_TYPE: '设置类型',
      WORKCARD_NO: '电子工牌编号',
      ASSIGN: '分配给',
      DEPARTMENT_PALCEHOLDER: '部门选择',
      STAFF_PLACEHOLDER: '员工选择',
      RESERVE: '预留',
      UNRESERVE: '取消预留',
      CONFIRM_ASSIGN: '确认分配',
      CANCEL_ASSIGN: '解除分配',
      CONFIRM_UPDATE: '确认修改',
      MODAL_CONFIRM_ASSIGN: '是否确定分配当前固工位?',
      MODAL_CANCEL_ASSIGN: '是否确定解除分配?',
      MODAL_UPDATE_ASSIGN: '是否确定修改分配的固定工位?',
      MODAL_RESERVE: '是否确定预留当前固定工位',
      MODAL_UNRESERVE: '是否确定取消预留的固定工位',
      OK: '确定',
      THINK_AGAIN: '再想想'
    },
    PAGE_TITLE: '工位地图',
    BUILDING_FINDER_PLACEHOLDER: '请选择楼房',
    FLOOR_FINDER_PLACEHOLDER: '请选择楼层',
    SEARCH_RAMGE_FINDER_PLACEHOLDER: '搜索工位编号或员工姓名',
    SEARCH_BTN: '查询',
    RESET: '重置',
    dialogModel: {
      DIALOG_TITLE: '员工工位',
      DIALOGCONT_TITLE: '分配部门给:',
      DEPARTMENT_PLACEHOLDER: '选择所属部门',
      USERNAME_PLACEHOLDER: '输入员工姓名',
      USERTYPE_PLACEHOLDER: '选择员工类型',
      DEPARTMENT: '部门:',
      STAFF: '员工:',
      TYPE: '类型:',
      REG_DEPARTMENT: '部门不能为空',
      REG_USERNAME_SPACE: '员工姓名不能为空',
      REG_USERNAME_MAX: '*员工姓名过长',
      REG_USERTYPE: '类型不能为空',
      STAFF_TYPE_LIST: [
        { type: 0, value: '内部员工', text: '内部员工' },
        { type: 1, value: '外部实习', text: '外部实习' },
        { type: 2, value: '外部外包', text: '外部外包' },
        { type: 3, value: '外部其他', text: '外部其他' }
      ]
    }
  },
  copyrights: {
    TEXT: 'Copyright © 2015-2018 xxx 版权所有. 沪ICP备xxx号 邮件支持support@xxx.com'
  },
  sidebar: {
    MAP: '工位分配',
    STATISTICS: '分析统计',
    INTERFACEMAP: '接口人工位分配',
    SPACEMANAGE: '空间管理',
    INTERFACEMANAGE: '接口人空间管理'
  },
  deskingList: {
    EDIT: '编辑',
    SEARCH: '查询',
    RESET: '重置',
    PRIVATE_SEAT: '私有工位',
    BATCH_IM: '批量导入工位',
    LIST_TOTAL: '共',
    DESK_UNIT: '个工位',
    EDIT_DESKING: '工位编辑',
    DESKING_ID: '工位编号',
    LOCATION: '地点',
    FLOOR: '楼层',
    SETTING_TYPE: '设置类型',
    PROPERTY: '属性',
    MODAL_TITLE: '批量导入工位',
    DESKID_REQUIRED: '工位编号为必填项',
    DESKID_TOO_LONG: '工位编号不能超过15位',
    DESKID_FORMAT_ERROR: '工位编号必须是大写字母 + 数字',
    DESK_TYPE_REQUIRED: '设置类型为必填项',
    ASSIGNMENT_REQUIRED: '属性为必填项',
    DESKID_PLACEHOLDER: '请输入工位编号ID',
    DESKTYPE_PLACEHOLDER: '请选择设置类型',
    CONFIRM: '确认',
    CANCEL: '取消'
  },
  statistics: {
    RESET: '重置',
    SEARCH: '查询',
    PAGE_TITLE: '分析统计',
    DESK_SPACE_DISTRIBUTION_TITLE: '工位空间分配情况',
    DESK_ATTR_DISTRIBUTION_TITLE: '固定工位-工位分配分布情况',
    DESK_TYPE_DISTRIBUTION_TITLE: '固定工位-工位标签分布情况',
    UNASSIGNED_DESK_TYPE_VACANT_TITLE: '各楼层固定工位空置情况',
    PRIVATE_DESK_DISTRIBUTION_TITLE: '各部门固定工位分布情况'
  },
  login: {
    PAGE_TITLE: '登录',
    WELCOME_TITLE: '欢迎登录',
    REMEMBER_ME_TXT: '记住我',
    SIGNIN_BTN_TXT: '立即登录',
    ACCOUNT_EMPTY_ERROR: '邮箱账号不能为空',
    ACCOUNT_FORMAT_ERROR: '邮箱格式错误',
    ACCOUNT_LENGTH_ERROR: '邮箱账号不能超过50个字符',
    ACCOUNT_PLACEHOLDER: '请输入邮箱账号',
    PASSWORD_PLACEHOLDER: '请输入密码',
    PASSWORD_EMPTY_ERROR: '密码不能为空',
    PASSWORD_FORMAT_ERROR: '请输入不包含空格的6-16字符',
    ACCOUNT_PWD_ERROR: '用户名或者密码错误！',
    USERNAME_TXT: '用户名',
    USERPASSWORD_TXT: '密码'
  }
}
