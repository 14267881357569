/* eslint-disable prettier/prettier */
export default {
  B_STATISTICS_AVG_RATE: '平均预订率',
  B_STATISTICS_AVG_RATE_TIPS: '平均预订率=共享工位被预订的时间/共享工位开放的时间',
  B_STATISTICS_PEAK_RATE: '峰值预订率',
  B_STATISTICS_PEAK_RATE_TIPS: '每天0-23时每小时的平均预订率的最大值',
  B_STATISTICS_SINGLE_AVG_RATE: '单次平均预订时长',
  B_STATISTICS_SINGLE_AVG_RATE_TIPS: '单次预订工位的平均预订时长',

  B_STATISTICS_BOOK_COUNT_AND_DESK: '使用人数与可预订工位数量',
  B_STATISTICS_BOOK_COUNT_AND_DESK_TIPS: '在所选日期内，多日每小时的平均使用人数和平均工位数量',
  B_STATISTICS_AVG_BOOK_DESK_COUNT: '平均可预订工位数量',
  B_STATISTICS_AVG_BOOK_COUNT: '平均使用人数',

  B_STATISTICS_HOURS_RATE: '每小时预订率情况',
  B_STATISTICS_HOURS_RATE_TIPS: '在所选日期内，多日每小时的平均预订率',

  B_STATISTICS_DAY_RATE: '每日预订率情况',
  B_STATISTICS_DAY_RATE_TIPS: '在所选日期内，每日的平均预订率和峰值预订率',
  B_STATISTICS_AVG_LINE: '平均值参考线',
  B_STATISTICS_PEAK_LINE: '峰值参考线',

  B_STATISTICS_RATE_HEAT_MAP: '预订率热力分布',
  B_STATISTICS_RATE_HEAT_MAP_TIPS: '在所选日期内。楼层中每个工位的预订率工位和它们的预订率热度',
  B_STATISTICS_MAP_FIX_DESK: '当前为固定工位',
  B_STATISTICS_MAP_NO_LICENSE: '当前未授权',
  B_STATISTICS_MAP_DISABLED: '当前禁用中',

  B_STATISTICS_SINGLE_HOURS_RANGE: '单次预订时长分布',
  B_STATISTICS_SINGLE_HOURS_RANGE_TIPS: '在所选日期内，所有预订记录的时长在各时间区间内的百分比',
  B_STATISTICS_HOUR: '小时',

  B_STATISTICS_SHARED_DESK_RANGE: '共享工位预订排行',
  B_STATISTICS_SHARED_DESK_RANGE_TIPS:
    '在所选日期内，单个工位预订率的排行；选择正序时，展示预订率最高的工位前五名；选择倒序时，展示预订率最低的工位前五名',
  B_STATISTICS_BOOKING_RATE: '预订率',

  B_STATISTICS_BOOKING_OVERVIEW_EXCEL: '预订概览全部表格',
  B_STATISTICS_BOOKING_OVERVIEW_IMAGE: '预订概览全部图片',

  B_STATISTICS_BUILDING: '楼宇',

  B_STATISTICS_QUERY_TIPS: '努力查询中，请耐心等待...'
}
