import { message } from 'antd'
import { removeAuthToken } from '../../../helpers/storage'
import { redirectTo } from '../../../helpers/view'

export function dfocusTokenExp(params) {
  removeAuthToken()
  const { response, currentUrl, showMessageControl } = params
  if (showMessageControl) {
    response.data.message && message.error(response.data.message)
    redirectTo('/login')
    return response
  }
  if (currentUrl === '/login') {
    return response
  } else {
    redirectTo('/login')
    return response
  }
}
