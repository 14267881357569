import { message } from 'antd'
import {
  getGroupTree,
  groupsSearchText,
  userSearchText,
  getDepartmentTable,
  getRoleList,
  getSelectInterface,
  addNewDepartment,
  editDepartment,
  deleteDepartment,
  serDepartmentColor,
  searchRole,
  addUser,
  resetPassword,
  startAccount,
  deleteCheckOne,
  deleteCheckMore,
  deleteRole,
  deleteRoleMore,
  downloadModel,
  downloadError,
  getUser,
  editUser,
  changeDepartment,
  getDepartmentDetail,
  fetchBuildingList,
  downLoadInfoModel,
  uploadIndo,
  downloadErrorInfo
} from '../pages/organizationalStructure/services'
import { withMixin } from '../helpers/dva'

export default withMixin({
  namespace: 'organizationalStructure',
  state: {
    isEditFatherDepartment: false,
    pathName: null,
    needUpdateTree: false,
    getGroupTree: null
  },
  subscriptions: {},
  effects: {
    *getGroupTree({ payload }, { put, call, select }) {
      try {
        const info = yield call(getGroupTree)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }

        if (errorCode === 200) {
          yield put({
            type: 'updateState',
            payload: {
              getGroupTree: data
            }
          })
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *groupsSearchText({ payload }, { put, call, select }) {
      try {
        const info = yield call(groupsSearchText, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *userSearchText({ payload }, { put, call, select }) {
      try {
        const info = yield call(userSearchText, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *getDepartmentTable({ payload }, { put, call, select }) {
      try {
        const info = yield call(getDepartmentTable, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *getRoleList({ payload }, { put, call, select }) {
      try {
        const info = yield call(getRoleList, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *getSelectInterface({ payload }, { put, call, select }) {
      try {
        const info = yield call(getSelectInterface, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *addNewDepartment({ payload }, { put, call, select }) {
      try {
        const info = yield call(addNewDepartment, payload)
        const { success, errorCode } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return errorCode
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *editDepartment({ payload }, { put, call, select }) {
      try {
        const info = yield call(editDepartment, payload)
        const { success, errorCode } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return errorCode
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *deleteDepartment({ payload }, { put, call, select }) {
      try {
        const info = yield call(deleteDepartment, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return { data, errorCode }
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *serDepartmentColor({ payload }, { put, call, select }) {
      try {
        const info = yield call(serDepartmentColor, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *searchRole({ payload }, { put, call, select }) {
      try {
        payload.size = 10000
        payload.page = 1
        const info = yield call(searchRole, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *addUser({ payload }, { put, call, select }) {
      try {
        const info = yield call(addUser, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return { data, errorCode }
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *resetPassword({ payload }, { put, call, select }) {
      try {
        const info = yield call(resetPassword, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return { data, errorCode }
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *startAccount({ payload }, { put, call, select }) {
      try {
        const info = yield call(startAccount, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *deleteCheckOne({ payload }, { put, call, select }) {
      try {
        const info = yield call(deleteCheckOne, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *deleteCheckMore({ payload }, { put, call, select }) {
      try {
        const info = yield call(deleteCheckMore, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *deleteRole({ payload }, { put, call, select }) {
      try {
        const info = yield call(deleteRole, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return { data, errorCode }
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *deleteRoleMore({ payload }, { put, call, select }) {
      try {
        const info = yield call(deleteRoleMore, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return { data, errorCode }
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *downloadModel({ payload }, { put, call, select }) {
      try {
        const info = yield call(downloadModel, payload)
        return info
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *downloadError({ payload }, { put, call, select }) {
      try {
        const info = yield call(downloadError, payload)
        return info
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *getUser({ payload }, { put, call, select }) {
      try {
        const info = yield call(getUser, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *editUser({ payload }, { put, call, select }) {
      try {
        const info = yield call(editUser, payload)
        const { success, errorCode } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return errorCode
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *changeDepartment({ payload }, { put, call, select }) {
      try {
        const info = yield call(changeDepartment, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    *getDepartmentDetail({ payload }, { put, call, select }) {
      try {
        const info = yield call(getDepartmentDetail, payload)
        const { success, errorCode, data } = info
        if (!success) {
          return false
        }
        if (errorCode === 200) {
          return data
        }
      } catch (err) {
        err.message && message.error(err.message)
        return false
      }
    },
    // 信息初始化弹窗获取楼宇列表
    *fetchBuildingList({ payload }, { call }) {
      try {
        const { success, data } = yield call(fetchBuildingList, payload)
        return { success, data: data || [] }
      } catch (err) {
        err.message && message.error(err.message)
        return { success: false }
      }
    },
    // 信息初始化弹窗，下载模板
    *downLoadInfoModel({ payload }, { call }) {
      try {
        const info = yield call(downLoadInfoModel, payload)
        return info
      } catch (err) {
        err.message && message.error(err.message)
        return null
      }
    },
    // 上传组织架构信息
    *uploadIndo({ payload }, { call }) {
      try {
        const { success, data, errorCode } = yield call(uploadIndo, payload)
        return { success, data, errorCode }
      } catch (err) {
        return { success: false, message: err.message }
      }
    },
    // 信息初始化弹窗，下载错误数据
    *downloadErrorInfo({ payload }, { call }) {
      try {
        const info = yield call(downloadErrorInfo, payload)
        return info
      } catch (err) {
        err.message && message.error(err.message)
        return null
      }
    }
  },
  reducers: {}
})
