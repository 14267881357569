import { isProduction } from '../helpers/env'

/**
 * 所有的 *_PLACEHOLDER__，在npm run serve启动时，都需要指定运行时需要的值
 * 这个在“Use docker for release”时非常好用，可以针对不同的运行环境使用同一个镜像，指定不同的环境变量
 */

export const OPEN_PAGES = [
  '/login',
  '/404',
  '/oacmbNoAuth',
  '/changePassword',
  '/sso-callback',
  '/server_config',
]

// 需要被校正的地图名单
export const TILTED_MAPS = ['cmb-shenzhen']

export const STORAGE_EXPIRE_DAYS = 49

export const VERSION = '1.14.0'

export const STORAGE_DOMAIN = !isProduction ? 'dftestv3.dfocus.co' : window.location.hostname
// export const STORAGE_DOMAIN = !isProduction ? 'testenv.desking.dfocus.tech' : window.location.hostname
export const MAP_URL = !isProduction ? 'https://maps.data.dfocus.tech/fengmaps' : 'MAP_URL_PLACEHOLDER__'
export const API_HOST = !isProduction ? 'http://dftestv3.dfocus.co/bookinganyong' : 'API_HOST_PLACEHOLDER__'
export const FEISHU_THIRD = !isProduction
  ? 'https://open.feishu.cn/open-apis/authen/v1/index'
  : 'FEISHU_THIRD_PLACEHOLDER__'

export const THIRD_HOST = !isProduction ? 'http://dftestv3.dfocus.co/anyongdep' : 'THIRD_HOST_PLACEHOLDER__'
export const THIRD_MANUAL = !isProduction ? 'testenv.desking.dfocus.tech' : 'THIRD_MANUAL_PLACEHOLDER__'
export const injectedModule = process.env.UMI_ENV || 'dfocus'
