export default {
  GET_FAILED: '获取失败',
  EDIT_FAILED: '编辑失败',
  ADD: '添加',
  ADD_FAILED: '添加失败',
  DELETE_FAILED: '删除失败',
  EDIT_SUCCESS: '编辑成功',
  CUSTOMIZE: '自定义',
  EDIT: '编辑',
  MODIFY: '修改',
  DELETE_SUCCESS: '删除成功',
  OPEN: '开启',
  CLOSE: '关闭',
  OPEN_ONLY: '开',
  CLOSE_ONLY: '关',
  TO: '至',
  EVERY_DAY: '每天',
  EVERY_WEEK: '每周',
  EVERY_MONTH: '每月',
  BEGIN_TIME: '开始时间',
  END_TIME: '结束时间',
  MINUTE_SINGULAR: '分钟',
  MINUTE_PLURAL: '分钟',
  MIN_PLURAL: '分钟'
}
