import { BOOKINGAPI } from '../../../helpers/api'

export function getGroupTree(opts) {
  return BOOKINGAPI.get(`/groups/groupTree`, { params: opts })
}
export function groupsSearchText(opts) {
  return BOOKINGAPI.get(`/groups/searchText`, { params: opts })
}
export function userSearchText(opts) {
  return BOOKINGAPI.get(`/user/searchText`, { params: opts })
}
export function getDepartmentTable(opts) {
  return BOOKINGAPI.get(`/groups`, { params: opts })
}
export function getRoleList(opts) {
  return BOOKINGAPI.get(`/user`, { params: opts })
}
export function getSelectInterface(opts) {
  return BOOKINGAPI.get(`/groups/interfacer`, { params: opts })
}
export function addNewDepartment(opts) {
  return BOOKINGAPI.post(`/groups`, opts)
}
export function editDepartment(opts) {
  return BOOKINGAPI.put(`/groups/${opts.id}`, opts.dto)
}
export function deleteDepartment(opts) {
  return BOOKINGAPI.delete(`/groups`, { data: opts.data })
}
export function serDepartmentColor(opts) {
  return BOOKINGAPI.post(`/groups/setGroupColor`, opts)
}
export function searchRole(opts) {
  return BOOKINGAPI.get(`/role`, { params: opts })
}
export function addUser(opts) {
  return BOOKINGAPI.post(`/user`, opts)
}
export function resetPassword(opts) {
  return BOOKINGAPI.post(`/user/reset/password/`, opts)
}
export function startAccount(opts) {
  return BOOKINGAPI.put(`/user/alter/status/?userId=${opts.userId}&status=${opts.status}`)
}
export function deleteCheckOne(opts) {
  return BOOKINGAPI.get(`/user/delete/check/${opts.id}`)
}
export function deleteCheckMore(opts) {
  return BOOKINGAPI.post(`/user/delete/check/`, opts.idx)
}
export function deleteRole(opts) {
  return BOOKINGAPI.delete(`/user/${opts.id}`)
}
export function getDepartmentDetail(opts) {
  return BOOKINGAPI.get(`/groups/${opts.id}`)
}
export function deleteRoleMore(opts) {
  return BOOKINGAPI.delete(`/user`, { data: opts.data })
}
export function downloadModel(opts) {
  return BOOKINGAPI.get(`/user/template/download`, { responseType: 'blob' })
}
export function downloadError(opts) {
  return BOOKINGAPI.get(`/user/download`, { responseType: 'blob' })
}
export function getUser(opts) {
  return BOOKINGAPI.get(`/user/${opts.id}`)
}
export function editUser(opts) {
  return BOOKINGAPI.put(`/user/${opts.id}`, opts.dto)
}
export function changeDepartment(opts) {
  return BOOKINGAPI.post(`/user/allocate/group`, opts.dto)
}

/**
 * 信息初始化弹窗获取楼宇列表
 * @param {object} opts
 * @param {number} opts.pageType
 */
export function fetchBuildingList(opts = { pageType: 0 }) {
  return BOOKINGAPI.get('desk/buildingSearch', opts)
}

/**
 * 信息初始化弹窗，下载模板
 */
export function downLoadInfoModel() {
  return BOOKINGAPI.get(`/upload/template`, { responseType: 'blob' })
}

/**
 * 上传组织架构信息
 * @param {number} buildingId
 * @param {formData} formData
 */
export function uploadIndo({ buildingId, formData }) {
  return BOOKINGAPI.post(`/upload/${buildingId}`, formData)
}

/**
 * 信息初始化弹窗，下载错误数据
 */
export function downloadErrorInfo() {
  return BOOKINGAPI.get('upload/downloadError', { responseType: 'blob' })
}
