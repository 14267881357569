/* eslint-disable max-len */
export default {
  ST_DESK_ON_FLOOR_DISTRIBUTION: "Desks' distribution of the floors",
  ST_DESK_ON_TAG_DISTRIBUTION: 'Distribution of Desk Tag',
  ST_BUILDING_OVERVIEW_DATA: "Building's overview",
  ST_UN_ASSIGN_DESKING_SCALE: 'Proportion of Desks unassigned to Staff',
  ST_DESK_STATUS_DISTRIBUTE: "Distribution of Fixed Desks' status",
  ST_TOTAL_BUILDINGS_DESK_COUNT: 'Total desks in the building',
  ST_ASSIGNED_DESK_STAFF_COUNT: 'Assigned Staff',
  ST_UN_ASSIGN_DESK_COUNT: 'Desks unassigned to Staff',
  ST_TOTAL_DESK_COUNT: 'Fixed Desks',
  ST_ASSIGNED_DEPARTMENT: 'Department Assigned',
  ST_ASSIGNED_STAFF: 'Assigned Staff',
  ST_UNASSIGN: 'Unassigned',
  ST_UNASSIGNED_DESK_TOOLTIP_INFO1:
    'Proportion of Desks unassigned to Staff = ( Desks unassigned + Department Assigned) / Fixed Desks * 100%',
  ST_UNASSIGNED_DESK_TOOLTIP_INFO2: 'Desks unassigned to Staff = Fixed Desks - Assigned Staff ',
  ST_UNASSIGNED_DESK_TOOLTIP_INFO: 'Desks unassigned to Staff / Fixed Desks of the building',
  // ST_TAG_TYPE_COUNT_CALC_THIS_TAG_TYPE_COUNT: '已标签{deskType}：计算的是标记该标签名称的{deskType}数量',
  ST_TAG_TYPE_COUNT_CALC_THIS_TAG_TYPE_COUNT:
    'Number of tagged {deskType}: the calculation is the number of {deskType} tagged with this tag',
  ST_TAG_DESK_COUNT_CALC_TIPS: 'Number of desks with this tag',
  ST_TAG_FIXED_DESK_COUNT_CALC_TIPS: 'Number of fix desks with this tag',
  ST_TAG_HOT_DESK_COUNT_CALC_TIPS: 'Number of hot desks with this tag',
  ST_TAG_ASSIGN_STAFF_COUNT_CALC_TIPS: 'Number of assigned staff desks with this tag',
  ST_TAG_ASSIGN_DEPT_COUNT_CALC_TIPS: 'Number of assigned department desks with this tag',
  ST_TAG_UNASSIGN_COUNT_CALC_TIPS: 'Number of unassigned desks with this tag',
  ST_EXPORT_IMAGE: 'Export the picture.png',
  ST_EXPORT_XLS: 'Export the form.xls',
  ST_SYSTEM_DATA: 'Data of the system',
  ST_EXCEL_IMPORT: 'Import from Excel',
  ST_IMPORT_EXCEL: 'Import Excel',
  ST_SOURCE_SYSTEM_DATA: 'Data source：Data of the system',
  ST_SOURCE_EXCEL_IMPORT: 'Data source：Import from Excel',
  ST_UPLOAD_FILE_TIPS: 'Please upload files: (Support extensions: XLS, XLSX, single upload only)',
  ST_DOWNLOAD_TEMPLATE: 'Download the template',
  ST_PLEASE_SELECT_BUILDING_DATA: 'Please select the building to which the data belongs',
  ST_FLOORS: 'Floor ({count})',
  ST_DESK_COUNT: 'Desks',
  ST_FIXED_DESK: 'Fixed Desk',
  ST_IMPORT: 'Import',
  ST_EXPORT: 'Export',
  ST_BELONG_DEPT_DISTRIBUTION: "Desks' distribution of the Depts",
  ST_KEY_WORD_DEPT_NAME: 'Key words: Dept. name',
  ST_ALL_FLOORS: 'Floors',
  ST_TB_DEPARTMENT: 'Dept.',
  ST_TB_DESK_COUNT: 'Desk Amounts',
  ST_TB_DESK_COUNT_TIP: 'Desks : Desks assigned to "the Dept. + all Sub-Dept."',
  ST_TB_STAFF_DESK: 'Desk Amounts: Assigned Staff',
  ST_TB_STAFF_DESK_TIP: 'Assigned Staff : Desks assigned to Staff',
  ST_TB_DEPT_DESK: 'Desk Amounts: Department Assigned',
  ST_TB_DEPT_DESK_TIP: 'Department Assigned : Desks assigned to Dept. but not to Staff',
  ST_TB_RATIO: 'Bar of distribution proportion',
  ST_TB_RATIO_TIP: 'Proportion of Assigned Staff in the Dept. ：Assigned Staff/All Desks',
  ST_TB_MEMBER_COUNT: 'Staff',
  ST_TB_MEMBER_COUNT_TIP: 'Staff : Staff with "the Dept. + all sub-Dept."',
  ST_TB_LOCATE_FLOOR: 'Floor of the Dept.',
  ST_CONFIRM_CHECKOUT_BUILDING_SOURCE: 'Are you sure you want to switch the data source of this building to [{name}]？',
  ST_WARNING_VIEW_PERMISSIONS:
    'After switching the data source, ' +
    'it will take effect immediately. Staff with view permission will also see the latest data of the data source',
  ST_EXPORT_ALL_EXCELS: 'Export forms',
  ST_EXPORT_ALL_IMAGES: 'Export pictures',
  ST_UPDATE_EXCEL_DATA_SOURCE: 'Update Excel data source',
  ST_DEPT_COUNT: 'Departments',
  ST_DOWNLOAD_UPLOADED_DATA_MODIFY_THEN_UPLOAD: 'Download uploaded data and upload after modification',
  ST_MUST_UPLOAD_EXCEL_OF_FORMAT: '必须上传指定格式的EXCEL文件',
  ST_MUST_SELECT_BUILDING: 'Please select the building to which the data belongs',
  ST_DATA_OVERVIEW_IMPORT_TEMPLATE: 'Data overview - Data source - Import template',
  ST_DATA_OVERVIEW_ALL_PICTURES: 'Overview all pictures ',
  ST_DATA_OVERVIEW_ALL_TABLES: 'Overview all forms',
  ST_DATE: 'Date',
  ST_DATA_DUE_TIME: 'Latest data up to {time}',
  ST_UPLOAD_ERROR: 'The file has been changed, please upload it again.'
}
