export default {
  DESKING_ASSIGNATION: '分配',
  DESKING_ALL_FLOOR: '搜整楼',
  DESKING_SEARCH_BUILDING: '请选择楼宇',
  DESKING_SEARCH_FLOOR: '请选择楼层',
  DESKING_SEARCH_DESK: '请输入工位名称/人名/工号',
  DESKING_DESK_NUM: '{total}个工位',
  DESKING_DESK_NUMS: '{total}个工位',
  DESKING_DESK_NUM_BOOK: '个可预订工位',
  DESKING_SHOW_DESK_VIEW: '显示工位分配视图',
  DESKING_SHOW_DEPT_VIEW: '显示部门类型视图',
  DESKING_SEARCH_NOT_EMPTY: '搜索内容不能为空',
  DESKING_DESK_NOT_IN: '该工位不在该楼层',
  DESKING_DESK_NOT_IN_MAP: '该列表工位未与地图工位绑定',
  DESKING_DESK_NO_MAP: '暂无地图',
  DESKING_DESK_BLDG_NO_MAP: '楼宇{name}暂无地图',
  DESKING_DESK_FLOOR_NO_MAP: '楼层{name}暂无地图',
  DESKING_DESK_DESK_NO_MAP: '工位{name}暂无地图',
  DESKING_DESK_LOADING: '地图正在加载中...',
  DESKING_MAP_IN_BOWER: '您使用的浏览器暂不支持地图，请升级或改用Chrome获取更好的服务',
  DESKING_SELECT_DEPARTMENT_PLACE: '请选择部门',
  DESKING_SET_DEPARTMENT: '设置部门',
  DESKING_REOMVE_DEPARTEMTN_STAFF: '清除部门及员工',
  DESKING_SET_TAG: '设置标签',
  DESKING_HAS_SELECT: '已选',
  DESKING_HAS_SELECT_MANY: '项',
  DESKING_HAS_SELECT_MANYS: '项',
  DESKING_BATCH_OPERATION: '批量操作',
  DESKING_REG: '*输入字符过长',
  DESKING_CLEAR_CONFIRM: '你确定要清除该工位的部门及员工吗？',
  DESKING_CLEAR_SFAFF_CONFIRM: '你确定要清除该工位的员工吗？',
  DESKING_HAS_DESK: '已有固定工位:',
  DESKING_AND_CONTINUE: '是否继续添加{staff}为该员工的工位？',
  DESKING_AND_CONTINUE_LAST: '若继续，原工位会被自动释放',
  DESKING_CLEAR_SUCCESS: '清除工位成功',
  DESKING_DEPARTMENT_DIFFERENT: '该员工所属部门为[{departmentRole}]与工位所属部门[{departmentStaff}]不一致',
  DESKING_DEPARTMENT_NONE: '该员工所属部门为空与工位所属部门[{departmentStaff}]不一致',
  DESKING_ARE_YOU_CONTINUE: '是否继续添加?',
  DESKING_CONTINUE_RESULT: '若继续，可能会影响部门工位成本核算',
  DESKING_SELECT_WORDER_TYPE: '选择员工类型',
  DESKING_ASSIGN_TO: '分配工位给',
  DESKING_DESK_DEPARTMENT: '选择工位所属部门',
  DESKING_SEARCH_STAFF_NAME: '输入员工姓名或工号',
  DESKING_CLEAR_CONFRIM: '确定清除所选工位的部门及员工吗？',
  DESKING_DELETE_CONFIRM: '你确认要删除该工位?',
  DESKING_SEARCH_DESK_BOOK: '请输入工位名称',
  DESKING_BOOK_TITLE: '工位预订',
  DESKING_BOOK_START: '开始',
  DESKING_BOOK_END: '结束',
  DESKING_AM: '上午',
  DESKING_PM: '下午',
  DESK_INFORMATION: '工位详情',
  DESK_BASIC_INFO: '基本信息',
  DESK_KEY_INFO: '属性信息',
  DESK_DEVICE_BIND_INFO: '设备绑定',
  DESK_SENSOR_BIND_INFO: '传感器绑定',
  DESK_TAG_INFO: '标签及设备',
  DESK_DURATION: '持续时间',
  DESK_OPEN_DETAIL: '展开',
  DESK_fold_DETAIL: '收起',
  DESK_CHANGE_TYPE: '员工为空时可更改员工类型',
  DESK_HOT_DESK: '{total}个共享工位',
  DESK_HOT_DESKS: '{total}个共享工位',
  DESK_HOT_AVAILABLE: '({total}个可预订)',
  DESK_DEPARTMENT_TOTAL: '{total}个部门',
  DESK_DEPARTMENT_TOTALS: '{total}个部门',
  DESK_HAS_ASSIGNED: '已分配',
  DESK_DESK_NOT_EMPTY: '工位名称不能为空',
  DESK_FLOOR_NOT_EMPTY: '楼层不能为空',
  DESK_TAG_NOT_EMPTY: '工位标签不能为空',
  DESK_HSA_DEPARTMENT_DESK: '该工位已分配员工或部门',
  DESK_CHANGE_CLEAR: '若改变工位属性会清除已分配的信息',
  DESK_THANK_AGAIN: '再想想',
  DESK_DEPARTMENT_UNASSIGNED: '无部门',
  DESK_CAN_MANAGE: '{total}个可管理工位',
  DESK_CAN_MANAGES: '{total}个可管理工位',
  DESKING_SELECT_FLOOR: '请选择楼层',
  DESKING_HOLD_SHIFT: '按住shift键(',
  DESKING_HOLD_SHIFT_TEXT: ')选中多个工位',
  DESKING_FLOOR_CHANGE_INFO: '楼层地图已切换，请关注！'
}
