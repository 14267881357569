// import facilityStatus from './equipment/facility-status/en-US'

export default {
  COPYRIGHTS_TEXT:
    // eslint-disable-next-line max-len
    'Copyright © 2015-2021 DFocusGroup ® All Rights Reserved. ICP No. 16054689 Email: support@dfocuspace.com | AS-V1.9.3',
  LOGIN: 'Welcome',
  LOGIN_ACCOUNT: 'Please  enter your email  account',
  TRIPARTITE_ACCOUNT_LOGIN: 'Third Party Account Login',
  LOGIN_WELCOME_TITLE: 'Welcome',
  LOGIN_REMEMBER_ME_TXT: 'Remember Me',
  LOGIN_REMEMBER_ME_PASSWORD: 'Remember me',
  FORGET_PASSWORD: 'Forgot password?',
  ACCOUNT_LOGIN: 'Account Password Login',
  LOGIN_ACCOUNT_EMPTY_ERROR: 'Email account cannot be empty',
  LOGIN_ACCOUNT_FORMAT_ERROR: 'Please enter the correct email format',
  LOGIN_ACCOUNT_LENGTH_ERROR: 'Email account cannot exceed 50 characters',
  LOGIN_ACCOUNT_PLACEHOLDER: 'Please enter your email address',
  LOGIN_PASSWORD_PLACEHOLDER: 'Please enter the password',
  LOGIN_PASSWORD_EMPTY_ERROR: 'Password cannot be empty',
  LOGIN_CODE_EMPTY_ERROR: 'Verfication code cannot be empty',
  LOGIN_GET_CODE_ERROR: 'Failed to get verification code',
  LOGIN_CODE_PLACEHOLDER: 'Please enter the Verification code',
  LOGIN_PASSWORD_FORMAT_ERROR: 'Please enter a 8-20 bit combination of Letters and Numbers',
  LOGIN_ACCOUNT_PWD_ERROR: 'Account or Password is incorrect!',
  LOG_OUT: 'Sign Out',
  LOGIN_NEW_PASSWORD: 'Please enter a new password',
  LOGIN_NEW_PASSWORD_AGAIN: 'Please enter the  password again',
  LOGIN_CHANGE_PASSWORD: 'Change  password',
  LOGIN_CHANGE_PASSWORD_ERROR: 'Passwords do not match',
  USAGE_AD_LOGIN: 'Log in with your AD account',
  GO_BACK: 'Go Back',
  AD_ACCOUNT_EMPTY: 'Account is required',
  PLEASE_ENTER_AD_ACCOUNT: 'Please enter AD account',
  AD_LOGIN: 'AD Login',
  NORMAL_LOGIN: 'Log in',
  DINGDING_LOGIN: 'DingDing Login',
  WECHAT_LOGIN: 'Wechat Login',
  FEISHU_LOGIN: 'Feishu Login',
  LOGIN_SEND_CODE: 'Send code',
  LOGIN_SEND_AGAIN: 'Resend',
  RESET_PASSWORD_EMAIL: 'Please enter your mailbox',
  LOGIN_ENTER_THE_VERIFICATION_CODE: 'Please enter the received verification code',
  LOGIN_ENTER_THE_VERIFICATION_CODE_PLACEHOLDER: 'Verification code',
  LOGIN_CAPTCHA_GET_SECCESS: 'The verification code has sent to the mailbox. It is valid for 5mins. Please check.',
  LOGIN_ENTER_NEW_PASSWORD: 'Please enter the new password',
  LOGIN_RESET: 'Reset',
  LOGIN_PREVIOUS: 'Previous',
  LOGIN_PWD_RESET_SUCCESS: 'Password reset, please login',
  THIRD_PARTY_LOGIN: 'Third Party Login',
  CLICK_ICON_LOGIN: 'Click to'
}
