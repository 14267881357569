export default {
  EXTERNAL: 'External/Others',
  OTHERS: '',
  BOOKED: 'Failed: the desk has been booked',
  BOOKCHANGED: 'Failed: the desk type has been changed',
  BOOKNOTFOUND: "Failed: the desk can't been found",
  BOOKSUCCESS: 'Booking success',
  BOOKTWOSUCCESS: 'Booking success： create 2 records ',
  BOOKADVANCE: 'Desk can be booked up to ## days in advance',
  BOOKNOTMORE: "The desk cann't been booked more than a day",
  REFRESH: 'The administrator has modified the booking rules，please try again',
  PASSEDTIME: 'The start time has passed',
  FORME: 'Book for me',
  FOROTHERS: 'Book for others',
  TIME: 'Time',
  DESKNAME: 'Desk Name',
  LOCATION: 'Location',
  BOOKER: 'Booker',
  USER: 'User',
  MORNING: 'half day（AM）',
  AFTERNOON: 'half day（PM）',
  WHOLEDAY: 'Whole day',
  BOOKAGAIN: 'Book again',
  CANCEL: 'Cancel',
  LOCATE: 'Location',
  END: 'End',
  UNSTARTED: 'Unstarted',
  ONGOING: 'Ongoing',
  ENDEDNORMAL: 'Ended normal',
  CANCELLED: 'Cancelled',
  EARLYEND: 'Ended early',
  MY_RESERVATION_WAITING: 'Waiting',
  MY_RESERVATION_RELEASED: 'Released',
  CONFIRMCANCEL: 'Confirm to cancel the booking？',
  CONFIRMEND: 'Confirm to end the booking？',
  CONFIRM: 'Confirm',
  DURATION: 'Duration',
  BOOKSTATION: 'Booking Desk',
  TYPE: 'Type',
  USERNAME: 'Username',
  DESK: 'Desk Name',
  BOOKINGSTATUS: 'Desk Status',
  INTERNALSTAFF: 'Internal Staff',
  INNER: 'Internal',
  OUTER: 'External',
  EXTERNALTRAINEE: 'External Trainee',
  EXTERNALOUTSOURING: 'External Outsouring',
  EXTERNALOTHERS: 'External Others',
  CROSSDAYS: 'Cross Days',
  DESKLABEL: 'Desk Label',
  BOOK: 'Book',
  BUILDINGNAME: 'Location',
  FLOORNAME: 'Floor',
  TIMEMESSAGE: 'Please choose time',
  SHOWMOREDETAIL: 'Unfold',
  MYBOOKING_DESK_NOT_BIND_MAP: "The desk hasn't bind map"
}
