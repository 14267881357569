/* eslint-disable max-len */
export default {
  COPYRIGHTS_TEXT:
    'Copyright © 2015-2021 DFocusGroup ® All Rights Reserved. ICP No. 16054689 Email: support@dfocuspace.com | AS-V1.9.3',
  LOGIN: '登录账号',
  LOGIN_ACCOUNT: '',
  TRIPARTITE_ACCOUNT_LOGIN: '第三方账号登录',
  LOGIN_WELCOME_TITLE: '欢迎登录',
  LOGIN_REMEMBER_ME_TXT: '记住我',
  LOGIN_REMEMBER_ME_PASSWORD: '记住密码',
  FORGET_PASSWORD: '忘记密码?',
  ACCOUNT_LOGIN: '账号密码登录',
  LOGIN_ACCOUNT_EMPTY_ERROR: '邮箱账号不能为空',
  LOGIN_ACCOUNT_FORMAT_ERROR: '请输入合法邮箱',
  LOGIN_ACCOUNT_LENGTH_ERROR: '邮箱账号不能超过50个字符',
  LOGIN_ACCOUNT_PLACEHOLDER: '请输入邮箱账号',
  LOGIN_PASSWORD_PLACEHOLDER: '请输入密码',
  LOGIN_PASSWORD_EMPTY_ERROR: '密码不能为空',
  LOGIN_CODE_PLACEHOLDER: '请输入验证码',
  LOGIN_CODE_EMPTY_ERROR: '验证码不能为空',
  LOGIN_GET_CODE_ERROR: '获取验证码失败',
  LOGIN_PASSWORD_FORMAT_ERROR: '密码8-20位,大写&小写字母、数字、特殊符号至少包含三种',
  LOG_OUT: '注销',
  LOGIN_NEW_PASSWORD: '请输入新密码',
  LOGIN_NEW_PASSWORD_AGAIN: '请再次输入新密码',
  LOGIN_CHANGE_PASSWORD: '修改密码',
  LOGIN_CHANGE_PASSWORD_ERROR: '两次输入的密码不一致',
  SEARCH: '搜索',
  USAGE_AD_LOGIN: '使用AD账号登录',
  GO_BACK: '返回',
  AD_ACCOUNT_EMPTY: '账号不能为空',
  PLEASE_ENTER_AD_ACCOUNT: '请输入AD账号',
  AD_LOGIN: 'AD登录',
  NORMAL_LOGIN: '登录',
  DINGDING_LOGIN: '钉钉登录',
  WECHAT_LOGIN: '企业微信登录',
  FEISHU_LOGIN: '飞书登录',
  LOGIN_SEND_CODE: '发送验证码',
  LOGIN_SEND_AGAIN: '再次发送',
  RESET_PASSWORD_EMAIL: '请输入您的邮箱',
  LOGIN_ENTER_THE_VERIFICATION_CODE: '请输入收到的验证码',
  LOGIN_ENTER_THE_VERIFICATION_CODE_PLACEHOLDER: '请输入收到的验证码',
  LOGIN_CAPTCHA_GET_SECCESS: '验证码获取成功，已发送至邮箱，验证码有效期5分钟，请注意查收',
  LOGIN_ENTER_NEW_PASSWORD: '请输入新密码',
  LOGIN_RESET: '重置',
  LOGIN_PREVIOUS: '上一步',
  LOGIN_PWD_RESET_SUCCESS: '密码重置成功，请登录',
  THIRD_PARTY_LOGIN: '第三方账号登录',
  CLICK_ICON_LOGIN: '点击进行'
}
