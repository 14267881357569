export default {
  QR_QR_CODE_TEMPLATE: 'QR code Template',
  QR_EDIT_TEMPLATE: 'Edit',
  QR_PUBLISH: 'Publish',
  QR_CONFIRM_TO_THE_TEMPLATE_TO_PUBLISH: 'Are you sure to publish according to this template?',
  QR_CONFIRM_TO_END_AND_PUBLISH_NEW_TEMPLATE:
    // eslint-disable-next-line max-len
    'The last updated QR code is still being generated, is it confirmed to end early and update according to the new template?',
  QR_NO_NEW_INFO_NO_PUBLISH: 'You have not made new change and do not need to publish.',
  QR_PUBLISH_SUCCESS: "We're working hard to generate the QR code!",
  QR_PUBLISH_WARNINGS:
    // eslint-disable-next-line max-len
    'Due to the number of your work stations, it will take about {time} to update, and it cannot be downloaded during the period, please be patient.',
  QR_ONE_HOUR: '1 hour',
  QR_TEMPLATE_EXAMPLE: 'Example',
  QR_EXPORT_ALL_CODES: 'QR codes',
  QR_DOWNLOAD_CODE_AFTER_COMPLETED: 'The QR code is still being generated, please download it later~!',
  QR_EDIT_CODE_TEMPLATE: 'Edit QR code Template',
  QR_TEMPLATE_PREVIEW: 'Template Preview',
  QR_SELECT_MODE: 'Template mode',
  QR_STANDARD_MODE: 'Standard mode',
  QR_SIMPLE_MODE: 'Simple mode',
  QR_AVATAR: 'QR code Avatar',
  QR_AVATAR_SEL_SUCCESS: 'Select successfully',
  QR_AVATAR_OVER_SIZE: 'Select failed, image size must be less than 1M',
  QR_AVATAR_RECOMMENDED:
    // eslint-disable-next-line max-len
    'Support .png .jpeg .jpg format file, no more than 1M, the recommended size is 64px * 64px. If not uploaded, the default avatar will be used.',
  QR_RESET_AVATAR: 'Restore default avatar',
  QR_TIP_OVER_LENGTH: 'The text cannot exceed 1 line',
  QR_UPDATE_SUCCESS: 'Save successfully, if you need to update the QR code of the workstation, please click Publish',
  QR_TIP_WORD: 'Cue text',
  QR_FIRST_LINE: 'First line template',
  QR_SECOND_LINE: 'Second line template',
  QR_FIRST_LINE_PLACEHOLDER: 'Available references:$Desk Attribute-$Desk Name-$Desk ID-{location}-$Building-$Floor',
  QR_SECOND_LINE_PLACEHOLDER: 'Available references:$Desk Attribute-$Desk Name-$Desk ID-{location}-$Building-$Floor',
  QR_DEFAULT_TIP: 'Open system 「scan」',
  QR_BACKGROUND_COLOR: 'Background color',
  QR_RESET_COLOR: 'Restore default color'
}
