// import facilityStatus from './equipment/facility-status/en-US'

export default {
  PER_SEARCH_ROLENAME: 'Search Role Name',
  PER_TOTAL: 'total',
  PER_SEARCH_STRIP: 'strip',
  TABLE_ROLE_ID: 'Role ID',
  TABLE_ROLE_NAME: 'Role Name',
  TABLE_ROLE_DESCRIPATION: 'Description',
  TABLE_ROLE_OPERATION: 'Operation',
  EDIT_ROLE: '+Add Role',
  ROLE_PERMISSION: 'Roles Permission',
  ROLE_PERMISSIONONE: 'Roles',
  ROLE_DETAIL: 'Role Detail',
  FUNCTION_PERMISSION: 'Function Menu',
  ROLE_NAME_EMPTY_ERROR: 'Role name cannot be empty',
  EDIT_ROLE_TITLE: 'Edit Role',
  ROLE_NAME_INPUT_PLACEHOLDER: 'Please enter role name',
  REMARKS_INPUT_PLACEHOLDER: 'Please enter description',
  ROLE_REMARKS: 'Description',
  REMARK_MORE_CHARACTERS: 'At most 100 characters',
  ROLE_MORE_CHARACTERS: 'At most 50 characters',
  AT_LEAST_PERIMISSION: 'At least function menu must be checked',
  DELECT_WARING_TEXT1: 'Confirm delete the role【',
  DELECT_WARING_TEXT2: '】?',
  ADD_ROLE_TITLE: 'Add Role',
  LAST_ONE_DATA_AUTH: 'At least data permission must be checked'
}
