export default {
  PREF_SET_MOBILE_SYS_TITLE: 'Mobile System Title',
  PREF_SET_EDIT: 'Edit',
  PREF_SET_EDIT_MOBILE_SYS_TITLE: 'Edit Mobile System Title',
  PREF_SET_SYS_TITLE: '{type} System Title',
  PREF_SET_SYS_TITLE_PLH: 'Please input system title',
  PREF_SET_TPL_PREVIEW: 'Template Preview',
  PREF_SET_CONFIRM: 'Confirm',
  PREF_SET_CANCEL: 'Cancel',
  PREF_SET_ERR_EMPTY: 'Cannot be empty.',
  PREF_SET_ERR_EXCEED: 'Cannot exceed {num} characters',
  PREF_SET_SAVE_SUCCESS: 'Save successfully',
  PREF_SET_SAVE_FAILURE: 'Save failed. The network was skipped. Please try again later.'
}
