//
// import { ReactComponent as ManageIcon } from '../../assets/manage.svg'
import { ReactComponent as Booking } from '../../assets/booking.svg'
import { ReactComponent as Desking } from '../../assets/desking.svg'
import { ReactComponent as EquipmentManage } from '../../assets/equipmentManage.svg'
import { ReactComponent as ManageIcon } from '../../assets/manage.svg'
import { ReactComponent as Statics } from '../../assets/statics.svg'
import { ReactComponent as SystemSetting } from '../../assets/systemSetting.svg'
import { ReactComponent as UserManage } from '../../assets/userManage.svg'
import { ReactComponent as Security } from '../../assets/security.svg'

import { ReactComponent as ActBooking } from '../../assets/active_icon/act_booking.svg'
import { ReactComponent as ActDesking } from '../../assets/active_icon/act_desking.svg'
// import { ReactComponent as EquipmentManage } from '../../assets/active_icon/'
import { ReactComponent as ActManageIcon } from '../../assets/active_icon/atc_manage.svg'
import { ReactComponent as ActStatics } from '../../assets/active_icon/act_statics.svg'
import { ReactComponent as ActSystemSetting } from '../../assets/active_icon/act_sys_setting.svg'
import { ReactComponent as ActUserManage } from '../../assets/active_icon/atc_user_manage.svg'
import { ReactComponent as ActSecurity } from '../../assets/active_icon/act_sec_manage.svg'
import { hashArrToObjByKey, getHashParentArr } from '../../helpers/text'

export const ALL_MENUS_TREE = [
  {
    name: '工位预订',
    path: '/book',
    icon: <Booking />,
    activeIcon: <ActBooking></ActBooking>,
    sn: 'DESKBOOKING',
  },
  {
    name: '工位分配',
    path: '/map',
    icon: <Desking />,
    activeIcon: <ActDesking></ActDesking>,
    sn: 'DESKASSIGNATION',
  },
  {
    name: '空间管理',
    path: '/spaceManageFather',
    icon: <ManageIcon />,
    activeIcon: <ActManageIcon></ActManageIcon>,
    sn: 'SPACEMANAGEMENT',
    children: [
      {
        name: '工位管理',
        path: '/spaceManage',
        sn: 'DESKMANAGE',
        parentPath: '/spaceManageFather',
      },
      {
        name: '工位管理-楼层',
        path: '/spaceManage/building',
        sn: 'DESKMANAGEBUILDING',
        parentPath: '/spaceManageFather',
      },
      {
        name: '工位管理-工位',
        path: '/spaceManage/floor',
        sn: 'DESKMANAGEFLOOR',
        parentPath: '/spaceManageFather',
      },
      {
        name: '工位标签库',
        path: '/customizetags',
        sn: 'DESKLABEL',
        parentPath: '/spaceManageFather',
      },
    ],
  },
  {
    name: '部门空间经理',
    path: '/departmentSpaceFather',
    icon: <ManageIcon />,
    activeIcon: <ActManageIcon></ActManageIcon>,
    sn: 'DEPTSPACEMANAGER',
    children: [
      {
        name: '接口人工位分配',
        path: '/interfaceMap',
        sn: 'INTERFACERDESKASSIGNATION',
        parentPath: '/departmentSpaceFather',
      },
      {
        name: '接口人空间管理',
        path: '/interfaceManage',
        sn: 'INTERFACERSPACEMANAGEMENT',
        parentPath: '/departmentSpaceFather',
      },
      {
        name: '接口人空间管理-楼层',
        path: '/interfaceManage/building',
        sn: 'DESKMANAGEBUILDING',
        parentPath: '/departmentSpaceFather',
      },
      {
        name: '接口人空间管理-工位',
        path: '/interfaceManage/floor',
        sn: 'DESKMANAGEFLOOR',
        parentPath: '/departmentSpaceFather',
      },
    ],
  },
  // {
  //   name: '接口人工位分配',
  //   path: '/interfaceMap',
  //   icon: <Desking />,
  //   activeIcon: <ActDesking></ActDesking>,
  //   sn: 'INTERFACERDESKASSIGNATION'
  // },
  // {
  //   name: '接口人空间管理',
  //   path: '/interfaceManage',
  //   icon: <ManageIcon />,
  //   sn: 'INTERFACERSPACEMANAGEMENT',
  //   activeIcon: <ActManageIcon></ActManageIcon>
  // },

  {
    name: '用户管理',
    path: '/userManage',
    icon: <UserManage />,
    activeIcon: <ActUserManage></ActUserManage>,
    sn: 'USERS',
    children: [
      {
        name: '组织架构',
        path: '/organizationalStructure',
        sn: 'DEPARTMENTS',
        parentPath: '/userManage',
      },
      {
        name: '用户列表',
        path: '/user-page',
        sn: 'USERLIST',
        parentPath: '/userManage',
      },
      {
        name: '角色权限',
        path: '/rolePermissions',
        sn: 'ROLES',
        parentPath: '/userManage',
      },
      // {
      //   name: '员工审批',
      //   path: '/user-approve',
      //   sn: 'USERAPPROVE',
      //   parentPath: '/userManage',
      // },
    ],
  },
  {
    name: '系统设置',
    path: '/systemsetting',
    icon: <SystemSetting />,
    activeIcon: <ActSystemSetting></ActSystemSetting>,
    sn: 'SETTINGS',
    children: [
      {
        name: '企业信息设置',
        path: '/company-setting',
        sn: 'COMPANYINFORMATIONSETTING',
        parentPath: '/systemsetting',
      },
      {
        name: '全局设置',
        path: '/global-config',
        sn: 'GLOBALSETTINGS',
        parentPath: '/systemsetting',
      },
      {
        name: '工位规则',
        path: '/deskrule',
        sn: 'DESKRULE',
        parentPath: '/systemsetting',
      },
      {
        name: '二维码模板',
        path: '/qr-code',
        sn: 'QRCODE',
        parentPath: '/systemsetting',
      },
      {
        name: '系统偏好设置',
        path: '/preference-setting',
        sn: 'SYSTEMPREFERENCES',
        parentPath: '/systemsetting',
      },
      {
        name: '系统邮箱',
        path: '/email-setting',
        sn: 'SYSTEMMAILBOX',
        parentPath: '/systemsetting',
      },
      {
        name: '第三方集成',
        path: '/third-part',
        sn: 'THIRDCONFIG',
        parentPath: '/systemsetting',
      },
      {
        name: '许可证',
        path: '/license',
        sn: 'LICENSEINFO',
        parentPath: '/systemsetting',
      },
      {
        name: '关于我们',
        path: '/about-setting',
        sn: 'ABOUTUS',
        father: '/systemsetting',
      },
    ],
  },
  {
    name: '分析统计',
    path: '/static',
    icon: <Statics />,
    activeIcon: <ActStatics></ActStatics>,
    sn: 'STATISTICS',
    children: [
      // {
      //   name: '数据概览',
      //   path: '/statistics',
      //   sn: 'DATAOVERVIEW',
      //   parentPath: '/static',
      // },
      {
        name: '预订数据概览',
        path: '/book-statistics',
        sn: 'BOOKSTATISTICS',
        parentPath: '/static',
      },
      {
        name: '预定报表',
        path: '/bookingForm',
        sn: 'RESERVEREPORT',
        parentPath: '/static',
      },
      {
        name: '分配统计报表',
        path: '/iframe',
        sn: 'JINGTIANIFRAME',
        parentPath: '/static',
      },
      {
        name: '使用情况',
        path: '/usage',
        sn: 'DESKUSE',
        parentPath: '/static',
      },
    ],
  },
  {
    name: '安全管理',
    path: '/securityManage',
    icon: <Security />,
    activeIcon: <ActSecurity></ActSecurity>,
    sn: 'SECURITYMANAGE',
    children: [
      {
        name: '日志管理',
        path: '/log',
        parentPath: '/securityManage',
        sn: 'LOGMANAGE',
      },
      {
        name: '自定义密码',
        path: '/custom-password',
        parentPath: '/securityManage',
        sn: 'CUSTOMPASSWORD',
      },
    ],
  },
  {
    name: '设备管理',
    path: '/map2',
    icon: <EquipmentManage />,
    sn: '',
  },
]

export const getHashPathArr = () => {
  const obj = hashArrToObjByKey(ALL_MENUS_TREE, 'path', 'children')
  return getHashParentArr(obj, 'parentPath')
}
