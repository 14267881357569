export default {
  APPROVE_UN_HANDLE: 'Not processed',
  APPROVE_HANDLEED: 'Processed',
  APPROVE_RECALL: 'Revoked',
  APPROVE_REFUSED: 'Rejected',
  APPROVE_RECEIVE_EMAIL_OF_PEOPLE: 'Set up email recipients',
  APPROVE_SEARCH: 'Search: UserName',
  APPROVE_DESCRIPTION: 'Application Description',
  APPROVE_PEOPLE: 'Applicant',
  APPROVE_TIME: 'Application Time',
  APPROVE_HANDLE_TIME: 'Processing time',
  APPROVE_HANDLE_TYPE: 'Processing type',
  APPROVE_HANDLE_PEOPLE: 'Processed by',
  APPROVE_STATUS: 'Status',
  APPROVE_HANDLE: 'Processing',
  APPROVE_REFUSE: 'Reject',
  APPROVE_CONTENT_QUOTA: 'Apply to change the quota from {nowDeskQuota} to {amount}',
  APPROVE_FEEDBACK: 'Feedback on application',
  APPROVE_ITEMS: 'Total {total}',
  APPROVE_CONTENT: 'Application content',
  APPROVE_NOW_QUOTA: 'Available quota',
  APPROVE_SOLVE_WAY: 'Solutions',
  APPROVE_SOLVE_STATUS_2: 'No need to process, close the application',
  APPROVE_SOLVE_STATUS_3: 'Change the quota',
  APPROVE_CHANGE_QUOTA: 'Change the quota',
  APPROVE_CONFIRM_REFUSE: 'Confirm',
  APPROVE_DETAIL: 'Application details',
  APPROVE_PROCESS_DETAIL: 'Processing details',
  APPROVE_EXAMINE: 'Approval Comments',
  APPROVE_RECEIVE_MESSAGE_EMAIL: 'E-mail address',
  APPROVE_SEND_APPROVE: 'Receiving applications',
  APPROVE_ASSIGNED_FAILED: 'Assign failed',
  APPROVE_ASSIGNED_CAN_NOT_QUOTA: 'The number of desk(s) assigned cannot be greater than the quota',
  APPROVE_CONFIRM_USER_TO_DESK_NAME: 'Confirm to assign the desk【{deskName}】to {userName}?',
  APPROVE_DESK_QUOTA_ASSIGNED_DESK: 'The quota of {userName} is {deskQuota}, assigned {assignedLength}{desksName}',
  PLEASE_INPUT_LIMIT_WORDS: 'Please enter {count} words or less'
}
