import desk from './desking/en-US'
import common from './common/en-US'
import deskrule from './deskrule/en-US'
import login from './login/en-US'
import manage from './manage/en-US'
import myBooking from './myBooking/en-US'
import organizational from './organizational/en-US'
import permissions from './permissions/en-US'
import log from './log/en-US'
import pwd from './pwd/en-US'
import customizetags from './customizetags/en-US'
import globalConfig from './global-config/en-US'
import bookForm from './bookForm/en-US'
import book from './book/en-US'
import statistics from './statistics/en-US'
import qrCode from './qrCode/en-US'
import companySetting from './company-setting/en-US'
import aboutUs from './about-us/en-US'
import preferenceSetting from './preference-setting/en-US'
import map from './map/en-US'
import customPassword from './custom-password/en-US'
import emailSetting from './email-setting/en-US'
import license from './license/en-US'
import thirdPart from './third-part/en-US'
import userPage from './user-page/en-US'
import bookStatistics from './book-statistics/en-US'
import userApprove from './user-approve/en-US'

export default {
  ...common,
  ...book,
  ...bookForm,
  ...globalConfig,
  ...customizetags,
  ...pwd,
  ...log,
  ...login,
  ...desk,
  ...permissions,
  ...organizational,
  ...myBooking,
  ...manage,
  ...deskrule,
  ...statistics,
  ...qrCode,
  ...companySetting,
  ...aboutUs,
  ...preferenceSetting,
  ...map,
  ...customPassword,
  ...emailSetting,
  ...license,
  ...thirdPart,
  ...userPage,
  ...bookStatistics,
  ...userApprove,
  // global
  MENU_MY_BOOKING: 'My booking',
  APP_LOG_OUT: 'Sign out',
  FEED_BACK: 'Feed back',
  CONFIRM_BUTTON: 'Confirm',
  DELETE_BUTTON: 'Delete',
  CANCEL_BUTTON: 'Cancel',
  EDIT_BUTTON: 'Edit',
  VIEW_BUTTON: 'View',
  CONTINUE_BUTTON: 'Continue',
  MORE: 'More',
  FUNCTION: 'Function',
  MENU: 'Menu',
  TYPE: 'Type',
  TOTAL: 'Total',
  DESK_TYPES: 'Desk Status',
  AMOUNTS: 'Amounts',
  SELECT_BUTTON: 'Select',
  SEARCH: 'Search',
  TIPS: 'Tips',
  RESET_BUTTON: 'Reset',
  CLEAR_BUTTON: 'Clear',
  CLEAR_REMOVE_BUTTON: 'Remove',
  OPERATION: 'Operation',
  ENTER: 'Enter',
  MAX_CHARACTERS: 'At most {num} characters',
  TOTAL_TRIPS: 'total {total} strip',
  MEMBER_NAME: 'Member',
  ROLE_NAME_TABLE: 'Role',
  ROLE_NAME_TITLE: 'Roles',
  ROLE_NAME_SEARCH: 'Roles',
  DEPARTMENT_TITLE: 'Departments',
  DEPARTMENT: 'Department',
  DEPARTMENT_SORT: 'Dept', //英文为department
  NAME: 'Name',
  PASSWORD: 'Password',
  ADD_SUCCESS: 'Added successfully',
  SAVE_SUCCESS: 'Saved successfully',
  NO_DATA: 'No Data',
  NO_SEARCH_DATA: 'No search results',
  //DESK
  DESK: 'Desk',
  STAFF: 'Staff',
  UNASSIGNED: 'Unassigned',
  RESERVED: 'Preserved',
  PENDING_ALLOCATION: 'Unassigned Staff',
  ASSIGNED_STAFF: 'Assigned Staff',
  SHARE_DESK: 'Hot Desk',
  USED: 'Enable',
  NO_USED: 'Disable',
  NO_USED_DESK: 'Disable',
  NO_DEPARTMENT: 'No department',
  UNASSIGNED_EMPLOYEE: 'Fixed Desk Staff',
  ASSIGNED_DEPARTMENT: 'Department Assigned',
  ASSIGNED_DEPARTMENTS: 'Department Assigned',
  DESKING_DISABLED_DESK: 'Disable desk',
  DESK_NAME: 'Desk Name',
  DESK_AUTHORITY: 'Desk Status',
  DESK_TAG: 'Desk Tag',
  DESK_TYPE: 'Desk Type',
  DESK_AREA: 'Average desk area',
  AVAILABLE_TO_ALL: 'Available to All',
  AVAILABLE_TO_PART: 'Available to Part',
  CLOSED_BOOKING: 'Closed Booking',
  DISABLE: 'Disable',
  DESK_INFORMATION: 'Desk information',
  DESK_ID: 'Desk ID',
  SECRECY: 'Secrecy',
  STAFF_TYPE_ONE: 'Internal Staff',
  STAFF_TYPE_TWO: 'External Trainee',
  STAFF_TYPE_THR: 'External Outsourcing',
  STAFF_TYPE_FOU: 'External Others',
  AVAILABLR_STATUS: 'Available',
  UNAVAILABLE_STATUS: 'Unavailable',
  BOOKING: 'Book',
  TIME: 'Time',
  DESK_DESK_NOT_EMPTY: 'Desk name cannot be empty',
  DESK_FLOOR_NOT_EMPTY: 'Floor cannot be empty',
  DESK_TAG_NOT_EMPTY: 'Desk tag cannot be empty',
  CHECK_ALL: 'All {location}, buildings and floors',
  ALL_BUILDING_AND_FLOOR: 'All buildings and floors',
  DATA_AUTH: 'Data Permission',
  NO_ASSESS: 'No access',
  TRUE: 'Yes',
  FALSE: 'No',
  LAST_STEP: 'Cancel',
  DESKASSIGNATION: 'Desk Assignation',
  INTERFACE_DESKASSIGNATION: 'Dept.Space Manager Assign',
  DOWNLOAD: 'Download',
  QRCODE: 'QR code',
  DELETED: '(Deleted)',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_ONE_WEEK: 'Last 7 days',
  LAST_ONE_MONTH: 'Last 1 month',
  LAST_THREE_MONTH: 'Last 3 months',
  LAST_ONE_YEAR: 'Last 1 year',
  H5: 'H5 System',
  WEB: 'Web System',
  SELECT_ALL: 'Select all',
  EXCEPT_DATE: 'Except date',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  QUERY: 'Search',
  AVAILABLE: 'Vacant',
  STANBY: 'Pending',
  OCCUPIED: 'In use',
  IMPROMPTU: 'Occupied',
  REFRESH: 'Refresh',
  SUM: 'Sum',
  CLICK_TO_LOGIN: 'Click to login by scan qrcode EY APP',
}
