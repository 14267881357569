import _ from 'lodash'
import DEFAULT_MENUS from '../components/Auth/AuthMap'
import { ALL_MENUS_TREE } from '../components/GlobalSidebar/menus'
import check from '../helpers/basic-check'
const { isArray, isObject } = check
// eslint-disable-next-line
export const someHref = 'javascript:void(0)'

export function getTitle(locale, titleKey) {
  if (!titleKey) {
    return ''
  }
  const [namespace, key] = titleKey.split('.')

  if (!locale[namespace]) {
    return ''
  }

  return locale[namespace][key]
}

// 处理权限列表
export function handleCurrentAuths(val) {
  let auths = []
  val.forEach((f) => {
    const fObj = { sn: f.sn, level: f.level }
    auths.push(fObj)
    if (f.childrens || f.childrens.length) {
      f.childrens.forEach((c) => {
        const cObj = { sn: c.sn, level: c.level }
        auths.push(cObj)
      })
    }
  })
  return auths
}

/**
 * 获取所有有权限的menus
 * @param {*} menus 模块的menus(Auth/AutnMap.js)
 * @param {*} snArr 该模块拥有的权限sn表
 */
export function getAuthMenus(menus, snArr) {
  if (!snArr || !snArr.length) {
    return []
  }
  let newMenus = []
  menus.forEach((menu) => {
    if (menu.children) {
      menu.children.forEach((ch) => {
        newMenus.push(ch)
      })
    }
    newMenus.push(menu)
  })
  const initaMenus = newMenus.filter((m) => {
    return m.sn && _.includes(snArr, m.sn)
  })
  return initaMenus
}
export function getAllMenu(currentUserMenu) {
  const _currentUserMenu = JSON.parse(JSON.stringify(currentUserMenu))
  const { POSITION } = DEFAULT_MENUS
  const allMenus = POSITION //工位系统所有的menu
  const allSn = _currentUserMenu.menus // 用户拥有的权限的menuSN
  const menuItems = delectSpaceChildren(allMenus, allSn) // 获取所有有权限的Menus
  _currentUserMenu.menus = menuItems
  return _currentUserMenu
}
//获取菜单整合菜单
export function getNewMenu(allMenus, allSn) {
  allSn.forEach((menu) => {
    if (menu.children) {
      let filterMenu = allMenus.filter((m) => {
        return m.sn === menu.code
      })
      menu.icon = filterMenu[0]?.icon
      menu.pathName = filterMenu[0]?.path
      menu.children.forEach((ch) => {
        var newFilter = allMenus.filter((m) => {
          return m.sn === ch.code
        })
        if (newFilter.length > 0) {
          ch.pathName = newFilter[0].path
          ch.icon = newFilter[0].icon
          ch.father = newFilter[0].father
        }
      })
    } else {
      var fatherNewFilter = allMenus.filter((m) => {
        return m.sn === menu.code
      })
      if (fatherNewFilter.length > 0) {
        menu.pathName = fatherNewFilter[0].path
        menu.icon = fatherNewFilter[0].icon
      }
    }
  })
  return allSn
}
export function delectSpaceChildren(allMenus, allSn) {
  allSn.forEach((item) => {
    if (item.children.length === 0) {
      delete item.children
    } else {
      item.children.forEach((items) => {
        delete items.children
      })
    }
  })
  return getNewMenu(allMenus, allSn)
}
//16进制转换成rgba
export function convertColorToRgbaByHex(color, alpha = 100) {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  let sColor = color.toLowerCase()
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt(`0x${sColor.slice(i, i + 2)}`, 16))
    }
    return `rgba(${sColorChange.join(',')},${alpha / 100})`
  } else {
    return sColor
  }
}

export const dealWithMenus = (data) => {
  const _data = JSON.parse(JSON.stringify(data))
  const { menus } = _data
  const _menus = JSON.parse(JSON.stringify(menus))
  const _allMenusTree = ALL_MENUS_TREE
  // 数据扁平化
  const authMenusObj = flatAuthArrToObjByCode(_menus)
  const pathWithUrl = _filterMenuUrls(_allMenusTree, authMenusObj)
  const newMenus = _filterMenus(_allMenusTree, authMenusObj)
  return {
    ..._data,
    menus: newMenus,
    pathWithUrl,
  }
}

// 把所有数组 以code 为key 进行hash
export const flatAuthArrToObjByCode = (arr) => {
  let _obj = {}
  if (!isArray(arr)) {
    return _obj
  }
  for (let v of arr) {
    const { children, code } = v
    if (children && children.length !== 0) {
      _obj[code] = v
      _obj = {
        ...flatAuthArrToObjByCode(children),
        ..._obj,
      }
    } else {
      _obj[code] = v
    }
  }
  return _obj
}

// 把所有数组 以code 为key 进行hash
export const flatAuthArrToObjByKey = (key, arr) => {
  let _obj = {}
  if (!isArray(arr)) {
    return _obj
  }
  for (let v of arr) {
    const { children } = v
    const _key = v[key]

    if (children && children.length !== 0) {
      _obj[_key] = v
      _obj = {
        ...flatAuthArrToObjByKey(key, children),
        ..._obj,
      }
    } else {
      _obj[_key] = v
    }
  }
  return _obj
}

// 这个方法只是淡出的过滤
export const filterMenus = (menus, allMenusObj) => {
  if (!isArray(menus) || !isObject(allMenusObj)) {
    return []
  }
  return menus.filter((v) => {
    const { children, sn } = v
    if (!children || !children.length) {
      if (allMenusObj[sn]) {
        return true
      }
      return false
    }
    return filterMenus(children, allMenusObj)
  })
}

export const _filterMenuUrls = (menus, authMenusObj) => {
  if (!isArray(menus) || !isObject(authMenusObj)) {
    return []
  }
  let pathWithUrl = []
  for (let v of menus) {
    const { children, sn } = v
    if (!children || !children.length) {
      if (authMenusObj[sn]) {
        if (authMenusObj[sn].url) {
          const nowUrl = {
            sn: sn,
            path: v.path,
            url: authMenusObj[sn].url,
          }
          pathWithUrl = [...pathWithUrl, nowUrl]
        }
      }
      continue
    }
    if (authMenusObj[sn]) {
      const nowUrls = _filterMenuUrls(children, authMenusObj)
      pathWithUrl = [...pathWithUrl, ...nowUrls]
    }
  }
  return pathWithUrl
}

// 这个 不但进行了过滤 还进行了 对象合并
export const _filterMenus = (menus, authMenusObj, pathWithUrl) => {
  if (!isArray(menus) || !isObject(authMenusObj)) {
    return []
  }
  let newMenus = []

  for (let v of menus) {
    const { children, sn } = v
    if (!children || !children.length) {
      if (authMenusObj[sn]) {
        let nowV = {
          ...v,
          ...authMenusObj[sn],
          children: [],
        }
        newMenus = [...newMenus, nowV]
      }
      continue
    }
    if (authMenusObj[sn]) {
      const nowV = {
        ...v,
        ...authMenusObj[sn],
        children: _filterMenus(children, authMenusObj),
      }
      newMenus = [...newMenus, nowV]
    }
  }
  return newMenus
}

export const menu_func_auth = (path, menus) => {
  // 生成以 path 变量 为key 的hash
  const flatPathObj = flatAuthArrToObjByKey('path', menus)
  const { functionMenu } = flatPathObj[path] || {}
  if (!functionMenu || !functionMenu.length) {
    return {}
  }
  return flatAuthArrToObjByCode(functionMenu)
}

// 获取所有菜单权限的ID
export const getAllHasAuthID = (menus) => {
  if (!menus || !menus.length) {
    return []
  }
  return menus.reduce((pre, next) => {
    const { children, id, isAuthorize } = next
    if (!isAuthorize) {
      return pre
    }
    if (!children || !children.length) {
      return [...pre, id]
    }
    return [...pre, id, ...getAllHasAuthID(children)]
  }, [])
}

// 获取所有菜单权限的ID
export const getAllID = (menus) => {
  if (!menus || !menus.length) {
    return []
  }
  return menus.reduce((pre, next) => {
    const { children, id } = next
    if (!children || !children.length) {
      return [...pre, id]
    }
    return [...pre, id, ...getAllHasAuthID(children)]
  }, [])
}

// 获取所有功能权限的ID
export const getAllFuncAuthID = (menus) => {
  if (!menus || !menus.length) {
    return []
  }
  return menus.reduce((pre, next) => {
    const { functionMenu, children } = next
    if (!children || !children.length) {
      if (functionMenu && functionMenu.length) {
        return [...pre, ...getAllHasAuthID(functionMenu)]
      }
      return pre
    }
    return [...pre, ...getAllFuncAuthID(children)]
  }, [])
}

// 获取所有功能权限的ID
export const newGetAllFuncAuthID = (menus, pid) => {
  if (!menus || !menus.length) {
    return []
  }
  return menus.reduce((pre, next) => {
    const { functionMenu, children } = next
    if (!children || !children.length) {
      if (functionMenu && functionMenu.length) {
        return [...pre, ...getAllInitID(functionMenu)]
      }
      return pre
    }
    return [...pre, ...newGetAllFuncAuthID(children)]
  }, [])
}

// getMenuFuncHash 方法是获取最底层菜单的所有功能权限

// 获取所有菜单权限的ID
export const getAllInitID = (menus) => {
  if (!menus || !menus.length) {
    return []
  }
  return menus.reduce((pre, next) => {
    const { children, id, name, enName } = next
    if (!children || !children.length) {
      return [
        ...pre,
        {
          id,
          name,
          enName,
        },
      ]
    }
    return [
      ...pre,
      {
        id,
        name,
        enName,
      },
      ...getAllInitID(children),
    ]
  }, [])
}

// 获取所有菜单有功能权限的ID
export const getAllHasAuthInitID = (menus) => {
  if (!menus || !menus.length) {
    return []
  }
  return menus.reduce((pre, next) => {
    const { children, id, isAuthorize } = next
    if (!children || !children.length) {
      if (isAuthorize) {
        return [...pre, id]
      }
      return pre
    }
    if (isAuthorize) {
      return [...pre, id, ...getAllHasAuthInitID(children)]
    }
    return [...pre, ...getAllHasAuthInitID(children)]
  }, [])
}

// 获取每个菜单对应的功能权限
export const getMenuFuncHash = (arr, obj, hasAuthObj) => {
  let _obj = obj || {}
  let _hasAuthObj = hasAuthObj || {}
  if (!isArray(arr)) {
    return _obj
  }
  for (let v of arr) {
    const { children, functionMenu, id } = v
    if (!children || !children.length) {
      // 处理 functionMenu
      _obj[id] = getAllInitID(functionMenu)
      _hasAuthObj[id] = getAllHasAuthInitID(functionMenu)
    } else {
      getMenuFuncHash(children, _obj, _hasAuthObj)
    }
  }
  return {
    allMenuFuncHash: _obj,
    hasAuthMenuFuncHash: _hasAuthObj,
  }
}

// getMenuHash 方法是获取 多层级菜单下面 所有最底层菜单

export const getFuncMenus = (arr, obj) => {
  return arr.reduce((pre, next) => {
    const { children, id } = next
    if (!children || !children.length) {
      return [...pre, id]
    }
    const now = getFuncMenus(children, obj)
    obj[id] = now
    getMenuHash(children, obj)
    return [...pre, ...now]
  }, [])
}

export const getMenuHash = (arr, obj) => {
  let _obj = obj || {}
  for (let v of arr) {
    const { children, id } = v
    if (children && children.length) {
      _obj[id] = getFuncMenus(children, _obj)
    }
  }
  return _obj
}

// 拿到点击菜单下面所有的子菜单
export const getAllChildMenusHash = (arr = [], obj) => {
  const _obj = obj || {}
  for (let v of arr) {
    const { children, id } = v
    if (children && children.length) {
      _obj[id] = getChild(children, _obj)
    } else {
      _obj[id] = []
    }
  }
  return _obj
}

export const getChild = (arr, obj) => {
  return arr.reduce((pre, next) => {
    const { children, id } = next
    if (!children || !children.length) {
      obj[id] = []
      return [...pre, id]
    }
    const now = getChild(children, obj)
    obj[id] = now
    getAllChildMenusHash(children, obj)
    return [...pre, ...now]
  }, [])
}

// 拿到点击菜单所有的父菜单 ID

export const getAllParentMenusHash = (arr) => {
  // 首先扁平化数组
  if (!arr || !arr.length) {
    return {}
  }
  const flatArrr = flatArrByKey(arr, 'children')
  let _obj = {}
  for (let v of flatArrr) {
    const { parentId, id } = v
    if (parentId === 0) {
      _obj[id] = []
    } else {
      _obj[id] = getParents(flatArrr, parentId)
    }
  }
  return _obj
}

// 数组扁平化
export const flatArrByKey = (arr, key) => {
  return arr.reduce((pre, next) => {
    const child = next[key]
    if (child && child.length) {
      return [...pre, next, ...flatArrByKey(child, key)]
    }
    return [...pre, next]
  }, [])
}

export const getParents = (flatArrr, parentId) => {
  return flatArrr.reduce((pre, next) => {
    if (next.id === parentId) {
      return [...pre, next.id]
    }
    return pre
  }, [])
}

export const addAuthForAllData = (data) => {
  return data.map((address) => {
    const { buildingWithFloors } = address
    return {
      ...address,
      buildingWithFloors: buildingWithFloors.map((v) => {
        const { floors } = v
        if (floors && floors.length) {
          const _floors = floors.map((value) => {
            return {
              ...value,
              isAuthorize: true,
            }
          })
          return {
            ...v,
            floors: _floors,
          }
        }
        return v
      }),
    }
  })
}

// 寻找菜单的所有同级兄弟菜单
export const getMenuBrothers = (arr, obj) => {
  let _obj = obj || {}
  for (let v of arr) {
    const { children, id } = v
    _obj[id] = getArr(arr, id)
    if (children && children.length) {
      getMenuBrothers(children, _obj)
    }
  }

  return _obj
}

export const getArr = (arr, id) => {
  return arr
    .reduce((pre, next) => {
      return [...pre, next.id]
    }, [])
    .filter((v) => v !== id)
}

// 把 树形数组 进行 一固定的key 进行  hash
export const hashArrToObjByKey = (arr, key, childrenKey = 'children') => {
  let _obj = {}
  if (!isArray(arr)) {
    return _obj
  }
  for (let v of arr) {
    const children = v[childrenKey]
    const _key = v[key]
    if (children && children.length !== 0) {
      _obj[_key] = v
      _obj = {
        ...hashArrToObjByKey(children, key),
        ..._obj,
      }
    } else {
      _obj[_key] = v
    }
  }
  return _obj
}

// 获取 hash 对象里面 节点的父节点数组
export const getHashParentArr = (hash, parentKey) => {
  const getPar = (hash, pid) => {
    let _pid = pid
    const arr = []

    while (hash[_pid]) {
      arr.push(_pid)
      _pid = hash[_pid][parentKey]
    }
    return arr
  }

  // const getPar = (hash, pid, arr) => {
  //   let _arr = arr || []
  //   if (hash[pid]) {
  //     _arr.push(pid)
  //     getPar(hash, hash[pid], _arr)
  //   }
  //   return _arr
  // }
  const obj = {}
  for (let key in hash) {
    const value = hash[key]
    const parentPath = value[parentKey]
    if (!parentPath) {
      obj[key] = [value.path] // 如果没 父菜单 ，就高亮自己
    } else {
      obj[key] = getPar(hash, parentPath)
    }
  }

  return obj
}
