/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
// import facilityStatus from './equipment/facility-status/en-US'

export default {
  MANAGE_WORK_LOCATION: '{location}',
  MANAGE_BUILDINGID: 'Building ID',
  MANAGE_BUILDINGNAME: 'Building',
  MANAGE_BUILDINGNAME_STATUS: 'abnormal',
  MANAGE_BUILDINGNAME_STATUS_MESSAGE: 'The building has been removed in a third party',
  MANAGE_LOCATION: 'Location',
  MANAGE_SOURCE: 'Resource',
  MANAGE_SOURCE_FROM_LOCAL: 'Local',
  MANAGE_SOURCE_FROM_PARTY: 'The third party',
  MANAGE_FLOOR: 'Floor',
  MANAGE_DESK_TOTAL: 'Desk Amounts',
  MANAGE_HOT_DESK_TOTAL: 'Hot Desks',
  MANAGE_FIXED_DESK_TOTAL: 'Fixed Desks',
  MANAGE_DISABLED_DESK_TOTAL: 'Disabled Desks',
  MANAGE_DESK_DEPAREMENT_SELECT: 'Desk department',
  MANAGE_STAFF_DEPAREMENT_TAG: 'Desk Tag',
  MANAGE_DESK_TYPE_SELECT: 'Desk type',
  MANAGE_DESK_STATUS: 'Status',
  MANAGE_DESK_SEARCH_PLACEHOLDER: 'Search deskname、username、workid',
  MANAGE_DESK_SEARCH_PLACEHOLDER2: 'Search deskname',
  MANAGE_ADD_DESK: 'Add Desk',
  MANAGE_OPERATION: 'Operation',
  MANAGE_DOWN_DATA: 'Export This Floor',
  MANAGE_DESK_NAME: 'Desk Name',
  MANAGE_DESK_TAG: 'Desk Tag',
  MANAGE_DESK_TYPE: 'Desk Type',
  MANAGE_DESK_DEPAREMENT: 'Desk Dept',
  MANAGE_EDIT: 'Edit',
  MANAGE_DESK_INFORMATION: 'Desk information',
  MANAGE_DESKID: 'Desk ID',
  MANAGE_BASIC_INFO: 'Basic info',
  MANAGE_KEY_INFO: 'Key info',
  MANAGE_INTERNAL_STAFF: 'Internal Staff',
  MANAGE_STAFF_TYPE: 'Staff Type',
  MANAGE_EXTERNAL_TRAINEE: 'External/Trainee',
  MANAGE_EXTERNAL_OUTSOURCING: 'External/Outsourcing',
  MANAGE_EXTERNAL_OTHER: 'External/Others',
  MANAGE_STAFF_NAME: 'Name',
  MANAGE_STAFF_DEPAREMENT: 'Staff Dept',
  MANAGE_INTERFACER: 'Space Manager',
  MANAGE_BUTTON_EDIT: 'Edit',
  MANAGE_BUTTON_CANCEL: 'Cancel',
  MANAGE_BUTTON_CONFIRM: 'Confirm',
  MANAGE_BUTTON_ENTER: 'Enter',
  MANAGE_EDIT_BUILDING_AND_FLOOR: 'Edit building/floor',
  MANAGE_EDIT_BUILDING: 'Edit Building And Floor',
  MANAGE_ADD_BUILDING_AND_FLOOR: 'Add building/floor',
  MANAGE_AT_LOCATION: '{location}',
  MANAGE_COUNTRY: 'Country',
  MANAGE_PROVINCE: 'Province',
  MANAGE_CITY: 'City',
  MANAGE_REMARK: 'Remark',
  MANAGE_BUILDINGNAME_REPEATED: "BuildingName cann't be repeated",
  MANAGE_BUILDINGNAME_PLACEHOLDER: 'Please enter the building name',
  MANAGE_LOCATION_PLACEHOLDER: '{location}, such as Shanghai, China',
  MANAGE_REMARK_PLACEHOLDER: 'Please enter the remark',
  MANAGE_SAVE_SUCCESS: 'Saved successfully',
  MANAGE_LIST_TOTAL: 'total ',
  MANAGE_LIST_UNIT: ' strip',
  MANAGE_DOWN_DATAS: 'Export All Floors',
  MANAGE_DOWN_DATAS_LIST: 'Desks List',
  MANAGE_EDIT_FLOOR: 'Edit Floor',
  MANAGE_BOOKING_RULE: 'Booking Rule',
  MANAGE_BOOKING_RULE_TIP: 'The rule applies to all fixed desks on this floor',
  MANAGE_BOOKING_RULE_EMPTY: "Booking rule cann't  be empty",
  MANAGE_INPUT_MAX_LENGTH_50: 'At most 50  characters',
  MANAGE_INPUT_MAX_LENGTH_100: 'At most 100  characters',
  MANAGE_INPUT_MAX_LENGTH: 'At most {max} characters',
  MANAGE_BUTTON_DETAIL: 'Detail',
  MANAGE_BUILDING_DETAIL: 'Building Detail',
  MANAGE_FLOOR_DETAIL: 'Floor Detail',
  MANAGE_RULE_INFO: 'Rule',
  MANAGE_RULE_INFORMATION: 'Rule Information',
  MANAGE_CONFIG_INFO: 'Config info',
  MANAGE_RULE_PREVIEW: 'Preview',
  MANAGE_RULE_OPEN: 'On',
  MANAGE_RULE_CLOSE: 'Off',
  MANAGE_RULE_BY_HOUR: 'By the hours',
  MANAGE_RULE_BY_HALF_DAY: 'By a half-day',
  MANAGE_RULE_BY_DAY: 'By a day',
  MANAGE_RULE_UNIT_DAY: 'day',
  MANAGE_RULE_UNIT_DAYS: 'days',
  MANAGE_ADD_DESKS: 'Add Desk',
  MANAGE_CANTNOT_ASSOGMED: 'Cannot be assigned',
  MANAGE_DISABLE: 'disable',
  MANAGE_TO_ASSIGN: 'Assign',
  MANAGE_CLEAR_STAFF: 'Clear staff',
  MANAGE_DESK_ASSIGNED: 'Desk Assigned',
  MANAGE_DESK_UNASSIGNED: 'Desk Unassigned',
  MANAGE_DESK_LIST: 'Qrcode',
  MANAGE_DESK_STATUS_DISPLAY: 'Desk Status',
  MANAGE_SELECTED_DESCRIPTION: '{total} tag selected',
  MANAGE_SELECTED_DESCRIPTIONS: '{total} tags selected',
  MANAGE_TAGS_COUNT: '{total}',
  MANAGE_TOTAL_BUILDING: 'Total {total} buildings',
  // MANAGE_TOTAL_BUILDING: 'Total {total, plural, one {# building} other {# buildings}}</bold>',
  MANAGE_DESK_DEPT: 'Binded to Other Department',
  MANAGE_QR_CORD: 'QR Code',
  MANAGE_LIST: 'Desks List',
  MANAGE_LIST_TITLE: 'Desk_List',
  MANAGE_DOWN: 'Download',
  MANAGE_DOWN_TEXT1: 'Put them on the table',
  MANAGE_DOWN_TEXT2: '- if the qr code is shared,the employees can scan to book hot desks',
  MANAGE_DOWN_TEXT3: '- if the qr code is fixed,the employee can scan to book view/bind/unbind fixed desks',
  MANAGE_SET_STATUS: 'Set Desk Type',
  MANAGE_TIME_ZONE: 'Time zone',
  MANAGE_SET_ATTRIBUTE_PLACEHOLD: 'Please select the desk type',
  MANAGE_SET_ATTRIBUTE_HAS_DEPT1:
    'There is 1 desk assigned to staff or departments.If change the desktype, assigned information will be clean',
  MANAGE_SET_ATTRIBUTE_HAS_DEPT2:
    'There are {deskTotal} desks assigned to staff or departments. If change the desktype, assigned information will be clean',
  MANAGE_EDIT_DESK: 'Edit Desk',
  MANAGE_CAN_BOOK: 'The Desk Can be Booked',
  MANAGE_DISABLED: 'Disable',
  MANAGE_CLOSE_BOOKING: 'Closed Booking',
  MANAGE_OPEN_BOOKING: 'Open Booking',
  MANAGE_NO_ONE_CAN: 'No one can book',
  MANAGE_ONLY_MANAGE: 'Only superadmin、admin can book',
  MANAGE_ALLOW_STAFF: 'Allow staffs to book online',
  MANAGE_AUTHORITY: 'Authority',
  MANAGE_ALL_AVAILABLE: 'Available to All',
  MANAGE_CUSTOM: 'Available to Part',
  MANAGE_AVAILABLER_TIME: 'Available booking scope in advance',
  MANAGE_CAN_BOOK_ACROSS: 'Can book across the day',
  MANAGE_AVAILABLER_MORE_TIME: 'Desk can be booked up to {value} days in advance',
  MANAGE_MORE_TIME: 'Book multiple days of desk at a time',
  MANAGE_CANT_MORE_TIME: 'Cannot book for more than one day at a time',
  MANAGE_ONLY_TODAY: 'Only same-day reservations are allowed',
  MANAGE_TODAY_TOMOTTOW: 'You can book a desk today and tomorrow.',
  MANAGE_BIND_RULE: 'Binding Rule',
  MANAGE_BIND_RULE_INFO: 'Rule',
  MANAGE_EDIT_IN_FLOOR: 'Please set the rules on the floor where the desk is located',
  MANAGE_PREVIEW: 'Preview',
  MANAGE_SET_RULE: 'Set Hot Desks Rules',
  MANAGE_BOOKING_WAY: 'Method',
  MANAGE_BOOK_HOUR: 'Book by hours',
  MANAGE_BOOK_HALF_DAY: 'Book by half-day',
  MANAGE_BOOK_ALL_DAY: 'Book by day',
  MANAGE_DAILY: 'Daily (Monday to Sunday)',
  MANAGE_WORKING: 'Working days (Monday to Friday)',
  MANAGE_AM: 'AM.',
  MANAGE_PM: 'PM.',
  MANAGE_BEGIN: 'Start',
  MANAGE_END: 'End',
  MANAGE_CAN_BOOK_TIME: 'Bookable hours',
  MANAGE_SEARCH_NAME_PLH: 'Search staff name',
  MANAGE_ITEM_SEL_COUNT_SINGULAR: '{total} item selected',
  MANAGE_ITEM_SEL_COUNT_PLURAL: '{total} items selected',
  MANAGE_SHARED_DESK_SEL_COUNT_SINGULAR: '{total} item selected',
  MANAGE_SHARED_DESK_SEL_COUNT_PLURAL: '{total} items selected',
  MANAGE_ONLY: 'Only',
  MANAGE_ABLE_TO_BOOK: 'can book',
  MANAGE_BOOKING_BY_HOUR_DES: 'When choose booking by hours, duration will be 15 minutes in the filter box.',
  MANAGE_BOOKING_BY_HALF_DAY_DES:
    'When choose booking by half-day, duration will be “morning” or “afternoon” in the time filter box.',
  MANAGE_BOOKING_BY_FULL_DAY_DES: 'When choose booking by a day, you can only choose date in the time filter box.',
  MANAGE_SEL_DEPT_STAFF: 'Select Dept. and Staff',
  MANAGE_BOOKABLE_USERS: 'Bookable users',
  MANAGE_SPECIFIC_DEPT_STAFF: 'Specific Dept. and Staff',
  CUSTOM_RULE: 'Custom Rule',
  CAN_BOOK_IN_ADVANCE: 'Can be booked in advance',
  BOOK_DESK_OF_CURRENT_DAY: 'Only {time} same-day reservations are allowed',
  IMPORT_BUILDING_AND_FLOOR: 'Import',
  IMPORT_BUILDING_AND_FLOOR_FILE_NAME: 'Building Floor - Import.xlsx',
  CONFIRM_DEL_BUILDING_TIP: 'Are you sure you want to delete the building 【{name}】?',
  CONFIRM_DEL_FLOOR_TIP: 'Are you sure you want to delete the floor 【{name}】?',
  CONFIRM_DEL_DESK_TIP: 'Are you sure you want to delete the desk 【{name}】?',
  MANAGE_BUILDING_HAS_FLOOR_OR_BUILDING:
    'Some Floor or Desk have been created under the building.Please be careful when deleting data that cannot be found.',
  MANAGE_DELETE_BUILDING_NEED_INPUT_DOWN: 'Please enter “{name}” below to confirm the operation.',
  MANAGE_DELETE_BUILDING_NEED_INPUT: 'Please enter “{name}” to confirm the operation.',
  MANAGE_INPUT_ERROR: 'Wrong character entered, please re-enter.',
  MANAGE_NOT_DELETE_BUILDING: 'You cannot delete the building 【{name}】.',
  MANAGE_NOT_DELETE_BUILDING_BECAUSE_IT_HAS_MAP: 'The building is bound to the map and cannot be deleted.',
  CONFIRM_DELETE: 'Confirm',
  ADD_BUILDING_AND_FLOOR: '+ Add Building/Floor',
  EDIT_BUILDING_AND_FLOOR: 'Edit Building/Floor',
  ADD_FLOOR: 'Add floor',
  BLDG_HINT_FLOOR_FORMAT: "Choose naming rule for floor according to user's usage, for example, F1 or L1.",
  BLDG_INTERLAYER: 'Sandwich',
  BLDG_NOT_INTERLAYER: 'Not sandwich',
  BLDG_FLOOR_NUM_PLH: 'Number',
  BLDG_FLOOR_NAME_PLH: 'Floor',
  BLDG_FLOOR_ATTR: 'FloorType',
  BLDG_FLOOR_AREA: 'Area',
  BLDG_FLOOR_NUM: 'Number',
  BLDG_FLOOR_NAME: 'Floor',
  // validate
  BLDG_NOT_EDITED: 'Editing not completed',
  BLDG_NEC_ITEM_MISSING: 'Required fields not completed',
  BLDG_FLOOR_DUPL: 'When the same FloorType, Number can not be repeated.',
  BLDG_FLOOR_MISSING: 'Please add the floor',
  BLDG_PLS_INPUT_STH: 'Please enter {text}',
  BUILDING_INFO: 'Floor Information',
  BLDG_ADDED_FLOORS: 'Floor',
  MANAGE_PLEASE_ADDRESS_NAME: 'Please enter the {location}.',

  MANAGE_CANT_DELETE_BUILDING: 'You cannot delete the building 【{name}】.',
  MANAGE_CANT_DELETE_FLOOR: 'You cannot delete the floor 【{name}】.',
  MANAGE_DELETE_DESK: 'Delete Desk',
  MANAGE_SELECTED_DESK_DELETE: '{count} Desks are selected, are you sure about batch deletion?',
  MANAGE_DESK_HAS_MAP_TIPS:
    'There are {count} Desks associated with the map，please be careful when deleting data that cannot be found.',

  LICENSE_NUMBER_USED: '{value} licenses are available(Total {total})',
  MEETING_ROOM_NOT_AUTHRIZED: 'After License is withdrawn: Desk cannot be assigned，binded or reserved.',
  MANAGE_HAS_MAP_CANT_MODIFY_NUM: 'The floor is bound to the map and cannot change floor number.',
  MANAGE_HAS_MAP_CANT_DELETE: 'The floor is bound to the map and cannot be deleted.',
  LICENSE_SELECT_FIX_DESK: 'Total selected：{count} Hot Desks',
  LICENSE_SELECT_SHARE_DESK: 'Total selected：{count} Fix Desks',
  LICENSE_NUMBER_SHARE_DESKING_ROOM_USED: '{value} Hot Desk License available（Total {total}）',
  LICENSE_NUMBER_FIX_DESKING_ROOM_USED: '{value} Fixed Desk License available（Total {total}）',
  DISTRIBUTE_SPACE_LICENSE_TIPS:
    'After License is assigned：Fixed Desk can be assigned or be binded；Hot Desk can be reserved.',
  CLOSE_SPACE_LICENSE: 'Withdraw License',
  CLOSE_SPACE_LICENSE_TIPS: 'The Desk cannot be assigned or reserved without authorization',
  DISTRIBUTE_SPACE_LICENSE: 'Assign License',
  SELECTED_SPACES_COUNT: '{total} meeting rooms selected',
  SPACE_BATCH_MANDATE_LICENSE: 'Bulk Licensing',
  MANAGE_LICENSE: 'License',
  MANAGE_EXPORT_ALL_BUILDING_DESKS: 'Export the Desk list of all buildings',
  BLDG_HINT_MDF_FLOOR: 'Confirm to change the building message?',
  BLDG_HINT_MDF_FLOOR_DES:
    'Some Desk have been created under the floor.Desk and Desk data will be bound to the changed floor number.',
  BLDG_HINT_DEL_FLOOR: 'Do you want to delete this floor?',
  MANAGE_SEARCH_BUILDING_KEYWORDS: 'Keywords：Building，{location}',
  MANAGE_HAS_DESK_WHEN_DELETE_TIPS:
    'Some Desk have been created under the floor.Please be careful when deleting data that cannot be found.',
  MANAGE_DELETE_DATA_TIPS: 'Please be careful when deleting data that cannot be found.',
  MANAGE_DESK_RELATIVE_MAP_TIPS:
    'The Desk is associated with a map.Please be careful when deleting data that cannot be found.',
  LICENSE_COUNT_DEFAULT_TIPS: 'The number of licenses is abnormal, please adjust the license allocation. ',
  SUBSCRIPTION_INFORMATION: 'Subscription Information',
  FIXED_DESK_LICENSE: 'Fixed Desk License',
  HOT_DESK_LICENSE: 'Hot Desk License',
  SERVICE_PERIOD_OF_FIXED_DESK: 'Service Period of Fixed Desk',
  HOT_DSK_SERVICE_PERIOD: 'Service Period of Hot Desk',
  REMAINING_AVAILABLE: 'Remaining available',
  REMAINING_NOT_AVAILABLE: 'Remaining not available',
  REMAINING_DAYS: 'Remaining days',
  OVERDUE_DAYS: 'overdue days',
  GE_UNIT: ' ',
  FIXED_DESK_PURCHASED_NUMBER: 'Number of fixed Desk purchased：{val}',
  FIXED_DESK_AUTHORIZED_NUMBER: 'Number of fixed Desk authorized：{val}',
  HOT_DESK_PURCHASED_NUMBER: 'Number of Hot Desk purchased：{val}',
  HOT_DESK_AUTHORIZED_NUMBER: 'Number of Hot Desk authorized：{val}',
  LICENSE_START_DATE: 'Start date: {val}',
  LICENSE_END_DATE: 'End date: {val}',
  SUBSCRIPTION_HISTORY: 'Subscription History',
  CREATION_TIME: 'Creation time',
  LICENSE_NUMBER: 'License number',
  SERVICE_PERIOD: 'Service Period',
  SERVICE_STATUS: 'Service Status',
  EXPIRY_DATE: 'Expiry date',
  LICENSE_NOT_STARTED: 'Not started',
  LICENSE_IN_SERVICE: 'In Service',
  LICENSE_FAIL: 'Fail',
  PAID_NOTICE: 'Paid Notice',
  PAID_NOTICE_INFO:
    'Email Notice will be sent to the following members 30 days before, 15 days before, 7 days before and the last 3 days before the expiration date of the product License.',
  SEARCH_MEMBER: 'Search member',
  NO_NOTIFICATION_MAILBOX: 'No notification mailbox',
  LICENSE_ENTER_ADDRESS: 'Please enter email address',
  SERVICE_EXPIRATION_NOTICE: 'Service Expiration Notice',
  CONTACT_AFTERMARKET: 'You can contact the aftermarket for offline renewals.',
  NOTIFY_USER: 'Notify the user',
  UNAUTHORIZED: 'Unauthorized',
  AUTHORIZED: 'Authorized'
}
