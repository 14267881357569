import { message } from 'antd'
import axios from 'axios'
import queryString from 'query-string'
import { API_HOST, THIRD_HOST } from '../../config'
import router from 'umi/router'
import {
  clearIEDataCache,
  getAuthHeaders,
  getTimeHeader,
  getLocalHostname,
  getLanguageHeader,
  getPlatformHeader,
  removeAuthToken,
  clearAllCookie
} from '../../helpers/storage'
import { dealwithTokenExp } from './dealwithTokenExp'
import { redirectTo } from '../../helpers/view'

let showMessageControl = true
const BOOKING_ERR_CODES = [205, 206, 207, 208, 209] // 工位预订相关错误码
const goToLogin = () => {
  const { href, origin, search } = window.location
  const currentUrl = href.slice(origin.length)
  const query = queryString.parse(search)
  redirectTo('/login', query.from ? query.from : currentUrl)
}

function createAPI(host) {
  const API = axios.create({
    baseURL: host
  })
  API.interceptors.request.use(config => {
    // 这里将put,delete请求方法后面拼接_method是因为有部分客户服务器，不支持put、delete请求方式.
    // if (config.method === 'put' || config.method === 'delete') {
    //   if (!isEmpty(config.params) || config.url.match(/\?/g)) {
    //     config.url = config.url + `&_method=${config.method}`
    //   } else {
    //     config.url = config.url + `?_method=${config.method}`
    //   }
    // }
    Object.assign(
      config.headers,
      getAuthHeaders(),
      clearIEDataCache(),
      getLocalHostname(),
      getTimeHeader(),
      getLanguageHeader(),
      getPlatformHeader()
    )
    return config
  })
  const goLogin = [9999, 9998, 9997, 9996]
  const licenseCode = [1013, 1014]
  API.interceptors.response.use(
    response => {
      const { errorCode, message: msg } = response.data
      const { location } = window
      const { href, origin } = location
      const currentUrl = href.slice(origin.length)
      if (BOOKING_ERR_CODES.includes(errorCode)) {
        // 特殊情况，不拦截，交给页面处理相关逻辑
        return response.data
      }
      if (goLogin.includes(errorCode)) {
        dealwithTokenExp({
          currentUrl,
          response,
          showMessageControl
        })
        clearAllCookie()
        removeAuthToken()
        showMessageControl = false
        router.push('/login')
      } else if (errorCode === 200) {
        showMessageControl = true
        msg && message.success(msg)
      } else if ([201, 400].includes(errorCode)) {
        msg && message.warning(msg)
      } else if (errorCode === 202) {
        msg && message.error(msg)
      } else if (errorCode === 20) {
        // 未授权
        msg && message.warning(msg)
        router.push('/404')
      } else if (errorCode === 9995) {
        showMessageControl && msg && message.error(msg)
        showMessageControl = false
        goToLogin()
      } else if (licenseCode.includes(errorCode)) {
        // 许可证异常，不提示错误信息
        removeAuthToken()
        if (!location.pathname.endsWith('login')) {
          // 不在登录页需要给个提示
          msg && message.warning(msg)
          router.push('/login')
        }
        return response.data
      } else {
        msg && message.error(msg.message || msg)
      }
      return response.data
    },
    error => {
      if (axios.isCancel(error)) {
        return Promise.reject({})
      } else {
        // handle error code
      }
      return Promise.reject(error)
    }
  )
  return API
}

export const BOOKINGAPI = createAPI(API_HOST)
export const THIRDAPI = createAPI(THIRD_HOST)
export const LOCALAPI = createAPI('http://localhost:8080')
