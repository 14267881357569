import './helpers/polyfill'
import 'raf/polyfill'

import './assets/dfocus-desking-iconfont/iconfont.js'
import './assets/dfocus-desking-iconfont/iconfont.less'

import pkg from '../package.json'

Object.setPrototypeOf = require('setprototypeof')

window.version = pkg.version
