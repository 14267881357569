import { withMixin } from '../helpers/dva'

export default withMixin({
  state: {
    currentUser: null,
    currentAuths: null,
    locationAlias: '园区'
  },

  effects: {},
  reducers: {}
})
