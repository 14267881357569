export default {
  CS_BASIC_INFO: 'Basic Information',
  CS_CONTACT_INFO: 'Contact Information',
  CS_CHANGE_LOGO: 'Change Logo',
  CS_LOGO_RECOMMENDED: 'Support jpg, jpeg, png format files, no more than 1M，recommended size is 215px*90px',
  CS_UNDER_ONE_HUNDRED_PEOPLE: 'Up to 100 people',
  CS_UNDER_ONE_THOUSAND: '100-999 people',
  CS_UNDER_TEN_THOUSAND: '1000-9999 people',
  CS_MORE_THAN_TEN_THOUSAND: '10000 and above',
  CS_PLEASE_INPUT_COMPANY_NAME: 'Import Company Name',
  CS_NO_EMPTY_OF_COMPANY_NAME: 'Company Name cannot be empty',
  CS_PLEASE_SELECT_COMPANY_SIZE: 'Import Company Size',
  CS_PLEASE_INPUT_CONTACT_PEOPLE_NAME: 'Enter Contact Name',
  CS_NO_EMPTY_OF_CONTACT_NAME: 'Contact name cannot be empty',
  CS_PLEASE_INPUT_CONTACT_PEOPLE_EMAIL: 'Enter Contact email',
  CS_EMAIL_INVALID: 'Please enter the correct email format',
  CS_NO_EMPTY_OF_CONTACT_EMAIL: 'Contact email cannot be empty',
  CS_PLEASE_INPUT_PHONE_NUMBER: 'Enter Contact phone number',
  CS_NO_EMPTY_OF_CONTACT_PHONE_NUMBER: 'Contact phone number cannot be empty',
  CS_PLEASE_INPUT_CORRECT_PHONE_NUMBER: 'Please fill in the correct phone number',
  CS_PLEASE_INPUT_COMPANY_ADDRESS: 'Enter Company Address',
  CS_NO_EMPTY_OF_COMPANY_ADDRESS: 'Company Address cannot be empty',
  CS_PLEASE_INPUT_POSTALCODE: 'Enter Company postal code',
  CS_PLEASE_INPUT_REMARK: 'Enter Note information',
  CS_COMPANY_NAME: 'Company Name',
  CS_COMPANY_SIZE: 'Company size',
  CS_COMPANY_ADDRESS: 'Company Address',
  CS_COMPANY_POSTALCODE: 'Company postal code',
  CS_CONTACT_PEOPLE_NAME: 'Contact Name',
  CS_CONTACT_PEOPLE_EMAIL: 'Contact email',
  CS_CONTACT_PEOPLE_PHONE_NUMBER: 'Contact phone number',
  CS_REMARK: 'Note',
  CS_NO: '暂无',
  CS_NO_MORE_CHARS: 'Cannot exceed {count} characters',
  CS_UPLOAD_SUCCESS: 'Upload successfully',
  CS_LOGO_OVER_SIZE: 'Image size cannot exceed 1M'
}
