import { BOOKINGAPI, THIRDAPI } from '../../../helpers/api'

export function getUser() {
  return BOOKINGAPI.get('/login/user')
}

export function getTenantConfig(params) {
  return BOOKINGAPI.get('/tenant/config', { params })
}

export function getThirdInfo(tenantId) {
  return THIRDAPI.get(`/third/${tenantId}`)
}

export function loginDingtalk(params) {
  return THIRDAPI.post('/dingtalk/login', params)
}

export function anyongLogin(params) {
  return THIRDAPI.get('/anyong/login', { params })
}
