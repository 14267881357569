/* eslint-disable prettier/prettier */
export default {
  THIRD_ACCOUNT_IN: '第三方账号集成',
  THIRD_BINDED_TIPS: '绑定后将同步第三方平台的人员名单和组织架构，并允许成员使用第三方账号登录本系统。',
  THIRD_USE_BOOK: '使用手册',
  THIRD_HAND_ASYNC: '手动同步',
  THIRD_VIEW_CONFIG: '查看配置',
  THIRD_BIND: '绑定',
  THIRD_UNBIND: '解绑',
  THIRD_CONNECTED: '已连接',
  THIRD_VIEW_TYPE: '查看{type}配置',
  THIRD_CONFIRM_UNBIND_TYPE: '确定要解绑【{type}】吗？',
  THIRD_UNBIND_TYPE: '解绑{type}',
  THIRD_BIND_TYPE: '绑定{type}',
  THIRD_UNBIND_TIPS_TYPE:
    '一旦解绑已同步组织机构/成员将会被删除，同时不再继续与{type}同步组织架构/成员，不可使用{type}扫码登录系统',
  THIRD_ORGANIZATION_SYNC: '当前企业中有{number}位成员',
  THIRD_NEXT: '下一步',
  THIRD_SYNCING: '正在同步中',
  THIRD_DINGTALK: '钉钉',
  THIRD_C_WECHAT: '企业微信',
  THIRD_LARK: '飞书',
  THIRD_LOCAL: '本地',
  THIRD_OTHERS: '其他',
  THIRD_LDAP: 'LDAP',
  THIRD_SYNC_LOADING: '组织架构同步中, 请30秒后刷新本页面查看',
  THIRD_UNBIND_SUCCESS: '解绑成功',
  THIRD_BIND_SUCCESS: '绑定成功',

  // dialog
  THIRD_CONFIGURE_COMPANY_INFO: '配置企业信息',
  THIRD_INPUT_COMPANY_ID_TIPS: '填写企业 ID (位于“ 我的企业-企业信息”最下方)',
  THIRD_CREATE_APP_POSITION: '创建自建应用（位于“应用管理” 最下方），填写 AgentId 和 Secret',
  THIRD_CLICK_STEP1: '1.点击“创建应用”，设置应用的 Logo、名称和可见范围，完成创建；',
  THIRD_CLICK_STEP2: '2.完成创建后，系统会且只会同步可见范围里的成员名单和组织结构；',
  THIRD_CLICK_STEP3: '3.创建完成后，在应用详情页可以看到 AgentId 和 Secret。',
  THIRD_CONFIGURE_MY_APP: '配置自建应用',
  THIRD_OPEN_PATH_TIPS: '打开“功能-工作台应用主页”卡片里的“设置应用主页”',
  THIRD_APP_MAIN_PAGE: '应用主页',
  THIRD_COPY: '复制',
  THIRD_APP_DETAIL_PATH_TIPS: '应用详情页，打开“开发者接口-网页授权及JS-SDk”卡片里的“设置可信域名”',
  THIRD_TRUST_DOMAIN: '可信域名',
  THIRD_WEB_REDIRECT_DOMAIN: '应用详情页，打开“开发者接口-企业微信授权登录”卡片里的“设置”，填写Web网页的回调域',
  THIRD_AUTH_REDIRECT_DOMAIN: '授权回调域',
  THIRD_CONFIGURE_PEOPLE_SYNC: '配置通讯录同步',
  THIRD_SYNC_MANAGE_STEP1: '1.打开顶部导航栏的“管理工具”页面，然后打开“通讯录同步”',
  THIRD_SYNC_MANAGE_STEP2: '2.点击“开启API接口同步”，打开同步开关',
  THIRD_SYNC_MANAGE_STEP3: '3.设置接收事件服务器，将以下信息复制到对应表单之内',
  THIRD_CONFIRM_UNBIND: '确认解绑',
  THIRD_DEPARTMENT_ATTRIBUTE: '部门属性',

  // 飞书
  THIRD_CONFIGURE_APPLICATION_INFO: '配置应用信息',
  THIRD_CREATE_LARK_POSITION: '创建自建应用（位于「我的应用」tab中），填写App ID 、App Secret 和 App Name',
  THIRD_LARK_STEP_1: '1.登录「飞书开发者后台」，点击「创建应用」；',
  THIRD_LARK_STEP_2: '2.选择应用类型为「企业自建应用」，填入应用名称、应用副标题并上传应用图标，完成创建；',
  THIRD_LARK_STEP_3: '3.创建完成后，找到应用并进入应用详情页-「凭证与基础信息」，可以看到找到 App ID 和 App Secret。',

  THIRD_LARK_NEXT_1:
    '1.点击应用进入「应用详情页」，点击侧边栏的「应用功能-网页」，开启网页，并填入移动端和桌面端主页的地址',
  THIRD_DESKTOP_MAIN_PAGE: '桌面端主页',
  THIRD_MOBILE_MAIN_PAGE: '移动端主页',
  THIRD_LARK_NEXT_2: '2.点击进入「安全设置」页，添加重定向URL 和 H5可信域名',
  THIRD_LARK_H5_DOMAIN: 'H5 可信域名',
  THIRD_LARK_WEB_URL: '桌面端重定向URL',
  THIRD_LARK_H5_URL: '移动端重定向URL',
  THIRD_LARK_REQUEST_URL: '请求网址URL',
  THIRD_LARK_NEXT_3: '3.点击进入「事件订阅」页，填入Encrypt Key、Verification Token和请求网址',
  THIRD_LARK_NEXT_4:
    '4.点击进入「权限管理」页面，在「选择所需的权限」中选择“获取用户邮箱”、“获取用户userid”、 “获取用户基本信息”、“通过手机号或者邮箱获取用户ID”、“以应用身份访问通讯录”和“获取部门组织架构信息”6种权限。',
  THIRD_LARK_NEXT_5:
    '5.点击进入「版本管理与发布」页面，点击「创建版本」，填入相关信息，提交后等待企业管理员审核，审核通过后会在开发者后台通知。',

  // form
  TIP_HEADER_INPUT: '请输入',
  CREATE_CONFIG: '创建',
  CONFIRM_DISCONNECT_CONFIG: '确定要断开此配置吗？',
  CONFIRM_DELETE_CONFIG: '确定要移除此配置吗？',
  CONFIRM_DELETE_CALENDAR_CONFIG:
    '您正在移除第三方日历配置，此操作将会同时关闭所有会议室的第三方日历同步功能，并且删除已同步的所有(将来的)会议。',
  RM_CALENDAR_CONFIG_TIP: '请在下方输入框中输入“{code}”以确认',
  SELECT_VERSION: '选择版本',
  PLEASE_SELECT_VERSION: '请选择版本',
  SELECT_THIRDPART: '选择第三方',
  PLEASE_SELECT_THIRDPART: '请选择第三方',
  CONFIG_NAME: '名称',
  PLEASE_INPUT_CONFIG_ACCOUNT: '请输入账号名称',
  PLEASE_INPUT_CONFIG_NAME: '请输入名称',
  EWS_LOCATION: 'EWS地址',
  PLEASE_INPUT_EWS_LOCATION: '请输入Exchange服务的EWS地址',
  EXCHANGE_NAEM: '服务号名称',
  PLEASE_INPUT_EXCHANGE_NAEM: '请输入服务号名称',
  EXCHANGE_NAEM_ERROR: '名称不能包含特殊字符',
  EXCHANGE_PASSWORD: '服务号密码',
  PLEASE_INPUT_EXCHANGE_PASSWORD: '请输入服务号密码',
  INTER_SERVICE_CONFIG: '服务器配置',
  LDAP_FORM_TYPE: '目录名称',
  PLEASE_SELECT_LDAP_FORM_TYPE: '请选择目录命名',
  LDAP_HOST_URL: '主机名',
  LDAP_HOST_URL_PLACEHOLDER: '运行LDAP的服务器主机名。例如：ldap.example.com',
  PLEASE_INPUT_LDAP_HOST_URL: '请输入主机名',
  LDAP_PORT: '端口',
  LDAP_PORT_ERROR: '端口号只能为数字',
  PLEASE_INPUT_LDAP_PORT: '请输入端口',
  LDAP_BASE_DN: '基本DN',
  LDAP_BASE_DN_PLACEHOLDER: '从LDAP根节点搜索用户和组。例如：cn=users,dc=example,dc=com',
  PLEASE_INPUT_LDAP_BASE_DN: '请输入基础域',
  LDAP_USER_NAME: '用户名',
  LDAP_USER_NAME_PLACEHOLDER: '用户登录到LDAP。例子：user@domain.name 或 cn = 用户，dc = 域，dc = 名称',
  PLEASE_INPUT_LDAP_USER_NAME: '请输入用户名',
  LDAP_USER_PASSWORD: '密码',
  PLEASE_INPUT_LDAP_USER_PASSWORD: '请输入密码',
  LDAP_MODEL_SETTING: '用户模式设置',
  LDAP_OBJECT_CLASS: '用户对象类',
  LDAP_OBJECT_CLASS_PLACEHOLDER: '请输入用户对象类。加载用户时使用的LDAP用户对象类',
  PLEASE_INPUT_LDAP_OBJECT_CLASS: '请输入用户对象类',
  LDAP_USER_ACCOUNT: '用户登录属性',
  LDAP_USER_ACCOUNT_PLACEHOLDER: '请输入用户登录属性。常用值：sAMAccountName',
  PLEASE_INPUT_LDAP_USER_ACCOUNT: '请输入用户登录属性',
  LDAP_USER_EMAIL: '用户电邮属性',
  LDAP_USER_EMAIL_PLACEHOLDER: '请输入用户电邮。该域用于加载用户电邮',
  PLEASE_INPUT_LDAP_USER_EMAIL: '请输入用户电邮属性',
  PLEASE_INPUT_LDAP_DEPT_ATTRIBUTE: '请输入部门属性,常用值：organizationalUnit',
  THIRD_LOGIN_FIRST: '优先使用第三方登录'
}
