export default {
  FORMSELECTFLOOR: 'Please select floor',
  FORM_START_STATUS: 'Start Status',
  FORM_ORDER_STATUS: 'Order Status',
  FORMENDSTATUS: 'End Status',
  FORMBOOKINGTYPE: 'Booking Type',
  FORMUSERTYPE: 'Users Type',
  FORM_USER_NAME: 'User',
  FORMDESKNAME: 'DeskName',
  FORMRLIST: 'Reserve record',
  FORM_WAY: 'Reserve way',
  FORMRESERVATIONTIME: 'Reservation Time',
  FORMRESERVATIONDURATION: 'Reservation Duration',
  FORMUSERDURATION: 'Using Duration',
  FORMBOOKFROMOTHER: 'Book for others',
  FORMBOOKFROMOWN: 'Book for own',
  FORMCANCEL: 'Cancelled',
  FORM_RELEASED: 'Released',
  FORMENDEARLY: 'Ended Early',
  FORMENDNORMALLY: 'Ended Normally',
  FORMEBENGINORMALLY: 'On-time',
  FORM_LATE: 'Late',
  FORM_ABSENT: 'No-show',
  FORMRESERVATIONDETAIL: 'Reservation Record Details',
  FORMBAISEINFO: 'Basic Info',
  FORMDESK: 'DeskName',
  FORMADDRESS: 'Location',
  FORMTAG: 'Tags',
  FORMBOOKER: 'Booker',
  FORMBOOKER_DEPT: 'Booker Dept.',
  FORMUSER: 'User',
  FORMUSER_DEPT: 'User Dept.',
  FORMRESERVATIONINFO: 'Reservation Info',
  FORMSTARTSTATUS: 'Start Status',
  FORMHOUR: 'h',
  FORMINTERNALUSER: 'Internal user',
  FORMEXTERNALUSER: 'External user',
  FORMDESKPLACEHOLD: 'Search: DeskName, Reserver, User',
  FORMDOWNLIST: 'Export',
  FORM_HAS_DELETE: 'Deleted',
  FORM_ENDED: 'Finished',
  FORM_NOT_ENDED: 'Unfinished',
  FORM_CLOSE_OPERATOR: 'Operator',
  FORM_CONFIRM_END_ORDER: 'Confirm to end the booking?',
  FORM_END_ORDER_TIPS: 'The operation cannot be withdrawn.Please be careful.',
  FORM_SIGN_IN_TIME: 'Sign in time',
  FORM_SIGN_OUT_TIME: 'Sign out time'
}
