export default {
  USERS_TOTAL_USERS: '共 {total} 个用户',
  USERS_LIST: '用户列表',
  USERS_ALL_MEMBERS: '所有用户',
  USERS_UNASSIGNED_DESKS: '未分配工位',
  USERS_ASSIGNED_DESKS: '已分配工位',
  USERS_MULTIPLE_DESKS: '多工位用户',
  USERS_ALL_BUILDINGS: '全部楼宇',
  USERS_ALL_ADDRESS: '全部园区',
  DESKING_SEARCH_ADDRESS: '选择园区',
  USERS_SELECT_BUILDING: '选择楼宇',
  USERS_SELECT_ADDESS: '选择园区',
  USERS_SELECT_FLOOR: '选择楼层',
  USERS_SELECT_BUILDING_FIRST: '请先选择楼宇',
  USERS_SELECT_DEPT: '选择部门',
  USERS_SELECT_ROLE: '选择角色',
  USERS_ACCOUNT_STATUS: '账号状态',
  USERS_DISABLING: '禁用中',
  USERS_ENABLING: '启用中',
  USERS_CREATE_TIME: '创建时间',
  USERS_IMPORT: '导入',
  USERS_ADD_MEMBER: '添 加',
  USERS_DESKS_EXCEL: '用户工位表',
  USERS_ACCOUNT_SOURCE: '账号来源',
  USERS_MORE_DEPT_USERS: '多部门用户',
  USERS_SINGLE_DEPT_USER: '单部门用户',
  USERS_SHOW_SETTINGS: '显示设置',
  USERS_SORT_ORDERS: '排序规则',
  USERS_ALL_FIELDS: '所有字段',
  USERS_SEARCH_USERS_TIPS: '搜索：用户名、邮箱、部门名、工位名',
  USERS_CREATE_TIME_ASC: '创建时间正序',
  USERS_CREATE_TIME_DESC: '创建时间倒序',
  USERS_USER_ID_ASC: 'UserId正序',
  USERS_USER_ID_DESC: 'UserId倒序',
  USERS_DESK_NAME: '办公工位',
  USERS_ACCORDING_TO: '按',
  USERS_NO_DEPARTMENT_LIMIT: '部门不限',

  USERS_ASSIGNED_QUOTA: '已分配/工位额度',
  USERS_QUOTA: '工位额度',
  USERS_DESK_QUOTA_TIPS: '工位额度指可以分配的固定工位的数量'
}
