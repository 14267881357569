export default {
  USERS_TOTAL_USERS: '{total} users',
  USERS_LIST: 'Members',
  USERS_ALL_MEMBERS: 'All Members',
  USERS_UNASSIGNED_DESKS: 'Unassigned Desks',
  USERS_ASSIGNED_DESKS: 'Assigned Desks',
  USERS_MULTIPLE_DESKS: 'Multiple Desks',
  USERS_ALL_BUILDINGS: 'All Buildings',
  USERS_ALL_ADDRESS: 'All Address',
  DESKING_SEARCH_ADDRESS: 'Select Address',
  USERS_SELECT_ADDESS: 'Select Address',
  USERS_SELECT_BUILDING: 'Select Building',
  USERS_SELECT_FLOOR: 'Select Floor',
  USERS_SELECT_BUILDING_FIRST: 'Select Building Firstly',
  USERS_SELECT_DEPT: 'Select Department',
  USERS_SELECT_ROLE: 'Select Role',
  USERS_ACCOUNT_STATUS: 'Status',
  USERS_DISABLING: 'Disabled',
  USERS_ENABLING: 'Enabled',
  USERS_CREATE_TIME: 'CreateTime',
  USERS_IMPORT: 'Batch import',
  USERS_ADD_MEMBER: 'Add member',
  USERS_DESKS_EXCEL: 'List of Members',
  USERS_ACCOUNT_SOURCE: 'User Source',
  USERS_MORE_DEPT_USERS: 'Multi-department user',
  USERS_SINGLE_DEPT_USER: 'Single department user',
  USERS_SHOW_SETTINGS: 'Display selected items',
  USERS_SORT_ORDERS: 'Sort',
  USERS_ALL_FIELDS: 'All items',
  USERS_SEARCH_USERS_TIPS: 'Search: user name, email address, department , DeskName',
  USERS_CREATE_TIME_ASC: 'CreatTime Asc',
  USERS_CREATE_TIME_DESC: 'CreatTime Desc',
  USERS_USER_ID_ASC: 'UserId  Asc',
  USERS_USER_ID_DESC: 'UserId Desc',
  USERS_DESK_NAME: 'DeskName',
  USERS_ACCORDING_TO: 'By',
  USERS_NO_DEPARTMENT_LIMIT: 'Any department',

  USERS_ASSIGNED_QUOTA: 'Assigned/Quota',
  USERS_QUOTA: 'Quota',
  USERS_DESK_QUOTA_TIPS: 'The number of quota cannot be less than the number of  Desks assigned'
}
