import { BOOKINGAPI } from '../../../helpers/api'

export const getCompanyInfo = () => {
  return BOOKINGAPI.get(`/tenant`)
}

export const uploadLogo = params => {
  return BOOKINGAPI.post(`/tenant/upload/${params.id}`, params.file)
}

export const modifyCompanyInfo = (params, id) => {
  return BOOKINGAPI.post(`/tenant/${id}`, params)
}
