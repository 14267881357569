import router from 'umi/router'
import PageNotFoundError from './helpers/errors/PageNotFoundError'
import { message } from 'antd'

import debounce from 'lodash/debounce'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'

// Sentry.init({
//   dsn: 'https://d2edfc2f2b1b473caf59cbd43448e6e3@o495478.ingest.sentry.io/5568289',
//   autoSessionTracking: true,
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// })

export const dva = {
  config: {
    onError: debounce(function(err, dispatch) {
      if (err instanceof PageNotFoundError) {
        return router.push({
          pathname: '/404',
          query: {
            from: err.path
          }
        })
      }
      err.message && message.error(err.message)
    }, 200)
  }
}
