function isFunction(v) {
  return Object.prototype.toString.call(v) === '[object Function]'
}

function isArray(v) {
  return Object.prototype.toString.call(v) === '[object Array]'
}

function isObject(v) {
  return Object.prototype.toString.call(v) === '[object Object]'
}

function isNull(v) {
  return Object.prototype.toString.call(v) === '[object Null]'
}

function isString(v) {
  return Object.prototype.toString.call(v) === '[object String]'
}

function isBoolean(v) {
  return Object.prototype.toString.call(v) === '[object Boolean]'
}

function isNumber(v) {
  return Object.prototype.toString.call(v) === '[object Number]' && !isNaN(v)
}

function isUndefined(v) {
  return Object.prototype.toString.call(v) === '[object Undefined]'
}

export default {
  isFunction,
  isArray,
  isObject,
  isNull,
  isString,
  isBoolean,
  isNumber,
  isUndefined
}
