/* eslint-disable max-len */
// import facilityStatus from './equipment/facility-status/en-US'
export default {
  ORIGANIZE_INFO: 'Departments',
  ORGANIZE_INITIAL_INFO: 'Message initialization',
  ORGANIZE_IMPORT_TEXT: 'Single import only supports 1 building station',
  ORGANIZE_DOWNLOAD_INFO_TIP: 'Please download the member-template and import it after formatting modification',
  ORIGANIZE_SEARCH_INFO: 'Search: Department，Member',
  ORIGANIZE_DEPARTMENT_NAME: 'Department name：',
  ORIGANIZE_DEPARTMENT_NAME_PLACE: 'Please enter department name',
  ORIGANIZE_DEPARTMENT_INTERFACE: 'Interfacer：',
  ORIGANIZE_DEPARTMENT_INTERFACE_PLACE: 'Please Select  Interfacer：Name、Work ID',
  ORIGANIZE_DEPARTMENT_COLOR: 'Department color：',
  ORIGANIZE_ADD_ROLE: 'Add member',
  DELECT_DEPARTMENT_TEXT1: 'Confirm delete the department【',
  LOAD_MORE: 'load more',
  LOGIN_EMAIL: 'Email',
  RESET_PASSWORD: 'Reset Password',
  DISABLED: 'Disabled',
  DISABLED_ACCONUT: 'Disable',
  ENABLE_ACCONUT: 'Enable',
  SELECT_DEPARTMENT: 'Select departments',
  ORIGANIZE_INFO_TITLE: 'Department',
  ORG_SEARCH_DEPARTMENT: 'Search department',
  ORG_DEPT_SEL_COUNT_SINGULAR: '{count} department selected',
  ORG_DEPT_SEL_COUNT_PLURAL: '{count} departments selected',
  ORIGANIZE_SEARCH_DEPARMENT_INFO: 'Search:department',
  ADD_CHILD_DEPARTMENT: 'Add department',
  DEPARTMENT_NAME_TABLE: 'Department name',
  DEPARTMENT_COLOR_TABLE: 'Department color',
  DEPARTMENT_INTERFACE_TABLE: 'Dept. Space Manager',
  SUB_DEPARTMENT: 'Sub-departments',
  SET_DEPARTMENT_COLOR: 'Set department color',

  ENABLE: 'Enable',
  HAS_DISABLED: 'Disabled',
  DISABLED_TEXT: 'Confirm disable the account ?',
  ENABLE_TEXT: 'Confirm enable the account ?',
  DEPEARTMENT_MEMBERS: 'Department members',
  BATCH_IM: 'Batch import',
  TRANSFER_DEPERTMENT: 'Transfer department',
  MODAL_EMAIL: 'Email',
  NEW_PASSWORD_PLACERHOLDER: 'Please enter new password',
  NEW_PASSWORD_WARNING_TEXT: 'Password cannot be empty',
  DOWNLOAD_TEXT: 'Please download the template first, and upload it after modification',
  DOWNLOAD_TEMPLATE: 'Download',
  DOWNLOAD_DATA: 'Download',
  UPLOAD_DATA_TEXT: 'Supported file format: csv, xls, xlsx. Only single upload is supported.',
  EDIT_MEMBER: 'Edit member',
  ENTER_NAME_MEMBER: 'Please enter name',
  ENTER_EMAIL_ADDRESS: 'Please enter email',
  PLEASE_SELECT_ROLES: 'Please select roles',
  TRANSFER: 'Transfer',
  WORK_ID: 'Work ID',
  WORK_ID_SELECT: 'Work ID(Optional)',
  WORK_ID_SELECT_OPPO: 'Please enter work id',
  ROLE_MORE_CHARACTERS_30: 'At most 30 characters',
  ROLE_MORE_CHARACTERS_60: 'At most 60 characters',
  ROLE_MORE_CHARACTERS_80: 'At most 80 characters',
  ROLE_MORE_CHARACTERS_20: 'At most 20 characters',
  ROLE_MORE_CHARACTERS_50: 'At most 50 characters',
  PLACE_SELECT_DEPARTMENTS: 'Please select departments',
  PLACE_ENTER_LETTERS: 'Please enter Letters and Numbers',
  NEW_PASSWORD: 'Password',
  PASSWORD_TEXT: 'password(Optional),the default password for the new user is 12345678',
  SAVE_CONTINUE: 'Save and Continue',
  EDIT_DEPEARTMENT: 'Edit department',
  ADD_DEPEARTMENT: 'Add department',
  INTERFACER: 'Dept. Space Manager',
  DEPARTMENT_MANAGE_TEXT: 'Department’s space manager : this role can assign desks to employees',
  SELECT_INTERFACE: 'Please Select Interfacer：Name、Work ID',
  DEPARTMENT_COLOR: 'Department color',
  ORGANIZATIONAL_TIPS: 'Tips：',
  ORGANIZATIONAL_TIP_1:
    '1. The batch import function is applied to the information initialization of enterprise organization structure, user list and desk assignation',
  ORGANIZATIONAL_TIP_2:
    '2. If the imported table information is modified and imported again, the existing staff and desk will not be updated/overwritten',
  MANAGEABLE_DESKS: 'Manageable desks',
  MANAGEABLE_DEPARTMENT: 'Manageable departments',
  MANAGEABLE_FLOORS: 'Manageable floors',
  PLACE_DEPARTMENTS: 'Desk Department',
  ORGAMOZA_DESK_BOOKING: 'Desk Booking',
  ORGAMOZA_DESK_ASSIGNATION: 'Desk Assignation',
  ORGAMOZA_DESK_PERMISSION: 'Data Permission of Desk Management',
  ORGAMOZA_DESK_SUP: 'Support to book hot desks with permission',
  ORGAMOZA_DESK_FIND: 'Support to find colleagues, query/bind all fixed desks',
  HIGHER_OFFICE: 'Superior Department'
}
