export default {
  PER_SEARCH_ROLENAME: '搜索角色名',
  PER_TOTAL: '共',
  PER_SEARCH_STRIP: '条',
  TABLE_ROLE_ID: '角色ID',
  TABLE_ROLE_NAME: '角色名称',
  TABLE_ROLE_DESCRIPATION: '角色描述',
  TABLE_ROLE_OPERATION: '操作',
  EDIT_ROLE: '+添加角色',
  ROLE_PERMISSION: '角色权限',
  ROLE_PERMISSIONONE: '角色权限',
  ROLE_DETAIL: '角色详情',
  FUNCTION_PERMISSION: '功能权限',
  ROLE_NAME_EMPTY_ERROR: '角色名称不能为空',
  EDIT_ROLE_TITLE: '编辑角色',
  ROLE_NAME_INPUT_PLACEHOLDER: '请输入角色名称',
  REMARKS_INPUT_PLACEHOLDER: '请输入角色描述',
  ROLE_REMARKS: '角色描述',
  REMARK_MORE_CHARACTERS: '最大输入100个字符',
  ROLE_MORE_CHARACTERS: '最大输入50个字符',
  AT_LEAST_PERIMISSION: '至少选中一个角色权限',
  LAST_ONE_DATA_AUTH: '至少选中一个数据权限',
  DELECT_WARING_TEXT1: '确定要删除角色【',
  DELECT_WARING_TEXT2: '】吗?',
  ADD_ROLE_TITLE: '添加角色'
}
