export default {
  GLOBAL_CONFIG: '全局设置',
  INTER_PER: '空间经理权限',
  INTER_CAN_NOT: '空间经理不可分配工位到部门',
  INTER_CAN: '空间经理可分配工位到可管理部门',
  SAVE_OK: '保存成功',
  GC_RULE_DESK_SET: '工位预订规则',
  GC_RULE_INTERFACER_SET: '空间经理权限设置',
  GC_RULE_ON: '开',
  GC_RULE_OFF: '关',
  GC_RULE_OPEN: '打开',
  GC_RULE_CLOSE: '关闭',
  GC_RULE_TOTAL: '共 {total} 条规则',
  GC_RULE_TITLE: '规则{index}',
  GC_RULE_EXEMPT: '无需遵守规则的人',
  GC_RULE_DESC_1: '一个人同一时间段里只能使用一个工位',
  GC_RULE_DESC_2: '批量预订',
  GC_RULE_DESC_2_1: '可单次选择最多 {count} 个工位',
  GC_RULE_MOBILE_ONLY: '(该规则仅应用于手机端预订)',
  GC_RULE_DESC_3: '代内部员工预订',
  GC_RULE_DESC_4: '代外部员工预订',
  GC_RULE_SAVE: '确定',
  GC_RULE_CANCEL: '取消',
  GC_RULE_SELECT_PLH: '请选择',
  GC_RULE_NONE: '无',
  GC_RULE_SEARCH_NAME_PLH: '请搜索员工姓名',
  GC_RULE_SEL_COUNT_SINGULAR: '已选择{total}个选项',
  GC_RULE_SEL_COUNT_PLURAL: '已选择{total}个选项',
  GC_DESK_ASSIGN_RULES: '工位分配规则',
  GC_DESK_ASSIGN_MORE: '可以分配多个工位给外部人员',
  GC_DESK_ASSIGN_ONE: '只能分配1个工位给外部人员'
}
