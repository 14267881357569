export default {
  CS_BASIC_INFO: '基本信息',
  CS_CONTACT_INFO: '联系信息',
  CS_CHANGE_LOGO: '更换Logo',
  CS_LOGO_RECOMMENDED: '支持jpg、jpeg、png格式，大小不超过1M，推荐尺寸215px*90px',
  CS_UNDER_ONE_HUNDRED_PEOPLE: '100人以下',
  CS_UNDER_ONE_THOUSAND: '100-999人',
  CS_UNDER_TEN_THOUSAND: '1000-9999人',
  CS_MORE_THAN_TEN_THOUSAND: '10000人及以上',
  CS_PLEASE_INPUT_COMPANY_NAME: '请输入企业名称',
  CS_NO_EMPTY_OF_COMPANY_NAME: '企业名称不能为空',
  CS_PLEASE_SELECT_COMPANY_SIZE: '请输入企业规模',
  CS_PLEASE_INPUT_CONTACT_PEOPLE_NAME: '请输入联系人姓名',
  CS_NO_EMPTY_OF_CONTACT_NAME: '联系人姓名不能为空',
  CS_PLEASE_INPUT_CONTACT_PEOPLE_EMAIL: '请输入联系人邮箱',
  CS_EMAIL_INVALID: '请输入合法邮箱',
  CS_NO_EMPTY_OF_CONTACT_EMAIL: '邮箱地址不能为空',
  CS_PLEASE_INPUT_PHONE_NUMBER: '请输入手机号码',
  CS_NO_EMPTY_OF_CONTACT_PHONE_NUMBER: '手机号码不能为空',
  CS_PLEASE_INPUT_CORRECT_PHONE_NUMBER: '请填写正确的手机号',
  CS_PLEASE_INPUT_COMPANY_ADDRESS: '请输入企业地址',
  CS_NO_EMPTY_OF_COMPANY_ADDRESS: '企业地址不能为空',
  CS_PLEASE_INPUT_POSTALCODE: '请输入邮政编码',
  CS_PLEASE_INPUT_REMARK: '请输入备注信息',
  CS_COMPANY_NAME: '企业名称',
  CS_COMPANY_SIZE: '企业规模',
  CS_COMPANY_ADDRESS: '企业地址',
  CS_COMPANY_POSTALCODE: '企业邮编',
  CS_CONTACT_PEOPLE_NAME: '联系人姓名',
  CS_CONTACT_PEOPLE_EMAIL: '联系人邮箱',
  CS_CONTACT_PEOPLE_PHONE_NUMBER: '联系人手机号',
  CS_REMARK: '备注信息',
  CS_NO: '暂无',
  CS_NO_MORE_CHARS: '不能超过{count}个字符',
  CS_UPLOAD_SUCCESS: '上传成功',
  CS_LOGO_OVER_SIZE: '图片大小不能超过1M'
}
