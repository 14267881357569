import { OPEN_PAGES, injectedModule } from '../config'

export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const isProduction = process.env.NODE_ENV === 'production'

export function isOpenPages(pathname) {
  return OPEN_PAGES.some(p => pathname === p)
}
export function resolvePublicPath(pathname) {
  const base = window.routerBase || ''
  if (isProduction) {
    return `${base.endsWith('/') ? base.slice(0, base.length - 1) : base}${pathname}`
  }
  return pathname
}
export function isDfocusEnv() {
  return injectedModule === 'dfocus'
}
