/* eslint-disable max-len */
// import facilityStatus from './equipment/facility-status/en-US'

export default {
  DESKING_ASSIGNATION: 'Assignation',
  DESKING_ALL_FLOOR: 'All floor',
  DESKING_SEARCH_BUILDING: 'Search Building',
  DESKING_SEARCH_FLOOR: 'Search Floor',
  DESKING_SEARCH_DESK: 'Search DeskName、UserName or Work ID',
  DESKING_DESK_NUM: '{total} Desk',
  DESKING_DESK_NUMS: '{total} Desks',
  DESKING_DESK_NUM_BOOK: 'book desks',
  DESKING_SHOW_DESK_VIEW: 'Desk assignment view',
  DESKING_SHOW_DEPT_VIEW: 'Department type view',
  DESKING_SEARCH_NOT_EMPTY: 'search is not empty',
  DESKING_DESK_NOT_IN: 'The desk is not on this floor',
  FIXED_DESK: 'Fixed Desk',
  DESKING_DESK_NOT_IN_MAP: 'The desk is not bound to the map',
  DESKING_DESK_NO_MAP: 'No map yet',
  DESKING_DESK_BLDG_NO_MAP: 'Building {name} has no related map',
  DESKING_DESK_FLOOR_NO_MAP: 'Floor {name} has no related map',
  DESKING_DESK_DESK_NO_MAP: 'Desk {name} has no related map',
  DESKING_DESK_LOADING: 'Loading...',
  DESKING_MAP_IN_BOWER: 'Your browser does not support maps, please update or switch to Chrome for better service',
  DESKING_SELECT_DEPARTMENT_PLACE: 'please select the department',
  DESKING_SET_DEPARTMENT: 'Assign Department',
  DESKING_REOMVE_DEPARTEMTN_STAFF: 'Remove dept. and staff',
  DESKING_SET_TAG: 'Set Tag',
  DESKING_HAS_SELECT: 'Total',
  DESKING_HAS_SELECT_MANY: 'desk selected',
  DESKING_HAS_SELECT_MANYS: 'desks selected',
  DESKING_BATCH_OPERATION: 'Batch Operation',
  DESKING_REG: '*Character is too long',
  DESKING_CLEAR_CONFIRM: 'Confirm remove the staff and department of the desk？',
  DESKING_CLEAR_SFAFF_CONFIRM: 'Confirm remove the staff of the desk？',
  DESKING_HAS_DESK: ' has a fixed desk:',
  DESKING_AND_CONTINUE: 'Continue to assign {staff} to the staff?',
  DESKING_AND_CONTINUE_LAST: 'If continue, the original desk will be automatically released',
  DESKING_CLEAR_SUCCESS: 'Clear staff success',
  DESKING_DEPARTMENT_DIFFERENT:
    'The department of this staff is [{departmentRole}] ,which is not consistent with that department of desk [{departmentStaff}]',
  DESKING_DEPARTMENT_NONE:
    'The department of staff is none ,not consistent with that department of desk is [{departmentStaff}]',
  DESKING_ARE_YOU_CONTINUE: 'Continue to assign?',
  DESKING_CONTINUE_RESULT: 'If continue, department cost accounting may be affected',
  DESKING_SELECT_WORDER_TYPE: 'Select staff type',
  DESKING_ASSIGN_TO: 'Assign to',
  DESKING_DESK_DEPARTMENT: 'Please select department',
  DESKING_SEARCH_STAFF_NAME: 'Please enter Name or Work ID',
  DESKING_CLEAR_CONFRIM: 'Confirm remove the staff and department of the desk?',
  DESKING_DELETE_CONFIRM: 'Confirm delete this desk?',
  DESKING_SEARCH_DESK_BOOK: 'Search DeskName',
  DESKING_BOOK_TITLE: 'Booking Desk',
  DESKING_BOOK_START: 'Start',
  DESKING_BOOK_END: 'End',
  DESKING_AM: 'half day (AM)',
  DESKING_PM: 'half day (PM)',
  DESK_INFORMATION: 'Desk information',
  DESK_BASIC_INFO: 'Basic information',
  DESK_KEY_INFO: 'Property',
  DESK_DEVICE_BIND_INFO: 'Device Binding',
  DESK_SENSOR_BIND_INFO: 'Sensor Binding',
  DESK_TAG_INFO: 'Desk Tag',
  DESK_DURATION: 'Duration',
  DESK_OPEN_DETAIL: 'Unfold',
  DESK_fold_DETAIL: 'Fold',
  DESK_CHANGE_TYPE: 'Staff type can be changed when name is empty',
  DESK_HOT_DESK: '{total} Hot Desk',
  DESK_HOT_DESKS: '{total} Hot Desks',
  DESK_HOT_AVAILABLE: '({total} available)',
  DESK_DEPARTMENT_TOTAL: '{total} department',
  DESK_DEPARTMENT_TOTALS: '{total} departments',
  DESK_HAS_ASSIGNED: 'Assigned to',
  DESK_HSA_DEPARTMENT_DESK: 'The desk has been assigned to staff or departments',
  DESK_CHANGE_CLEAR: 'If change the desktype,  assigned information will be clean',
  DESK_THANK_AGAIN: 'Think again',
  DESK_DEPARTMENT_UNASSIGNED: 'Department unassigned',
  DESK_CAN_MANAGE: '{total} manageable desk',
  DESK_CAN_MANAGES: '{total} manageable desks',
  DESKING_SELECT_FLOOR: 'please select floor',
  DESKING_HOLD_SHIFT: 'Hold down the shift(',
  DESKING_HOLD_SHIFT_TEXT: ')to select multiple desks',
  DESKING_FLOOR_CHANGE_INFO: 'Floor map has been switched successfully!'
}
