export default {
  OLD_PASSWORD_REQUIRED: '旧密码不能为空',
  INPUT_OLD_PASSWORD: '输入旧密码',
  LOGIN_NEW_PASSWORD_NO_EMPTY: '新密码不能为空',
  INPUT_NEW_PASSWORD: '输入新密码',
  LOGIN_AGAIN_ENTER_NEW_PASSWORD: '请再次输入新密码',
  INPUT_AGAIN_NEW_PASSWORD: '再次输入新密码',
  MODIFY_CONFIRM_BTN: '确定',
  MODIFY_PASSWORD: '修改密码',
  LOGIN_CONTAINER_NUMBER_AND_WORD: '密码8-20位，大小写字母、数字、特殊字符至少三种',
  LOGIN_PASSWORD_NOT_SAME: '两次密码不一致',
  MODIFY_FAIL: '密码修改失败，请重试！',
  OLD_NOT_EQUAL_NEW: '新密码不能与旧密码相同',
  LOGIN_LENGTH_PASSWORD: '* 请输入不包含空格的字符',
  CAN_NOT_HAS_CHINESE: '* 请输入不包含中文的字符',
  PASSWORD_LENGTH: '密码长度',
  PASSWORD_LENGTH_MIN_ERROR: '最少字符数不能为空',
  PASSWORD_LENGTH_MIN_ERROR0: '最少字符数不能小于6',
  PASSWORD_LENGTH_MAX_ERROR: '最多字符数不能为空',
  PASSWORD_LENGTH_MAX_ERROR0: '最多字符数不能大于32',
  PASSWORD_LENGTH_MIN_AND_MAX_ERROR: '最多字符数不能小于最少字符数',
  PASSWORD_POMPLEXITY: '密码复杂度',
  PASSWORD_POMPLEXITY_ERROR: '请至少勾选一项',
  SHOULD_CONTAINS: '需包含',
  CONTAINSUPPERCASE: '大写字母',
  CONTAINSLOWERCASE: '小写字母',
  CONTAINSNUMBER: '数字',
  CONTAINSSPECIALCHARACTER: '特殊字符',
  CONTAINSUPPERCASE0: '大写字母',
  CONTAINSLOWERCASE0: '小写字母',
  CONTAINSNUMBER0: '数字',
  CONTAINSSPECIALCHARACTER0: '特殊字符',
  REMEMBER_PASSWORD_VALID_TIME: '记住密码有效期',
  REMEMBER_PASSWORD_VALID_TIME_ERROR: '密码有效期不能为空',
  DAYS_NEED_ENTER_PASSWORD: '{days}天有效期后需重输入密码',
  DAYS_NEED_ENTER_PASSWORD0: '天有效期后需重输入密码',
  AFTER: ' ',
  COUNT_LOCKED_UNLOCKED: '账号锁定与解锁',
  NOT_LOCKED: '不会锁定',
  CAN_LOCKED: '会锁定',
  TIMES_AUTO_LOCKED_COUNT: '{time}连续输错密码后账号自动锁定',
  TIMES_AUTO_LOCKED_COUNT0: ' ',
  TIMES_AUTO_LOCKED_COUNT1: '连续输错密码后账号自动锁定',
  TIME_LATER_UNLOCK: '{component}后自动解锁',
  TIME_CI: '次',
  MIN: '最少',
  MAX: '最多',
  CHART: '个字符',
  PASSWORD_ERROR_MUL_TYPE: '密码{range}位，必须包含{type}{typeNum}种',
  PASSWORD_ERROR_ONE_TYPE: '密码{range}位，必须包含{type}',
  PERSONAL_EMAIL_ADDRESS: '邮箱账号',
  PERSONAL_DEPARTMENT: '所属部门',
  PERSONAL_FIX_DESK_INFO: '固定工位信息',
  PERSONAL_WORK_ID: '暂无工号',
  PERSONAL_LOCATION_OF: '所在地点',
  PERSONAL_PAGE: '个人主页',
  PERSONAL_INFO: '个人信息',
  PERSONAL_NO_ASSIGN_DESK: '暂未分配工位',
  PERSONAL_BOOK_RECORD: '暂无预订记录',
  PERSONAL_CURRENT_PWD: '当前密码',
  PERSONAL_NEW_PWD: '新的密码',
  PERSONAL_CONFIRM_PWD: '确认密码',
  PERSONAL_OF_LOCATION: '所在地点',
  PERSONAL_OF_DEPT: '工位所属部门',
  PERSONAL_THIRD_LOGIN: '第三方登录',
  PERSONAL_LOGIN_TYPE: '登录方式',
  PASSWORD_HAS_BEEN_MODIFY_TIPS: '密码被管理员重置,为账号安全,请重新修改密码'
}
