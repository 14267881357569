import cookies from 'js-cookie'
import { STORAGE_EXPIRE_DAYS, STORAGE_DOMAIN } from '../config'
import { getLocale } from 'umi-plugin-react/locale'

const DEFAULT_COOKIE_OPTS = {
  expires: STORAGE_EXPIRE_DAYS, // 49 days
  // domain: window.location.hostname,
  path: '/'
}

export function clearAll() {
  Object.keys(cookies.get()).forEach(name => {
    cookies.remove(name, DEFAULT_COOKIE_OPTS)
  })
  sessionStorage.clear()
  localStorage.clear()
}

export function clearAllCookie() {
  let keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
      document.cookie = `${keys[i]}=;domain=.${window.location.hostname};path=/;max-age=0`
    }
  }
}

export function setRememberme() {
  return cookies.set('rememberme', 'true', DEFAULT_COOKIE_OPTS)
}

export function getRememberme() {
  return cookies.get('rememberme')
}

export function setCanChangepws(value) {
  return cookies.set('canChangepws', value, DEFAULT_COOKIE_OPTS)
}
export function getCanChangepws() {
  return cookies.get('canChangepws')
}

export function removeRememberme() {
  return cookies.remove('rememberme')
}

export function setAuthToken(value) {
  if (!getRememberme()) {
    return cookies.set('Authorization', value, DEFAULT_COOKIE_OPTS)
  }
  return cookies.set('Authorization', value, {
    ...DEFAULT_COOKIE_OPTS,
    expires: new Date(new Date().getTime() + 60 * 24 * 9999 * 60 * 1000)
  })
}

export function getAuthToken() {
  return cookies.get('Authorization')
}

export function removeAuthToken() {
  return cookies.remove('Authorization', DEFAULT_COOKIE_OPTS)
}

export function getLanguage() {
  return cookies.get('platform-lang') || 'zh'
}

export function setLanguage(lang) {
  cookies.set('platform-lang', lang, {
    ...DEFAULT_COOKIE_OPTS,
    expires: new Date(new Date().getTime() + 60 * 24 * 9999 * 60 * 1000)
  })
}

// 清除IE上数据缓存
export function clearIEDataCache() {
  const headers = { 'Cache-Control': 'no-cache, no-store, must-revalidate', Pragma: 'no-cache', Expires: '0' }
  return headers
}

export function getAuthHeaders() {
  const headers = {}
  if (getAuthToken()) {
    headers.Authorization = getAuthToken()
  }
  return headers
}
export function getLocalHostname() {
  const headers = {}
  headers.DomainName = STORAGE_DOMAIN
  return headers
}
export function getTimeHeader() {
  const d = new Date()
  const offset = d.getTimezoneOffset() / 60
  return { TimeZoneOffset: offset }
}
export function getLanguageHeader() {
  return { Language: getLocale() === 'zh-CN' ? 0 : 1 }
}
export function getPlatformHeader() {
  // 0-PC, 1-H5
  return { Platform: 0 }
}

export function setSignInPage(value) {
  return cookies.set('signInPage', value, {
    expires: new Date(new Date().getTime() + 60 * 2 * 12 * 9999 * 60 * 100000), // 2 hours
    path: '/'
  })
}
export function getSignInPage() {
  return cookies.get('signInPage')
}
