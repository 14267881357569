export default {
  GLOBAL_CONFIG: 'Global Settings',
  INTER_PER: 'Space Manager permission',
  INTER_CAN_NOT: 'Space manager cannot assign desks to departments',
  INTER_CAN: 'Space manager can assign desks to  manageable departments',
  SAVE_OK: 'Save successfully',
  GC_RULE_DESK_SET: 'Desk Rule Setting',
  GC_RULE_INTERFACER_SET: 'Space Manager Permission Setting',
  GC_RULE_ON: 'On',
  GC_RULE_OFF: 'Off',
  GC_RULE_OPEN: 'On',
  GC_RULE_CLOSE: 'Off',
  GC_RULE_TOTAL: '{total} rules',
  GC_RULE_TITLE: 'Rule {index}',
  GC_RULE_EXEMPT: "People who don't have to follow the rule",
  GC_RULE_DESC_1: 'A staff can only use one desk at a time',
  GC_RULE_DESC_2: 'Bulk Booking',
  GC_RULE_DESC_2_1: 'Desk can be booked up to {count} seats at one time',
  GC_RULE_MOBILE_ONLY: '(This rule applies to mobile only)',
  GC_RULE_DESC_3: 'Book for internal staff',
  GC_RULE_DESC_4: 'Book for external person',
  GC_RULE_SAVE: 'Confirm',
  GC_RULE_CANCEL: 'Cancel',
  GC_RULE_SELECT_PLH: 'Please select',
  GC_RULE_NONE: 'None',
  GC_RULE_SEARCH_NAME_PLH: 'Please enter member',
  GC_RULE_SEL_COUNT_SINGULAR: '{total} item selected',
  GC_RULE_SEL_COUNT_PLURAL: '{total} items selected',
  GC_DESK_ASSIGN_RULES: 'Desk Assignation Rule',
  GC_DESK_ASSIGN_MORE: 'Multiple desks can be assigned to external personnel',
  GC_DESK_ASSIGN_ONE: 'Only 1 desk can be allocated to external personnel'
}
