export default {
  QR_EDIT_TEMPLATE: '编辑',
  QR_PUBLISH: '发布',
  QR_CONFIRM_TO_THE_TEMPLATE_TO_PUBLISH: '确认按此模版发布吗？',
  QR_CONFIRM_TO_END_AND_PUBLISH_NEW_TEMPLATE: '上一次更新的二维码还在生成中，确认提前结束并按新的模版更新吗？',
  QR_NO_NEW_INFO_NO_PUBLISH: '您没有做新的更改，无需发布',
  QR_PUBLISH_SUCCESS: '发布成功～二维码努力生成中',
  QR_PUBLISH_WARNINGS: '由于您的工位数量较多，二维码更新大约需要{time}，更新期间不能下载，请耐心等待~',
  QR_ONE_HOUR: '1小时',
  QR_TEMPLATE_EXAMPLE: '示例',
  QR_EXPORT_ALL_CODES: '导出所有二维码',
  QR_DOWNLOAD_CODE_AFTER_COMPLETED: '二维码还在努力生成中，请稍后下载',
  QR_QR_CODE_TEMPLATE: '二维码模版',
  QR_EDIT_CODE_TEMPLATE: '编辑二维码模版',
  QR_TEMPLATE_PREVIEW: '模版预览',
  QR_SELECT_MODE: '请选择模式',
  QR_STANDARD_MODE: '标准模式',
  QR_SIMPLE_MODE: '简单模式',
  QR_AVATAR: '二维码头像',
  QR_AVATAR_SEL_SUCCESS: '选择成功',
  QR_AVATAR_OVER_SIZE: '选择失败，图片大小需小于1M',
  QR_AVATAR_RECOMMENDED: '支持.png .jpeg .jpg的文件，大小不超过1M，推荐64px*64px尺寸效果最佳，如不上传将使用默认头像。',
  QR_RESET_AVATAR: '恢复默认头像',
  QR_TIP_OVER_LENGTH: '文字不能超过一行',
  QR_UPDATE_SUCCESS: '保存成功，如需更新工位的二维码，请点击发布',
  QR_TIP_WORD: '提示文字',
  QR_FIRST_LINE: '首行模版',
  QR_SECOND_LINE: '次行模版',
  QR_FIRST_LINE_PLACEHOLDER: '可输入引用字段：$工位属性-$工位名称-$工位编号-{location}-$楼宇-$楼层',
  QR_SECOND_LINE_PLACEHOLDER: '可输入引用字段：$工位属性-$工位名称-$工位编号-{location}-$楼宇-$楼层',
  QR_DEFAULT_TIP: '打开工位系统「扫一扫」',
  QR_BACKGROUND_COLOR: '背景颜色',
  QR_RESET_COLOR: '恢复默认颜色'
}
