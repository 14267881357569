export default {
  OLD_PASSWORD_REQUIRED: 'Old password cannot be empty',
  INPUT_OLD_PASSWORD: 'Enter the old password',
  LOGIN_NEW_PASSWORD_NO_EMPTY: 'New password cannot be empty',
  INPUT_NEW_PASSWORD: 'Enter a new password',
  LOGIN_AGAIN_ENTER_NEW_PASSWORD: 'Please enter the new password again',
  INPUT_AGAIN_NEW_PASSWORD: 'Enter the new password again',
  MODIFY_CONFIRM_BTN: 'Confirm',
  MODIFY_PASSWORD: 'Modify Password',
  LOGIN_CONTAINER_NUMBER_AND_WORD: 'Please enter a 8-20 bit combination of Letters and Numbers',
  LOGIN_PASSWORD_NOT_SAME: 'Two passwords are inconsistent',
  MODIFY_FAIL: 'Password modification failed, please try again!',
  OLD_NOT_EQUAL_NEW: 'The new password cannot be the same as the old password',
  LOGIN_LENGTH_PASSWORD: '* Please enter characters without spaces',
  CAN_NOT_HAS_CHINESE: '* please enter not included chinese characters',
  PASSWORD_LENGTH: 'Password length',
  PASSWORD_LENGTH_MIN_ERROR: 'Minimum number of characters cannot be empty',
  PASSWORD_LENGTH_MIN_ERROR0: 'Minimum number of characters cannot less then 6',
  PASSWORD_LENGTH_MAX_ERROR: 'Maxmun number of characters cannot be empty',
  PASSWORD_LENGTH_MAX_ERROR0: 'Maxmun number of characters cannot greater than minimum number of characters',
  PASSWORD_LENGTH_MIN_AND_MAX_ERROR: 'Minimum number of characters cannot less than the maxmun number of characters',
  PASSWORD_POMPLEXITY: 'Password Complexity',
  PASSWORD_POMPLEXITY_ERROR: 'At least check one',
  SHOULD_CONTAINS: 'Should ',
  SHOULD_CONTAINS0: 'Should contains ',
  CONTAINSUPPERCASE: 'contain uppercase letters',
  CONTAINSLOWERCASE: 'contain lowercase letters',
  CONTAINSNUMBER: 'contain numbers',
  CONTAINSSPECIALCHARACTER: 'contain special characters',
  CONTAINSUPPERCASE0: 'uppercase letters',
  CONTAINSLOWERCASE0: 'lowercase letters',
  CONTAINSNUMBER0: 'numbers',
  CONTAINSSPECIALCHARACTER0: 'special characters',
  REMEMBER_PASSWORD_VALID_TIME: 'Remember password validity period',
  REMEMBER_PASSWORD_VALID_TIME_ERROR: 'Password validity cannot be empty',
  DAYS_NEED_ENTER_PASSWORD: 'After {days} days, users will need to log in again',
  DAYS_NEED_ENTER_PASSWORD0: ' days, users will need to log in again',
  AFTER: 'After ',
  COUNT_LOCKED_UNLOCKED: 'Account locking and unlocking',
  NOT_LOCKED: 'Unlocking',
  CAN_LOCKED: 'Locking',
  TIMES_AUTO_LOCKED_COUNT: 'The account will be locked after {time} consecutive incorrect login attempts.',
  TIMES_AUTO_LOCKED_COUNT0: 'The account will be locked after ',
  TIMES_AUTO_LOCKED_COUNT1: ' consecutive incorrect login attempts.',
  TIME_LATER_UNLOCK: 'Automatically unlocked after {component}',
  TIME_CI: ' ',
  MIN: 'Minimum of ',
  MAX: 'Maximum of ',
  CHART: ' characters ',
  PASSWORD_ERROR_MUL_TYPE: 'Password {range} digits, must contain {type}, at least {typeNum} species',
  PASSWORD_ERROR_ONE_TYPE: 'Password {range} digits, must contain {type}.',
  PERSONAL_EMAIL_ADDRESS: 'Email address',
  PERSONAL_DEPARTMENT: 'Dept.',
  PERSONAL_FIX_DESK_INFO: 'Fixed Desk',
  PERSONAL_WORK_ID: 'Not available yet',
  PERSONAL_PAGE: 'My Homepage',
  PERSONAL_INFO: 'Personal Info',
  PERSONAL_NO_ASSIGN_DESK: 'Not available yet',
  PERSONAL_BOOK_RECORD: 'Not available yet',
  PERSONAL_CURRENT_PWD: 'Current password',
  PERSONAL_NEW_PWD: 'New password',
  PERSONAL_CONFIRM_PWD: 'Confirm password',
  PERSONAL_OF_LOCATION: 'Location',
  PERSONAL_OF_DEPT: 'Dept.',
  PERSONAL_THIRD_LOGIN: 'Third party login',
  PERSONAL_LOGIN_TYPE: 'Login Method',
  PASSWORD_HAS_BEEN_MODIFY_TIPS:
    'The password has been reset by the administrator. For account security, please change the password again.'
}
