import { reap } from 'safe-reaper'
import { withMixin } from '../helpers/dva'
import { BOOKINGAPI } from '../helpers/api'

export function getList(params) {
  return BOOKINGAPI.get('/deskrule', { params })
}

export function addRule(params) {
  return BOOKINGAPI.post('/deskrule', params)
}

const RULE_TYPE = { BOOK: 0, RELEASE: 2 }

export default withMixin({
  namespace: 'customRule',
  state: {
    bookRuleList: [],
    releaseRuleList: []
  },
  subscriptions: {},
  effects: {
    *getList({ payload }, { put, call }) {
      try {
        const params = { ...payload }
        console.log('params', params)
        const res = yield call(getList, params)
        console.log('res', res)
        const code = reap(res, 'errorCode', 0)
        if (code !== 200) {
          return
        }
        let listKey
        switch (payload.type) {
          case RULE_TYPE.BOOK:
            listKey = 'bookRuleList'
            break
          case RULE_TYPE.RELEASE:
            listKey = 'releaseRuleList'
            break
          default:
            break
        }
        if (listKey) {
          yield put({
            type: 'updateState',
            payload: { [listKey]: res.data }
          })
        }
        return res
      } catch (error) {
        console.error(error)
      }
    },
    *addRule({ payload }, { put, call }) {
      try {
        const res = yield call(addRule, payload)
        const code = reap(res, 'errorCode', 0)
        if (code !== 200) {
          return res
        }
        yield put({
          type: 'getList',
          payload: { type: payload.type }
        })
        return res
      } catch (error) {
        console.error(error)
      }
    }
  },
  reducers: {}
})
