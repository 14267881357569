import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/Users/shouyunshan/work/d-desking-fe/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__index" */ '../../layouts/index.js'),
        })
      : require('../../layouts/index.js').default,
    routes: [
      {
        path: '/404',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404.js'),
            })
          : require('../404.js').default,
      },
      {
        path: '/about-setting',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__about-setting__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/about-setting/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__about-setting__index" */ '../about-setting/index.js'),
            })
          : require('../about-setting/index.js').default,
      },
      {
        path: '/about-us',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__about-us__index" */ '../about-us/index.tsx'),
            })
          : require('../about-us/index.tsx').default,
      },
      {
        path: '/book',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__book__models__index.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/book/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__book__index" */ '../book/index.js'),
            })
          : require('../book/index.js').default,
        Routes: [require('../../routes/private.js').default],
      },
      {
        path: '/book-statistics',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__book-statistics__models__index.ts' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/book-statistics/models/index.ts').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__book-statistics__index" */ '../book-statistics/index.tsx'),
            })
          : require('../book-statistics/index.tsx').default,
      },
      {
        path: '/book-statistics/utils',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__book-statistics__models__index.ts' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/book-statistics/models/index.ts').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__book-statistics__utils__index" */ '../book-statistics/utils/index.ts'),
            })
          : require('../book-statistics/utils/index.ts').default,
      },
      {
        path: '/bookingForm',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__bookingForm__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/bookingForm/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__bookingForm__index" */ '../bookingForm/index.js'),
            })
          : require('../bookingForm/index.js').default,
      },
      {
        path: '/bookingForm/util',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__bookingForm__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/bookingForm/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__bookingForm__util__index" */ '../bookingForm/util/index.js'),
            })
          : require('../bookingForm/util/index.js').default,
      },
      {
        path: '/changePassword',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__changePassword__models__index.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/changePassword/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__changePassword__index" */ '../changePassword/index.js'),
            })
          : require('../changePassword/index.js').default,
      },
      {
        path: '/company-setting/hooks',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__company-setting__hooks__index" */ '../company-setting/hooks/index.ts'),
            })
          : require('../company-setting/hooks/index.ts').default,
      },
      {
        path: '/company-setting',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__company-setting__index" */ '../company-setting/index.tsx'),
            })
          : require('../company-setting/index.tsx').default,
      },
      {
        path: '/company-setting/service',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__company-setting__service__index" */ '../company-setting/service/index.ts'),
            })
          : require('../company-setting/service/index.ts').default,
      },
      {
        path: '/custom-password',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__custom-password__index" */ '../custom-password/index.js'),
            })
          : require('../custom-password/index.js').default,
      },
      {
        path: '/customizetags',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__customizetags__models__customizetags.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/customizetags/models/customizetags.js').then(
                  m => {
                    return { namespace: 'customizetags', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__customizetags__index" */ '../customizetags/index.js'),
            })
          : require('../customizetags/index.js').default,
      },
      {
        path: '/deskrule',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__deskrule__models__autoRelease.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/deskrule/models/autoRelease.js').then(
                  m => {
                    return { namespace: 'autoRelease', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__deskrule__models__bookRule.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/deskrule/models/bookRule.js').then(
                  m => {
                    return { namespace: 'bookRule', ...m.default };
                  },
                ),
                import(/* webpackChunkName: 'p__deskrule__models__system.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/deskrule/models/system.js').then(
                  m => {
                    return { namespace: 'system', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__deskrule__index" */ '../deskrule/index.js'),
            })
          : require('../deskrule/index.js').default,
      },
      {
        path: '/email-setting',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__email-setting__index" */ '../email-setting/index.js'),
            })
          : require('../email-setting/index.js').default,
      },
      {
        path: '/global-config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__global-config__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/global-config/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__global-config__index" */ '../global-config/index.js'),
            })
          : require('../global-config/index.js').default,
      },
      {
        path: '/global-config/util',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__global-config__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/global-config/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__global-config__util" */ '../global-config/util.js'),
            })
          : require('../global-config/util.js').default,
      },
      {
        path: '/iframe',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__iframe__index" */ '../iframe/index.js'),
            })
          : require('../iframe/index.js').default,
      },
      {
        path: '/',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__index" */ '../index.js'),
            })
          : require('../index.js').default,
      },
      {
        path: '/interfaceManage/building',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__interfaceManage__models__interfaceManage.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/interfaceManage/models/interfaceManage.js').then(
                  m => {
                    return { namespace: 'interfaceManage', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__interfaceManage__building__index" */ '../interfaceManage/building/index.js'),
            })
          : require('../interfaceManage/building/index.js').default,
      },
      {
        path: '/interfaceManage/floor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__interfaceManage__models__interfaceManage.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/interfaceManage/models/interfaceManage.js').then(
                  m => {
                    return { namespace: 'interfaceManage', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__interfaceManage__floor__index" */ '../interfaceManage/floor/index.js'),
            })
          : require('../interfaceManage/floor/index.js').default,
      },
      {
        path: '/interfaceManage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__interfaceManage__models__interfaceManage.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/interfaceManage/models/interfaceManage.js').then(
                  m => {
                    return { namespace: 'interfaceManage', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__interfaceManage__index" */ '../interfaceManage/index.js'),
            })
          : require('../interfaceManage/index.js').default,
      },
      {
        path: '/interfaceMap',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__interfaceMap__models__map.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/interfaceMap/models/map.js').then(
                  m => {
                    return { namespace: 'map', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__interfaceMap__index" */ '../interfaceMap/index.js'),
            })
          : require('../interfaceMap/index.js').default,
      },
      {
        path: '/license/hooks',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__license__models__index.ts' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/license/models/index.ts').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__license__hooks" */ '../license/hooks.ts'),
            })
          : require('../license/hooks.ts').default,
      },
      {
        path: '/license',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__license__models__index.ts' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/license/models/index.ts').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__license__index" */ '../license/index.tsx'),
            })
          : require('../license/index.tsx').default,
      },
      {
        path: '/log',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__log__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/log/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__log__index" */ '../log/index.js'),
            })
          : require('../log/index.js').default,
      },
      {
        path: '/login',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__login__models__index.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/login/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__login__index" */ '../login/index.js'),
            })
          : require('../login/index.js').default,
      },
      {
        path: '/map',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__map__models__map.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/map/models/map.js').then(
                  m => {
                    return { namespace: 'map', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__map__index" */ '../map/index.js'),
            })
          : require('../map/index.js').default,
      },
      {
        path: '/myBooking',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__myBooking__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/myBooking/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__myBooking__index" */ '../myBooking/index.tsx'),
            })
          : require('../myBooking/index.tsx').default,
        Routes: [require('../../routes/private.js').default],
      },
      {
        path: '/myBooking/services',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__myBooking__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/myBooking/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__myBooking__services" */ '../myBooking/services.js'),
            })
          : require('../myBooking/services.js').default,
      },
      {
        path: '/organizationalStructure',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__organizationalStructure__models__index.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/organizationalStructure/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__organizationalStructure__index" */ '../organizationalStructure/index.js'),
            })
          : require('../organizationalStructure/index.js').default,
      },
      {
        path: '/personal-page',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__personal-page__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/personal-page/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__personal-page__index" */ '../personal-page/index.js'),
            })
          : require('../personal-page/index.js').default,
        title: 'MODIFY_PASSWORD',
      },
      {
        path: '/preference-setting',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__preference-setting__models__index.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/preference-setting/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__preference-setting__index" */ '../preference-setting/index.js'),
            })
          : require('../preference-setting/index.js').default,
      },
      {
        path: '/preference-setting/service',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__preference-setting__models__index.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/preference-setting/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__preference-setting__service__index" */ '../preference-setting/service/index.js'),
            })
          : require('../preference-setting/service/index.js').default,
      },
      {
        path: '/qr-code',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__qr-code__index" */ '../qr-code/index.tsx'),
            })
          : require('../qr-code/index.tsx').default,
      },
      {
        path: '/qr-code/service',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__qr-code__service__index" */ '../qr-code/service/index.ts'),
            })
          : require('../qr-code/service/index.ts').default,
      },
      {
        path: '/rolePermissions',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__rolePermissions__models__index.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/rolePermissions/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__rolePermissions__index" */ '../rolePermissions/index.js'),
            })
          : require('../rolePermissions/index.js').default,
      },
      {
        path: '/server_config/AddModal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__server_config__AddModal__index" */ '../server_config/AddModal/index.js'),
            })
          : require('../server_config/AddModal/index.js').default,
      },
      {
        path: '/server_config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__server_config__index" */ '../server_config/index.js'),
            })
          : require('../server_config/index.js').default,
      },
      {
        path: '/server_config/request',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__server_config__request" */ '../server_config/request.js'),
            })
          : require('../server_config/request.js').default,
      },
      {
        path: '/spaceManage/building',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__spaceManage__models__manage.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/spaceManage/models/manage.js').then(
                  m => {
                    return { namespace: 'manage', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__spaceManage__building__index" */ '../spaceManage/building/index.js'),
            })
          : require('../spaceManage/building/index.js').default,
      },
      {
        path: '/spaceManage/floor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__spaceManage__models__manage.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/spaceManage/models/manage.js').then(
                  m => {
                    return { namespace: 'manage', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__spaceManage__floor__index" */ '../spaceManage/floor/index.js'),
            })
          : require('../spaceManage/floor/index.js').default,
      },
      {
        path: '/spaceManage/hooks',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__spaceManage__models__manage.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/spaceManage/models/manage.js').then(
                  m => {
                    return { namespace: 'manage', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__spaceManage__hooks" */ '../spaceManage/hooks.ts'),
            })
          : require('../spaceManage/hooks.ts').default,
      },
      {
        path: '/spaceManage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__spaceManage__models__manage.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/spaceManage/models/manage.js').then(
                  m => {
                    return { namespace: 'manage', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__spaceManage__index" */ '../spaceManage/index.js'),
            })
          : require('../spaceManage/index.js').default,
      },
      {
        path: '/sso-callback',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__sso-callback__index" */ '../sso-callback/index.js'),
            })
          : require('../sso-callback/index.js').default,
      },
      {
        path: '/sso-callback/util',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__sso-callback__util" */ '../sso-callback/util.js'),
            })
          : require('../sso-callback/util.js').default,
      },
      {
        path: '/statistics/hooks',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__statistics__models__statistics.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/statistics/models/statistics.js').then(
                  m => {
                    return { namespace: 'statistics', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__statistics__hooks__index" */ '../statistics/hooks/index.js'),
            })
          : require('../statistics/hooks/index.js').default,
      },
      {
        path: '/statistics',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__statistics__models__statistics.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/statistics/models/statistics.js').then(
                  m => {
                    return { namespace: 'statistics', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__statistics__index" */ '../statistics/index.js'),
            })
          : require('../statistics/index.js').default,
      },
      {
        path: '/statistics/utils',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__statistics__models__statistics.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/statistics/models/statistics.js').then(
                  m => {
                    return { namespace: 'statistics', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__statistics__utils__index" */ '../statistics/utils/index.js'),
            })
          : require('../statistics/utils/index.js').default,
      },
      {
        path: '/third-part',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__third-part__models__index.ts' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/third-part/models/index.ts').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__third-part__index" */ '../third-part/index.tsx'),
            })
          : require('../third-part/index.tsx').default,
      },
      {
        path: '/usage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__usage__model.js' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/usage/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__usage__index" */ '../usage/index.js'),
            })
          : require('../usage/index.js').default,
      },
      {
        path: '/user-approve',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user-approve__models__index.ts' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/user-approve/models/index.ts').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user-approve__index" */ '../user-approve/index.tsx'),
            })
          : require('../user-approve/index.tsx').default,
      },
      {
        path: '/user-page',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__user-page__models__index.ts' */ '/Users/shouyunshan/work/d-desking-fe/src/pages/user-page/models/index.ts').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__user-page__index" */ '../user-page/index.tsx'),
            })
          : require('../user-page/index.tsx').default,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404.js'),
            })
          : require('../404.js').default,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
